import { setupWorker } from 'msw';
import { platformHandlers } from './platformHandlers';
// This configures a Service Worker with the given request handlers.
export const startWorker = (additionalHandlers) => {
    const handlers = additionalHandlers
        ? [...platformHandlers, ...additionalHandlers]
        : platformHandlers;
    const worker = setupWorker(...handlers);
    worker.start();
};
