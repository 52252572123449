import { faker } from '@faker-js/faker';
import { IVerticalMarketNames, } from '../models/Organization/OrganizationInterfaces';
export class OrganizationSummaryResponseBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ organizationId: faker.lorem.slug(1), organizationIdentity: faker.number.int({
                    min: undefined,
                    max: undefined,
                }), name: faker.lorem.slug(1), smallLogoUrl: null, largeLogoUrl: null, verticalMarket: null, businessUnit: faker.lorem.slug(1), stateOrProvince: faker.lorem.slug(1), clientConcierge: null, userCount: faker.number.int({ min: undefined, max: undefined }), apps: [] }, this.overrides);
        };
        this.overrides = overrides;
    }
}
export class OrganizationDetailsResponseBuilder {
    constructor(overrides) {
        this.build = () => {
            const siteBuilder = new SiteResponseBuilder({});
            return Object.assign({ organizationId: faker.lorem.slug(1), organizationIdentity: faker.number.int(), name: faker.lorem.slug(1), businessUnit: faker.lorem.slug(1), companyWebsite: faker.internet.url(), address1: faker.location.streetAddress(), address2: undefined, city: faker.location.city(), stateOrProvince: faker.location.state(), postalCode: faker.location.zipCode(), smallLogoUrl: faker.internet.url(), largeLogoUrl: faker.internet.url(), verticalMarket: faker.helpers.enumValue(IVerticalMarketNames), clientConcierge: undefined, primaryContact: undefined, apps: [], sites: [siteBuilder.build()] }, this.overrides);
        };
        this.overrides = overrides;
    }
}
export class IOrganizationSnapshotBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ identity: faker.number.int({
                    min: undefined,
                    max: undefined,
                }), name: faker.lorem.slug(1) }, this.overrides);
        };
        this.overrides = overrides;
    }
}
// Sites
export class SiteResponseBuilder {
    constructor(overrides) {
        this.build = () => {
            const facilityBuilder = new FacilityResponseBuilder({});
            return Object.assign({ siteId: faker.lorem.slug(1), siteIdentity: faker.number.int(), name: faker.lorem.slug(1), address1: faker.location.streetAddress(), address2: null, city: faker.location.city(), stateOrProvince: faker.location.state(), postalCode: faker.location.zipCode(), facilities: [facilityBuilder.build()] }, this.overrides);
        };
        this.overrides = overrides;
    }
}
export class FacilityResponseBuilder {
    constructor(overrides) {
        this.build = () => {
            const facilityAdditionBuilder = new FacilityAdditionResponseBuilder({});
            return Object.assign({ facilityId: faker.lorem.slug(1), facilityIdentity: faker.number.int(), name: faker.lorem.slug(1), address1: faker.location.streetAddress(), address2: null, city: faker.location.city(), stateOrProvince: faker.location.state(), postalCode: faker.location.zipCode(), latitude: faker.location.latitude().toString(), longitude: faker.location.longitude().toString(), yearBuilt: faker.date.past().getFullYear(), originalSquareFootage: faker.number.int(), note: faker.lorem.sentence(), numberOfOccupants: faker.number.int(), primaryFacilityType: faker.lorem.slug(1), secondaryFacilityType: faker.lorem.slug(1), tertiaryFacilityType: faker.lorem.slug(1), keyCount: faker.number.int(), unitCount: faker.number.int(), customerInternalId: faker.lorem.slug(1), propertyManager: faker.person.fullName(), hasCompletedAudit: faker.datatype.boolean(), hasPropertySurvey: faker.datatype.boolean(), facilityGrouper1: faker.lorem.slug(1), facilityGrouper2: faker.lorem.slug(1), additions: [facilityAdditionBuilder.build()] }, this.overrides);
        };
        this.overrides = overrides;
    }
}
export class FacilityAdditionResponseBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ additionId: faker.lorem.slug(1), additionIdentity: faker.number.int(), name: faker.lorem.slug(1), yearBuilt: faker.date.past().getFullYear(), squareFootage: faker.number.int() }, this.overrides);
        };
        this.overrides = overrides;
    }
}
