var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Center, VStack } from '@chakra-ui/react';
import '@fontsource/montserrat';
import '@fontsource/montserrat/500.css';
import '@frontend/design-system/theme/font';
import { useOrganizationSummaries } from '@frontend/domain/contexts/Organization/OrganizationSummaryContext';
import { ReportsProvider } from '@frontend/domain/contexts/Reports/ReportsProvider';
import { PlannerSettingsProvider } from '@frontend/domain/contexts/Settings/PlannerSettingsProvider';
import { IAppNames } from '@frontend/domain/models/App';
import { startWorker } from '@frontend/domain/testing/msw/worker';
import { RequireOrgAppAccess } from '@frontend/shared/src/components/Access/RequireOrgAppAccess';
import { UnauthorizedOrg } from '@frontend/shared/src/components/Access/Unauthorized';
import { RequireWithinOrganization } from '@frontend/shared/src/components/Routing/RequireWithinOrganization';
import { UserControl } from '@frontend/shared/src/components/Routing/UserControl';
import React, { useEffect } from 'react';
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import { authConfig } from './authConfig';
import { PageLayout } from './components/layouts/PageLayout';
import './config/style.css';
import { ProjectsProvider } from './contexts/Projects/ProjectsProvider';
import { MyAssetsPage } from './pages/assets/MyAssetsPage';
import { Logout } from './pages/logout/Logout';
import { NoAssociatedData } from './pages/NoAssociatedData';
import { Projects } from './pages/projects/Projects';
import ScenarioPage from './pages/scenarios/ScenarioPage';
import { SupportRedirect } from './pages/SupportRedirect';
import { PlannerRoutes } from './paths';
import { apiService, platformApiService } from './services';
const App = () => {
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    useEffect(() => {
        if (isAuthenticated && (user === null || user === void 0 ? void 0 : user.email)) {
            apiService.setTokenRetrievalFunction(() => __awaiter(void 0, void 0, void 0, function* () {
                return yield getAccessTokenSilently({
                    authorizationParams: {
                        audience: authConfig.authorizationParams.platformAudience,
                        scope: 'email read:current_user update:current_user_metadata',
                    },
                });
            }));
            platformApiService.setTokenRetrievalFunction(() => __awaiter(void 0, void 0, void 0, function* () {
                return yield getAccessTokenSilently({
                    authorizationParams: {
                        audience: authConfig.authorizationParams.platformAudience,
                        scope: 'email read:current_user update:current_user_metadata',
                    },
                });
            }));
        }
    }, [isAuthenticated, user]);
    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (process.env.REACT_APP_API_MOCKING === 'enabled') {
                startWorker();
            }
        }
    }, []);
    const PlannerUserControl = ({ children, }) => {
        return (_jsx(UserControl, Object.assign({ authorizationParams: authConfig.authorizationParams }, { children: children })));
    };
    const GlobalPlannerDetails = ({ children, }) => {
        const { selectedOrganizationIdentity: orgIdentity } = useOrganizationSummaries();
        const navigate = useNavigate();
        useEffect(() => {
            const baseRoute = PlannerRoutes.organization.withOrgId(orgIdentity);
            if (location.pathname === baseRoute ||
                location.pathname === `${baseRoute}/`) {
                // Needed to properly redirect to landing route if just /organization/:oid
                navigate(PlannerRoutes.scenarios.base(orgIdentity));
            }
        }, [location, orgIdentity]);
        return (_jsx(PlannerSettingsProvider, Object.assign({ orgIdentity: orgIdentity }, { children: _jsx(ProjectsProvider, Object.assign({ orgIdentity: orgIdentity }, { children: _jsx(ReportsProvider, Object.assign({ organizationIdentity: orgIdentity }, { children: children })) })) })));
    };
    const { base, organization, scenarios, projects, assets, logout, support } = PlannerRoutes;
    return (_jsx(Box, Object.assign({ className: 'App', w: '100%', h: '100vh' }, { children: _jsxs(Routes, { children: [_jsx(Route, { path: logout, element: _jsx(Logout, {}) }), _jsxs(Route, Object.assign({ path: base, element: _jsx(PlannerUserControl, { children: _jsx(Outlet, {}) }) }, { children: [_jsx(Route, { path: base, element: _jsx(Navigate, { to: organization.base }) }), _jsx(Route, { path: support, element: _jsx(SupportRedirect, {}) }), _jsx(Route, Object.assign({ path: organization.base, element: _jsx(RequireWithinOrganization, Object.assign({ basePath: organization.base }, { children: _jsx(Outlet, {}) })) }, { children: _jsxs(Route, Object.assign({ path: organization.withOrgId(), element: _jsx(GlobalPlannerDetails, { children: _jsx(RequirePlannerOrgAppAccess, { children: _jsx(Outlet, {}) }) }) }, { children: [_jsx(Route, { path: scenarios.base(), element: _jsx(ScenarioPage, {}) }), _jsx(Route, { path: projects.base(), element: _jsx(Projects, {}) }), _jsx(Route, { path: assets.base(), element: _jsx(MyAssetsPage, {}) })] })) }))] })), _jsx(Route, { path: '*', element: _jsx(NoAssociatedData, {}) })] }) })));
};
const UnauthorizedPageWrapper = () => {
    // TODO: Update UnauthorizedOrg component to use primaryContact information from platformApi
    return (_jsx(PageLayout, { children: _jsx(Center, Object.assign({ h: '85vh' }, { children: _jsx(VStack, { children: _jsx(UnauthorizedOrg, {}) }) })) }));
};
const RequirePlannerOrgAppAccess = ({ children, }) => {
    return (_jsx(RequireOrgAppAccess, Object.assign({ requiredApp: IAppNames.PLANNER, unauthorizedPage: _jsx(UnauthorizedPageWrapper, {}) }, { children: children })));
};
export default App;
