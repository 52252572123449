import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { VStack } from '@chakra-ui/react';
import { TaxImpactCurrencyInput } from './Inputs/TaxImpactCurrencyInput';
import { TotalStateAidInput } from './Inputs/TotalStateAidInput';
import { TaxImpactTitleWrapper } from './TaxImpactTitleWrapper';
const AG2SCHOOL_STATE_AID_KEY = 'ag2SchoolStateAid';
const LTFM_STATE_AID_KEY = 'ltfmStateAid';
export const StateAidAssociatedSection = ({ formContext, taxImpact, }) => {
    const displayStateAidSection = () => {
        if (taxImpact &&
            !displayAg2SchoolStateAid() &&
            !displayLtfmStateAid() &&
            !displayTotalStateAid()) {
            return false;
        }
        else {
            return true;
        }
    };
    const displayAg2SchoolStateAid = () => {
        if (taxImpact && !taxImpact.ag2SchoolStateAid) {
            return false;
        }
        else {
            return true;
        }
    };
    const displayLtfmStateAid = () => {
        if (taxImpact && !taxImpact.longTermFacilityMaintenanceStateAid) {
            return false;
        }
        else {
            return true;
        }
    };
    const displayTotalStateAid = () => {
        if (taxImpact &&
            (!taxImpact.stateAidAmount || !taxImpact.stateAidDescription)) {
            return false;
        }
        else {
            return true;
        }
    };
    return (_jsx(_Fragment, { children: displayStateAidSection() && (_jsx(TaxImpactTitleWrapper, Object.assign({ title: 'State Aid Associated With This Project' }, { children: _jsxs(VStack, Object.assign({ w: '100%', spacing: 5 }, { children: [displayAg2SchoolStateAid() && (_jsx(TaxImpactCurrencyInput, { title: 'Total Ag2School state aid', formContext: formContext, taxImpactExists: taxImpact !== undefined, formKey: AG2SCHOOL_STATE_AID_KEY })), displayLtfmStateAid() && (_jsx(TaxImpactCurrencyInput, { title: 'Total LTFM equalization state aid', formContext: formContext, taxImpactExists: taxImpact !== undefined, formKey: LTFM_STATE_AID_KEY })), displayTotalStateAid() && (_jsx(TotalStateAidInput, { taxImpactExists: taxImpact !== undefined, formContext: formContext }))] })) }))) }));
};
