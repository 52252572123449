import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { HStack, Input, InputGroup, InputLeftElement, Text, VStack, } from '@chakra-ui/react';
import { CurrencyInput } from '@frontend/design-system/components/Inputs/CurrencyInput';
import { validateMinMax } from '@frontend/domain/formValidations/RuleValidation';
import { Controller } from 'react-hook-form';
import MaskedInput from 'react-text-mask';
import { createNumberMask } from 'text-mask-addons';
import { convertToCurrency } from '../../../../config/CPHelperFunctions';
import { TaxImpactText } from './TaxImpactText';
import { TaxImpactValueText } from './TaxImpactValueText';
const ACRE_VALUE_KEY = 'acreValue';
const ANNUAL_ACRE_VALUE_TAX_IMPACT_KEY = 'annualAcreValueTaxImpact';
export const AnnualAcreInput = ({ taxImpactExists, formContext, }) => {
    var _a;
    const { control, formState: { errors }, watch, } = formContext;
    const textFormValue = watch(ACRE_VALUE_KEY);
    const currencyValue = watch(ANNUAL_ACRE_VALUE_TAX_IMPACT_KEY);
    const currencyMask = createNumberMask({
        prefix: '',
        suffix: '',
        includeThousandsSeparator: true,
        thousandsSeparatorSymbol: ',',
        allowDecimal: false,
        integerLimit: 5,
        allowNegative: false,
        allowLeadingZeroes: false,
    });
    return taxImpactExists && currencyValue ? (_jsxs(VStack, Object.assign({ w: '100%', spacing: 0.5, "aria-label": 'annual-acre-tax-impact-values' }, { children: [_jsx(TaxImpactText, { text: 'Annual tax impact on ag non-homestead acre' }), _jsx(TaxImpactText, { text: `valued at ${convertToCurrency(textFormValue, false)} '$/acre'` }), _jsx(TaxImpactValueText, { text: convertToCurrency(currencyValue, true, true) })] }))) : (_jsxs(VStack, Object.assign({ w: '100%', spacing: 1, "aria-label": 'annual-acre-tax-impact-form' }, { children: [_jsx(TaxImpactText, { text: 'Annual tax impact on ag non-homestead acre' }), _jsxs(HStack, Object.assign({ w: '100%', spacing: 1 }, { children: [_jsx(TaxImpactText, { text: 'valued at' }), _jsx(Controller, { control: control, name: ACRE_VALUE_KEY, rules: {
                            required: true,
                            validate: (value) => {
                                return validateMinMax(value, 100, 10000);
                            },
                        }, render: ({ field }) => (_jsxs(InputGroup, Object.assign({ size: 'xs', w: '125px' }, { children: [_jsx(InputLeftElement, Object.assign({ pointerEvents: 'none' }, { children: "$" })), _jsx(Input, { h: '25px', as: MaskedInput, mask: currencyMask, placeholder: 'Type here...', onChange: (e) => {
                                        if (!(e && e.target.value) || e.target.value === '') {
                                            field.onChange(undefined);
                                        }
                                        field.onChange(e);
                                    } })] }))) }), _jsx(TaxImpactText, { text: '$/acre', requiredAfter: true })] })), errors.acreValue && (_jsx(Text, Object.assign({ alignSelf: 'start', color: 'red', fontSize: '13px', mb: '10px' }, { children: (_a = errors.acreValue) === null || _a === void 0 ? void 0 : _a.message }))), _jsx(Controller, { control: control, name: ANNUAL_ACRE_VALUE_TAX_IMPACT_KEY, rules: {
                    required: true,
                    validate: (value) => {
                        return validateMinMax(value, 0, 9000);
                    },
                }, render: ({ field }) => {
                    var _a;
                    return (_jsxs(_Fragment, { children: [_jsx(CurrencyInput, { data: field.value, onInputChange: (e) => {
                                    if (!(e && e.target.value) || e.target.value === '') {
                                        field.onChange(undefined);
                                    }
                                    field.onChange(e);
                                }, placeholder: 'Type here...' }), errors.annualAcreValueTaxImpact && (_jsx(Text, Object.assign({ alignSelf: 'start', color: 'red', fontSize: '13px' }, { children: (_a = errors.annualAcreValueTaxImpact) === null || _a === void 0 ? void 0 : _a.message })))] }));
                } })] })));
};
