export class ProjectService {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * @param projectId
     * @returns ProjectDetailResponse OK
     * @throws ApiError
     */
    getApiV1ProjectLoad(projectId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/Project/load/{projectId}',
            path: {
                projectId: projectId,
            },
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @param organizationId
     * @returns ProjectSummaryResponse OK
     * @throws ApiError
     */
    getApiV1ProjectList(organizationId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/Project/list/{organizationId}',
            path: {
                organizationId: organizationId,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @param formData
     * @returns ProjectDetailResponse OK
     * @throws ApiError
     */
    postApiV1ProjectConfigure(formData) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/Project/configure',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @param projectId
     * @returns any OK
     * @throws ApiError
     */
    deleteApiV1ProjectRemove(projectId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/Project/remove/{projectId}',
            path: {
                projectId: projectId,
            },
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
}
