import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import Chart from 'react-apexcharts';
import { HStack, useTheme, Wrap, WrapItem } from '@chakra-ui/react';
import { MeasureSquare } from '../../MeasureSquare/MeasureSquare';
import ReactDOMServer from 'react-dom/server';
export const PieChart = ({ config, containerHeight, containerWidth, data, labels, }) => {
    var _a, _b, _c;
    // ApexCharts does not update the tooltip with data changes
    // This is enforcing a unique component is rendered as the data changes
    // Need to stringify data because objects are considered unique on every render
    const chartKey = useMemo(() => `${Math.random()}`, [JSON.stringify(data)]);
    const theme = useTheme();
    const colors = [
        theme.colors.unbranded.red[500],
        theme.colors.unbranded.pink[100],
        theme.colors.unbranded.pink[300],
        theme.colors.unbranded.pink[500],
        theme.colors.unbranded.yellow[100],
        theme.colors.unbranded.yellow[500],
        theme.colors.unbranded.brown[500],
        theme.colors.unbranded.green[100],
        theme.colors.unbranded.green[500],
        theme.colors.unbranded.green[900],
        theme.colors.unbranded.blue[100],
        theme.colors.unbranded.blue[300],
        theme.colors.unbranded.blue[500],
        theme.colors.unbranded.blue[900],
        theme.colors.unbranded.purple[300],
        theme.colors.unbranded.purple[500],
        theme.colors.unbranded.neutral[100],
    ];
    const options = {
        chart: {
            height: 'auto',
            width: containerWidth,
            type: 'pie',
        },
        plotOptions: {
            pie: {
                dataLabels: { minAngleToShowLabel: 36 },
            },
        },
        tooltip: {
            enabled: (_b = (_a = config === null || config === void 0 ? void 0 : config.tooltip) === null || _a === void 0 ? void 0 : _a.enabled) !== null && _b !== void 0 ? _b : true,
            custom: ((_c = config === null || config === void 0 ? void 0 : config.tooltip) === null || _c === void 0 ? void 0 : _c.custom)
                ? ({ series, dataPointIndex, seriesIndex, chartControl }) => {
                    var _a;
                    return ((_a = config === null || config === void 0 ? void 0 : config.tooltip) === null || _a === void 0 ? void 0 : _a.custom)
                        ? ReactDOMServer.renderToStaticMarkup(config.tooltip.custom({
                            series,
                            seriesIndex,
                            dataPointIndex,
                            chartControl,
                        }))
                        : null;
                }
                : undefined,
        },
        labels: labels.length ? labels : ['No Data'],
        legend: {
            show: false,
        },
        stroke: {
            show: !(data === null || data === void 0 ? void 0 : data.length),
            colors: ['#000'],
        },
        colors: (data === null || data === void 0 ? void 0 : data.length) ? colors : ['#fff'],
    };
    const legendLabels = labels.length ? labels : ['No Data'];
    const labelColors = (data === null || data === void 0 ? void 0 : data.length) ? colors : ['#fff'];
    const getColor = (index) => {
        const colorIndex = index % labelColors.length;
        return labelColors[colorIndex];
    };
    return (_jsxs(HStack, Object.assign({ height: '100%', justifyContent: 'center', width: '100%', alignItems: 'center' }, { children: [_jsx(Chart, { options: options, series: (data === null || data === void 0 ? void 0 : data.length) ? data : [1], type: 'pie', height: containerHeight, width: '100%', minwidth: '200px' }, chartKey), _jsx(Wrap, Object.assign({ gap: 0, maxW: '45%', overflowY: 'auto', maxH: containerHeight }, { children: legendLabels.map((label, i) => (_jsx(WrapItem, { children: _jsx(MeasureSquare, { text: label, color: getColor(i) }) }, i))) }))] })));
};
