import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import { SimplePin } from '../shapes/SimplePin';
import { SimpleShape } from '../shapes/SimpleShape';
import { SplitShape } from '../shapes/SplitShape';
export const StaticFloorPlanShapesLayer = ({ shapes, splitShapes, pins, scale, isPinSelected, onSelectShape, }) => {
    return (_jsxs(_Fragment, { children: [shapes.map((shape) => (_createElement(SimpleShape, Object.assign({}, { shape: shape.shape, style: shape.style }, { key: `space_${shape.identity}`, isSelected: shape.isSelected, onSelect: () => onSelectShape === null || onSelectShape === void 0 ? void 0 : onSelectShape(shape) })))), splitShapes.map((shape) => (_createElement(SplitShape, Object.assign({}, { shape: shape.shape, style: shape.style }, { key: `space_${shape.identity}`, isSelected: shape.isSelected, onSelect: () => onSelectShape === null || onSelectShape === void 0 ? void 0 : onSelectShape(shape) })))), pins.map((pin) => {
                return (_jsx(SimplePin
                // TODO: Is possible the asset pins are being rendered multiple times for each space they service
                , { 
                    // Able to because of filter above
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    location: pin.location, scale: scale, isSelected: isPinSelected(pin), color: pin.fill }, `asset_pin_${pin.identity}}_${pin.associatedShapeIdentity}`));
            })] }));
};
