import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@chakra-ui/react';
import React from 'react';
import { TableGridBody } from './TableGridBody';
import { TableGridHeader } from './TableGridHeader';
export const TableGrid = ({ styleConfig, tableData, }) => {
    const { headers, data, popoverCellIndex, emptyStateText } = tableData;
    const { table, body, cell } = styleConfig;
    return (_jsxs(Box, Object.assign({ style: table }, { children: [_jsx(TableGridHeader, { headers: headers }), _jsx(TableGridBody, { data: data, bodyStyles: body, cellStyles: cell, popoverCellIndex: popoverCellIndex, emptyStateText: emptyStateText })] })));
};
