import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { organizationSessionStore } from '@frontend/domain/contexts/Organization/OrganizationSessionHandler';
import { useOrganizationSummaries } from '@frontend/domain/contexts/Organization/OrganizationSummaryContext';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppLoading } from '../Loading/AppLoading';
export const PATH_ORG_ID = ':oid';
export function RequireWithinOrganization({ basePath, subPath, children, }) {
    const { oid } = useParams();
    const navigate = useNavigate();
    const { organizations, isLoading, onSelectOrganization } = useOrganizationSummaries();
    const [meetsRequirement, setMeetsRequirement] = useState(false);
    const { getSessionOrgIdentity } = organizationSessionStore();
    const isInSummaryList = (orgIdentity) => {
        const foundIndex = organizations.findIndex((org) => org.identity === orgIdentity);
        return foundIndex >= 0;
    };
    const createFullPath = (orgIdentity) => {
        const orgPath = `${basePath}/${orgIdentity}`;
        return subPath ? `${orgPath}${subPath}` : orgPath;
    };
    useEffect(() => {
        if (!isLoading && organizations.length > 0) {
            if (oid) {
                onSelectOrganization(parseInt(oid));
                setMeetsRequirement(true);
            }
            else {
                const sessionOrgIdentity = getSessionOrgIdentity();
                if (sessionOrgIdentity && isInSummaryList(sessionOrgIdentity)) {
                    navigate(createFullPath(sessionOrgIdentity));
                }
                else {
                    navigate(createFullPath(organizations[0].identity));
                }
            }
        }
    }, [isLoading, organizations, oid]);
    return _jsx(_Fragment, { children: meetsRequirement ? children : _jsx(AppLoading, {}) });
}
