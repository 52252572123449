var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { convertUserProfileFormToIUser } from '../../models/User/UserConversions';
import { useUserService } from '../../services/UserService';
import { useDependencies } from '../Dependencies/DependenciesContext';
export const useUser = (ignoreStatus) => {
    const { platformApi, isReady } = useDependencies();
    const { user: { activate, fetch, update }, } = useUserService({ platformApi });
    // initialize with true due to fetch in useEffect
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState();
    const [isActive, setIsActive] = useState(false);
    useEffect(() => {
        if (isReady) {
            setIsLoading(true);
            fetch()
                .then((res) => {
                setUser(res);
                setIsActive(true);
            })
                .catch((e) => {
                e.status === ignoreStatus && setIsActive(false);
            })
                .finally(() => setIsLoading(false));
        }
    }, [isReady]); // ensures only fetches user once on initialization
    const updateProfile = (profile) => {
        setIsLoading(true);
        if (user) {
            const convertedUser = convertUserProfileFormToIUser(profile, user);
            return update(convertedUser)
                .then(setUser)
                .finally(() => setIsLoading(false));
        }
        console.error('No current user, cannot update');
        setIsLoading(false);
        return Promise.reject();
    };
    const activateUser = (activateUser) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        return activate(activateUser)
            .then((res) => res)
            .finally(() => setIsLoading(false));
    });
    return { activateUser, user, isActive, updateProfile, isLoading };
};
