import { createContext, useContext } from 'react';
import { IUserBuilder } from '../../testing/platformUserMocks';
import { useOrganizationSummaries } from '../Organization/OrganizationSummaryContext';
// Only use in test suites
export const testHappyUserContext = {
    status: {
        isLoading: false,
        isActive: true,
        isEulaAccepted: true,
    },
    activateUser: (_user) => {
        return Promise.resolve('/');
    },
    user: new IUserBuilder({}).build(),
    updateUserProfile: (_user) => {
        return Promise.resolve();
    },
};
export const UserContext = createContext({});
export const useCurrentUser = () => useContext(UserContext);
export const useCurrentUserOrgRole = () => {
    const { user } = useCurrentUser();
    const { selectedOrganizationIdentity } = useOrganizationSummaries();
    if (!user || !selectedOrganizationIdentity) {
        return undefined;
    }
    const userOrg = user.organizationRoles.find((org) => org.organizationIdentity === selectedOrganizationIdentity);
    return userOrg === null || userOrg === void 0 ? void 0 : userOrg.role;
};
