const simpleStringHash = (s) => {
    return Math.abs(s.split('').reduce((hash, c) => {
        const charCode = `${c}`.charCodeAt(0);
        let newHash = (hash << 5) - hash + charCode;
        return (newHash |= 0);
    }, 0));
};
export const rgbFromRandomString = (val) => {
    const n = simpleStringHash(val);
    const r = (n * 1223) % 256;
    const g = (n * 2069) % 256;
    const b = (n * 977) % 256;
    return `rgb(${r}, ${g}, ${b})`;
};
