import { jsx as _jsx } from "react/jsx-runtime";
import { RemovableBulb } from '@frontend/design-system/components/FilterRow/RemovableBulb';
import { instanceOfRangeValue, } from '@frontend/design-system/components/Inputs/RangeInput';
export const ProjectFilterBulbDisplay = ({ filterKey, filterValue, clearFilter, format, }) => {
    // TODO: Defaulting to the currency formatter if no formatting function is passed through
    // This is because we're not passing in a format function for the estimated budget values.
    // Ideally, we'd want to eventually get rid of this hard coded currency formatter and have
    // it passed through.
    const getRangeValueLabel = (rangeValue) => {
        const currencyFormatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            maximumFractionDigits: 0,
        }).format;
        const formatter = format || currencyFormatter;
        const stringMin = rangeValue.min !== undefined ? formatter(rangeValue.min) : undefined;
        const stringMax = rangeValue.max !== undefined ? formatter(rangeValue.max) : undefined;
        if (rangeValue.max !== undefined && rangeValue.min !== undefined) {
            return `${stringMin} to ${stringMax}`;
        }
        else if (rangeValue.max !== undefined) {
            return `Under ${stringMax}`;
        }
        else if (rangeValue.min !== undefined) {
            return `Above ${stringMin}`;
        }
        else {
            return 'Range Error'; // should not get here
        }
    };
    if (typeof filterValue === 'boolean') {
        return (_jsx(RemovableBulb, { label: filterKey, removeBulb: clearFilter }, `${filterKey}_${filterValue}`));
    }
    else if (typeof filterValue === 'string') {
        const value = filterValue.toString();
        return (_jsx(RemovableBulb, { label: value, removeBulb: clearFilter }, `${filterKey}_${filterValue}`));
    }
    else if (instanceOfRangeValue(filterValue)) {
        const value = filterValue;
        const label = getRangeValueLabel(value);
        return (_jsx(RemovableBulb, { label: label, removeBulb: clearFilter }, `${filterKey}_${filterValue}`));
    }
    else {
        return (_jsx(RemovableBulb, { label: filterKey, removeBulb: clearFilter }, `${filterKey}_${filterValue}`));
    }
};
