import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, HStack, Input, Text, Textarea } from '@chakra-ui/react';
import { CurrencyInput } from '@frontend/design-system/components/Inputs/CurrencyInput';
import { DateInput, getMaxDate, getMinDate, validateDateRange, } from '@frontend/design-system/components/Inputs/DateInput';
import { useEffect } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { BiSolidTrashAlt } from 'react-icons/bi';
import { slgqColorSchemes } from '@frontend/design-system/theme/theme';
export const RebateInput = ({ index, removeRebate, showDelete, formContext, }) => {
    const { control, trigger } = formContext;
    const rebate = useWatch({ name: `rebates.${index}`, control });
    useEffect(() => {
        trigger();
    }, [rebate]);
    const min = 1776;
    const max = new Date().getUTCFullYear() + 20;
    const validateRebates = (value, fieldName) => {
        rebate[fieldName] = value;
        if ((rebate.amount === '' || rebate.amount === undefined) &&
            (rebate.program === '' || rebate.program === undefined) &&
            (rebate.expirationDate === '' || rebate.expirationDate === undefined) &&
            (rebate.notes === '' || rebate.notes === undefined)) {
            return true;
        }
        if (rebate.amount !== '' &&
            rebate.amount !== undefined &&
            rebate.program !== '' &&
            rebate.program !== undefined &&
            rebate.expirationDate !== '' &&
            rebate.expirationDate !== undefined) {
            return true;
        }
        return false;
    };
    const validateExpirationDateInput = (value) => {
        const min = new Date();
        min.setUTCFullYear(1776, 0, 1);
        const max = new Date();
        max.setUTCFullYear(new Date().getUTCFullYear() + 20);
        const invalidYearMessage = validateDateRange(value, min, max);
        const isValidRebate = validateRebates(value, 'expirationDate');
        if (invalidYearMessage) {
            return invalidYearMessage;
        }
        return isValidRebate;
    };
    return (_jsxs(HStack, Object.assign({ w: '100%', align: 'flex-start' }, { children: [_jsxs(HStack, Object.assign({ w: '60%', align: 'flex-start' }, { children: [_jsx(Controller, { name: `rebates.${index}.amount`, control: control, rules: { validate: (value) => validateRebates(value, 'amount') }, render: ({ field: { value, onChange } }) => (_jsx(CurrencyInput, { data: value, maskOptions: { allowDecimal: false }, onInputChange: onChange, placeholder: '' })) }), _jsx(Text, Object.assign({ pt: 2 }, { children: "from" })), _jsx(Controller, { name: `rebates.${index}.program`, control: control, rules: { validate: (value) => validateRebates(value, 'program') }, render: ({ field: { value, onChange } }) => (_jsx(Input, { "data-testid": 'rebate-program-input', value: value, placeholder: '', onChange: onChange, maxLength: 30 })) }), _jsx(Text, Object.assign({ pt: 2 }, { children: "expires on" })), _jsx(Controller, { name: `rebates.${index}.expirationDate`, control: control, rules: {
                            validate: (value) => validateExpirationDateInput(value),
                        }, render: ({ field: { value, onChange }, fieldState: { error } }) => {
                            var _a;
                            return (_jsx(DateInput, { label: '', data: (_a = value === null || value === void 0 ? void 0 : value.split('T')[0]) !== null && _a !== void 0 ? _a : '', onInputChange: onChange, onInputBlur: (date) => {
                                    onChange(date);
                                }, minDate: getMinDate(min), maxDate: getMaxDate(max), errorMessage: error === null || error === void 0 ? void 0 : error.message }));
                        } })] })), _jsxs(HStack, Object.assign({ w: '40%' }, { children: [_jsx(Controller, { name: `rebates.${index}.notes`, control: control, rules: {
                            validate: (value) => validateRebates(value, 'notes'),
                        }, render: ({ field: { value, onChange } }) => (_jsx(Textarea, { value: value, placeholder: 'Type notes here...', onChange: onChange, maxLength: 250, fontSize: '14px', lineHeight: '22px', minH: '58px' })) }), showDelete && (_jsx(Button, Object.assign({ "aria-label": 'delete rebate', colorScheme: 'none', background: 'transparent', color: slgqColorSchemes.primBlue, height: 'fit-content', onClick: removeRebate }, { children: _jsx(BiSolidTrashAlt, {}) })))] }))] })));
};
