export class SettingsService {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * @param organizationId
     * @returns SettingsResponse OK
     * @throws ApiError
     */
    getApiV1SettingsLoad(organizationId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/Settings/load/{organizationId}',
            path: {
                organizationId: organizationId,
            },
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @param requestBody
     * @returns SettingsResponse OK
     * @throws ApiError
     */
    postApiV1SettingsConfigure(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/Settings/configure',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
}
