export const convertResponseToSettings = (response, orgIdentity) => {
    return {
        fundingTypes: convertFundingTypesSettingsResponseToIFundingTypes(response.fundingTypes),
        projectTypes: convertProjectTypesSettingsResponseToIProjectTypes(response.projectTypes),
        prioritizationAlgorithms: convertPrioritizationAlgorithmsResponseToIPrioritizationAlgorithms(response.priorityAlgorithms, orgIdentity),
        priorityCriteria: convertPriorityCriteriaResponseToIPriorityCriteria(response.priorityCriteria),
        facilityGroupers: convertFacilityGroupersResponseToISettingsFacilityGroupers(response.facilityGroupers),
        escalationFactor: convertEscalationFactorResponseToIEscalationFactor(response.escalationFactor),
        useAgeAdjustedCondition: response.useAgeAdjustedCondition,
    };
};
export const convertFundingTypesSettingsResponseToIFundingTypes = (data) => {
    return data.map(convertFundingTypeSettingsResponseToIFundingType);
};
export const convertFundingTypeSettingsResponseToIFundingType = (data) => {
    return {
        identity: data.fundingTypeId,
        name: data.fundingTypeName,
        isInUse: data.isInUse,
    };
};
export const convertFundingTypesResponseToIFundingTypes = (data) => {
    return data.map(convertFundingTypeResponseToIFundingType);
};
export const convertFundingTypeResponseToIFundingType = (data) => {
    return {
        identity: data.fundingTypeId,
        name: data.fundingTypeName,
    };
};
export const convertProjectTypesSettingsResponseToIProjectTypes = (data) => {
    return data.map(convertProjectTypeSettingsResponseToIProjectType);
};
export const convertProjectTypeSettingsResponseToIProjectType = (data) => {
    return {
        identity: data.projectTypeId,
        name: data.projectTypeName,
        algorithmId: data.algorithmId,
        isInUse: data.isInUse,
    };
};
export const convertProjectTypesResponseToIProjectTypes = (data) => {
    return data.map(convertProjectTypeResponseToIProjectType);
};
export const convertProjectTypeResponseToIProjectType = (data) => {
    return {
        identity: data.projectTypeId,
        name: data.projectTypeName,
        algorithmId: data.algorithmId,
    };
};
export const convertPrioritizationAlgorithmsResponseToIPrioritizationAlgorithms = (data, orgIdentity) => {
    return data.map((pa) => {
        return {
            orgIdentity: orgIdentity,
            identity: pa.algorithmId,
            criteria: convertAlgorithmCriteriaResponseToIPrioritizationWeight(pa.criteria),
        };
    });
};
export const convertAlgorithmCriteriaResponseToIPrioritizationWeight = (data) => {
    return data.map((pw) => {
        return {
            identity: pw.criteriaId,
            name: pw.criteriaName,
            type: pw.criteriaType,
            weight: pw.weight,
        };
    });
};
export const convertPriorityCriteriaResponseToIPriorityCriteria = (data) => {
    return data.map((pc) => {
        return {
            identity: pc.criteriaId,
            name: pc.criteriaName,
            type: pc.criteriaType,
        };
    });
};
export const convertFacilityGroupersResponseToISettingsFacilityGroupers = (data) => {
    return data.map((fg) => {
        return {
            grouperIndex: fg.facilityGrouperNumber,
            name: fg.facilityGrouperName,
        };
    });
};
export const convertFacilityGroupersResponseToIPiqFacilityGroupers = (data, orgIdentity) => {
    const grouperOne = data.find((grouper) => grouper.grouperIndex === 1);
    const grouperTwo = data.find((grouper) => grouper.grouperIndex === 2);
    return {
        orgIdentity: orgIdentity,
        grouperNameOne: (grouperOne === null || grouperOne === void 0 ? void 0 : grouperOne.name) || 'Facility Grouper 1',
        grouperNameTwo: (grouperTwo === null || grouperTwo === void 0 ? void 0 : grouperTwo.name) || 'Facility Grouper 2',
    };
};
export const convertEscalationFactorResponseToIEscalationFactor = (data) => {
    if (data) {
        return {
            escalation: data,
        };
    }
    else {
        return undefined;
    }
};
export const convertSettingsToRequest = (orgIdentity, settings) => {
    return {
        organizationId: orgIdentity,
        fundingTypes: settings.fundingTypes
            ? convertFundingTypesToRequest(settings.fundingTypes)
            : undefined,
        projectTypes: settings.projectTypes
            ? convertProjectTypesToRequest(settings.projectTypes)
            : undefined,
        escalationFactor: settings.escalationFactor
            ? convertEscalationFactorToRequest(settings.escalationFactor)
            : undefined,
        useAgeAdjustedCondition: settings.useAgeAdjustedCondition,
        projectPriorityAlgorithms: settings.prioritizationAlgorithms
            ? convertPrioritizationAlgorithmsToRequest(settings.prioritizationAlgorithms)
            : undefined,
        facilityGroupers: settings.facilityGroupers
            ? convertFacilityGroupersToRequest(settings.facilityGroupers)
            : undefined,
    };
};
const convertFundingTypesToRequest = (fundingTypes) => {
    return fundingTypes.map((ft) => {
        return {
            fundingTypeId: ft.identity,
            fundingTypeName: ft.name,
            remove: ft.remove || false,
        };
    });
};
const convertProjectTypesToRequest = (projectTypes) => {
    return projectTypes.map((pt) => {
        return {
            projectTypeId: pt.identity,
            projectTypeName: pt.name,
            algorithmId: pt.algorithmId,
            remove: pt.remove || false,
        };
    });
};
const convertEscalationFactorToRequest = (escalationFactor) => {
    return {
        escalationFactor: escalationFactor.escalation,
        remove: escalationFactor.remove || false,
    };
};
const convertPrioritizationAlgorithmsToRequest = (algorithms) => {
    return algorithms.map((pa) => {
        return {
            algorithmId: pa.identity,
            criteria: convertPrioritizationWeightsToRequest(pa.criteria),
        };
    });
};
const convertPrioritizationWeightsToRequest = (weights) => {
    return weights.map((pw) => {
        return {
            criteriaId: pw.identity,
            criteriaName: pw.name,
            weight: pw.weight,
        };
    });
};
const convertFacilityGroupersToRequest = (facilityGroupers) => {
    return facilityGroupers.map((grouper) => {
        return {
            facilityGrouperNumber: grouper.grouperIndex,
            facilityGrouperName: grouper.name,
        };
    });
};
