export class FacilityService {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Exports the floor plans, spaces, and assets for a facility.
     * Returns the individual CSVs for the plans, spaces, and assets in the facility as a zip file.
     * Filename will be in the content-disposition header.
     * @param requestBody IDs of the facility and organization to export
     * @returns binary OK
     * @throws ApiError
     */
    postApiV1FacilityExport(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/Facility/export',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Returns the system data necessary to create or edit a facility.
     * @param organizationId ID of the organization the facility belongs to
     * @returns FacilityTemplateResponse OK
     * @throws ApiError
     */
    getApiV1FacilityInitialize(organizationId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/Facility/initialize/{organizationId}',
            path: {
                organizationId: organizationId,
            },
            errors: {
                403: `Forbidden`,
            },
        });
    }
}
