import { OrganizationRoleEnum, SystemRole, } from '../models/Roles';
export function toSystemRoleEnum(role) {
    switch (role) {
        case SystemRole.SUPER_ADMINISTRATOR.toString():
            return SystemRole.SUPER_ADMINISTRATOR;
        case SystemRole.ORGANIZATION_APPROVER.toString():
            return SystemRole.ORGANIZATION_APPROVER;
        default:
            return SystemRole.USER;
    }
}
export function toFriendlySystemRole(role) {
    switch (role) {
        case SystemRole.SUPER_ADMINISTRATOR:
            return 'Super Admin';
        case SystemRole.ORGANIZATION_APPROVER:
            return 'Org Approver';
        case SystemRole.USER:
            return 'User';
        default:
            return 'User';
    }
}
export function toOrgRoleEnum(role) {
    switch (role) {
        case OrganizationRoleEnum.LOCAL_ADMIN.toString():
            return OrganizationRoleEnum.LOCAL_ADMIN;
        case OrganizationRoleEnum.CLIENT_CONCIERGE.toString():
            return OrganizationRoleEnum.CLIENT_CONCIERGE;
        case OrganizationRoleEnum.MEMBER.toString():
            return OrganizationRoleEnum.MEMBER;
        case OrganizationRoleEnum.VISITOR.toString():
            return OrganizationRoleEnum.VISITOR;
        default:
            return OrganizationRoleEnum.NONE;
    }
}
export function toFriendlyOrgRole(role) {
    switch (role) {
        case OrganizationRoleEnum.LOCAL_ADMIN:
            return 'Local Administrator';
        case OrganizationRoleEnum.CLIENT_CONCIERGE:
            return 'Client Concierge';
        case OrganizationRoleEnum.MEMBER:
            return 'Member';
        case OrganizationRoleEnum.VISITOR:
            return 'Visitor';
        default:
            return 'None';
    }
}
export function toFriendlyFacilityRole(role) {
    switch (role) {
        case 'Member':
            return 'Member';
        case 'Visitor':
            return 'Visitor';
        default:
            return 'None';
    }
}
export function toFacilityRole(role) {
    switch (role) {
        case 'Member':
            return 'Member';
        case 'Visitor':
            return 'Visitor';
        default:
            return 'None';
    }
}
