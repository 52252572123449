var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { usePlatformResponseWrapper } from '../hooks/responseWrappers/usePlatformResponseWrapper';
import { convertResponseToSettings, convertSettingsToRequest, } from '../models/Settings/SettingsConversions';
export const useSettingsService = ({ platformApi }) => {
    const { handleResponse: handlePlatformResponse } = usePlatformResponseWrapper();
    const fetchSettings = (orgIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        return handlePlatformResponse(platformApi.settings.getApiV1SettingsLoad(orgIdentity), {
            error: { label: 'Error occurred retrieving Planning Settings' },
            success: { enabled: false },
        })
            .then((res) => 
        // passing orgIdentity is temporary until project comes from platformAPI
        convertResponseToSettings(res, orgIdentity))
            .catch(() => undefined);
    });
    const upsertSettings = (orgIdentity, settings, settingsType) => __awaiter(void 0, void 0, void 0, function* () {
        const upsertData = convertSettingsToRequest(orgIdentity, settings);
        return handlePlatformResponse(platformApi.settings.postApiV1SettingsConfigure(upsertData), {
            error: {
                label: `Error occurred updating myPlanning setting: ${String(settingsType)}`,
            },
            success: {
                enabled: true,
                label: `Updated myPlanning setting: ${String(settingsType)}`,
            },
        }).then((res) => 
        // passing orgIdentity is temporary until project comes from platformAPI
        convertResponseToSettings(res, orgIdentity));
    });
    return {
        settings: {
            fetch: fetchSettings,
            update: upsertSettings,
        },
    };
};
