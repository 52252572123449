import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Circle, Line } from 'react-konva';
import { appColors } from '../../../theme/constants';
export const DrawPolygonShape = ({ tempPoints, pointSize, shape, }) => {
    const getOrderedLinePoints = (points) => {
        let linePoints = [];
        points
            .sort((a, b) => a.pointIndex - b.pointIndex)
            .forEach((point) => (linePoints = [...linePoints, point.x, point.y]));
        return linePoints;
    };
    // Shape config or shape should populate the points
    const renderDrawingShape = (points) => {
        if (points.length > 0) {
            return (_jsxs(_Fragment, { children: [_jsx(Line, { points: getOrderedLinePoints(points), stroke: appColors.SEC_ORANGE, strokeWidth: 2, dash: [33, 10], fill: appColors.KONVA_ORANGE }), tempPoints.map((point, i) => (_jsx(Circle, { x: point.x, y: point.y, width: pointSize, height: pointSize, fill: 'lightblue', opacity: 0.7, stroke: 'black', strokeWidth: 1 }, `drawing shape ${point} ${i}`)))] }));
        }
        return _jsx(_Fragment, {});
    };
    const renderShape = (shape) => {
        return (_jsx(Line, { points: getOrderedLinePoints(shape), stroke: appColors.SEC_ORANGE, strokeWidth: 2, dash: [33, 10], fill: appColors.KONVA_ORANGE, closed: true }));
    };
    return shape.length > 0
        ? renderShape(shape)
        : renderDrawingShape(tempPoints);
};
