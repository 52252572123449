import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, HStack, Link, Text, VStack } from '@chakra-ui/react';
import { CheckboxInput } from '@frontend/design-system/components/DynamicInput/CheckboxInput';
import theme from '@frontend/design-system/theme/theme';
export const AcceptEULA = ({ field, error }) => {
    const termsOfUse = 'https://www.sitelogiq.com/mysiteiq-eula/';
    const renderLink = () => {
        return (_jsx(Link, Object.assign({ color: theme.colors.brand.primBlue, sx: {
                fontStyle: 'italic',
                textDecoration: 'underline',
                fontWeight: 400,
                fontSize: '14px',
            }, target: '_blank', href: termsOfUse }, { children: "Terms of Use." })));
    };
    return (_jsxs(HStack, Object.assign({ alignItems: 'flex-start', gap: '20px' }, { children: [_jsx(Box, Object.assign({ boxSize: '16px' }, { children: _jsx(CheckboxInput, { label: '', editMode: true, data: field.value, onInputChange: field.onChange, errorMessage: error === null || error === void 0 ? void 0 : error.message, position: 'left' }) })), _jsxs(VStack, Object.assign({ alignItems: 'flex-start', gap: 0 }, { children: [_jsxs(Text, Object.assign({ fontWeight: 400, fontSize: '14px' }, { children: ["Please review and accept the ", renderLink()] })), _jsxs(Text, Object.assign({ fontWeight: 400, fontSize: '14px' }, { children: ["By checking this box, you are accepting that you have read and agree with the ", renderLink()] }))] }))] })));
};
