import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, HStack, Input, VStack } from '@chakra-ui/react';
import { CurrencyInput } from '@frontend/design-system/components/Inputs/CurrencyInput';
import { Controller } from 'react-hook-form';
import { convertToCurrency } from '../../../../config/CPHelperFunctions';
import { TaxImpactText } from './TaxImpactText';
import { TaxImpactValueText } from './TaxImpactValueText';
const STATE_AID_DESCRIPTION_KEY = 'stateAidDescription';
const STATE_AID_KEY = 'stateAid';
export const TotalStateAidInput = ({ taxImpactExists, formContext, }) => {
    const { control, resetField, watch } = formContext;
    const textFormValue = watch(STATE_AID_DESCRIPTION_KEY);
    const currencyValue = watch(STATE_AID_KEY);
    const anyFieldFilled = textFormValue || currencyValue;
    return taxImpactExists && currencyValue ? (_jsxs(VStack, Object.assign({ w: '100%', spacing: 0.5, "aria-label": 'total-state-aid-values' }, { children: [_jsx(TaxImpactText, { text: `Total ${textFormValue} state aid` }), _jsx(TaxImpactValueText, { text: convertToCurrency(currencyValue) })] }))) : (_jsxs(VStack, Object.assign({ w: '100%', spacing: 1, "aria-label": 'total-state-aid-form' }, { children: [_jsxs(HStack, Object.assign({ w: '100%', spacing: 0.5 }, { children: [_jsx(TaxImpactText, { text: 'Total' }), _jsx(Controller, { control: control, name: STATE_AID_DESCRIPTION_KEY, rules: { required: anyFieldFilled ? true : false }, render: ({ field }) => (_jsx(Box, Object.assign({ ml: '5px', mr: '5px' }, { children: _jsx(Input, { h: '25px', w: '125px', placeholder: 'Type here...', onChange: (e) => {
                                    if (!(e && e.target.value) || e.target.value === '') {
                                        resetField(STATE_AID_DESCRIPTION_KEY);
                                    }
                                    field.onChange(e);
                                } }) }))) }), _jsx(TaxImpactText, { text: 'state aid' })] })), _jsx(Controller, { control: control, name: STATE_AID_KEY, rules: { required: anyFieldFilled ? true : false }, render: ({ field }) => (_jsx(CurrencyInput, { data: field.value, onInputChange: (e) => {
                        if (!(e && e.target.value) || e.target.value === '') {
                            resetField(STATE_AID_KEY);
                        }
                        field.onChange(e);
                    }, placeholder: 'Type here...' })) })] })));
};
