import { OrganizationRoleEnum, SystemRole, } from '../../models/Roles';
/** Current Hierarchy of system role
 * Super Administrator
 * Org Approver
 * User
 */
/** Current Hierarchy of org role
 * Client Concierge
 * Local Administrator
 * Member
 * Visitor
 */
/** Current Hierarchy of facility role
 * Member
 * Visitor
 * None
 */
export const isSuperAdmin = (role) => {
    return role === SystemRole.SUPER_ADMINISTRATOR;
};
const ADMIN_ROLES = [
    SystemRole.SUPER_ADMINISTRATOR,
    SystemRole.ORGANIZATION_APPROVER,
];
export const isSystemAdmin = (role) => {
    return role ? ADMIN_ROLES.includes(role) : false;
};
const determineOrgRole = (user, identity) => {
    if (!user || !identity) {
        return undefined;
    }
    if (isSystemAdmin(user.systemRole)) {
        return undefined; // should always check admin access prior to relying on org
    }
    const orgRole = user.organizationRoles.find((role) => role.organizationIdentity === identity);
    return orgRole ? orgRole.role : undefined;
};
export var UserManagementContext;
(function (UserManagementContext) {
    UserManagementContext[UserManagementContext["ADMIN"] = 0] = "ADMIN";
    UserManagementContext[UserManagementContext["ORG"] = 1] = "ORG";
})(UserManagementContext || (UserManagementContext = {}));
export const isPrimaryContact = (context, user, organizationIdentity) => {
    if (context === UserManagementContext.ADMIN) {
        return user.primaryContactOrganizations.length > 0;
    }
    if (organizationIdentity) {
        const foundIndex = user.primaryContactOrganizations.findIndex((org) => org.identity === organizationIdentity);
        return foundIndex >= 0;
    }
    return false;
};
export const hasAppAccess = (user, orgApp, organizationIdentity, app) => {
    if (!user || !organizationIdentity || orgApp.length < 1) {
        return false;
    }
    if (isLocalAdminPlus(user, organizationIdentity)) {
        const foundIndex = orgApp.findIndex((orgApp) => orgApp.app === app && orgApp.enabled);
        //find index returns -1 if not found
        return foundIndex >= 0;
    }
    const foundIndex = user.appAccess.findIndex((appAccess) => appAccess.name === app &&
        appAccess.organizationIdentity === organizationIdentity);
    //find index returns -1 if not found
    return foundIndex >= 0;
};
export const isClientConciergePlus = (user, organizationIdentity) => {
    if (isSystemAdmin(user === null || user === void 0 ? void 0 : user.systemRole)) {
        return true;
    }
    const foundRole = determineOrgRole(user, organizationIdentity);
    return foundRole === OrganizationRoleEnum.CLIENT_CONCIERGE;
};
export const isLocalAdminPlus = (user, organizationIdentity) => {
    if (isSystemAdmin(user === null || user === void 0 ? void 0 : user.systemRole)) {
        return true;
    }
    const foundRole = determineOrgRole(user, organizationIdentity);
    return (foundRole === OrganizationRoleEnum.CLIENT_CONCIERGE ||
        foundRole === OrganizationRoleEnum.LOCAL_ADMIN);
};
export const isOrgMemberPlus = (user, organizationIdentity) => {
    if (isSystemAdmin(user === null || user === void 0 ? void 0 : user.systemRole)) {
        return true;
    }
    const foundRole = determineOrgRole(user, organizationIdentity);
    return (foundRole === OrganizationRoleEnum.CLIENT_CONCIERGE ||
        foundRole === OrganizationRoleEnum.LOCAL_ADMIN ||
        foundRole === OrganizationRoleEnum.MEMBER);
};
// Will need to change when we switch to facility exceptions
const determineFacilityRole = (user, organizationIdentity, facilityIdentity) => {
    if (isLocalAdminPlus(user, organizationIdentity)) {
        return 'None'; // should always check admin access and org access prior to relying on org
    }
    const foundRole = user === null || user === void 0 ? void 0 : user.facilityExceptions.find((facRole) => facRole.facilityIdentity === facilityIdentity);
    return foundRole ? foundRole.role : 'None';
};
export const canEditFacility = (user, organizationIdentity, facilityIdentity) => {
    if (isLocalAdminPlus(user, organizationIdentity)) {
        return true;
    }
    const facilityRole = determineFacilityRole(user, organizationIdentity, facilityIdentity);
    return facilityRole === 'Member';
};
export const canViewFacility = (user, organizationIdentity, facilityIdentity) => {
    if (isLocalAdminPlus(user, organizationIdentity)) {
        return true;
    }
    const facilityRole = determineFacilityRole(user, organizationIdentity, facilityIdentity);
    return facilityRole !== 'None';
};
