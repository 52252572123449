import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, HStack, Input, Text, Textarea } from '@chakra-ui/react';
import { SelectInput } from '@frontend/design-system/components/DynamicInput/SelectInput';
import { CurrencyInput } from '@frontend/design-system/components/Inputs/CurrencyInput';
import { useEffect } from 'react';
import { useController, useWatch, } from 'react-hook-form';
import { BiSolidTrashAlt } from 'react-icons/bi';
import { slgqColorSchemes } from '@frontend/design-system/theme/theme';
export const DollarSavingsInputs = ({ index, control, removeSaving, canRemove, trigger, }) => {
    const typeOptions = ['Utility', 'Non-Utility'];
    const dollarSavings = useWatch({
        name: `dollarSavings.${index}`,
        control: control,
    });
    useEffect(() => {
        trigger();
    }, [dollarSavings]);
    const validateDollarSavings = (value, fieldName) => {
        dollarSavings[fieldName] = value;
        if ((dollarSavings.saving === '' || dollarSavings.saving === undefined) &&
            (dollarSavings.type === '' || dollarSavings.type === undefined) &&
            (dollarSavings.duration === '' || dollarSavings.duration === undefined) &&
            (dollarSavings.notes === '' || dollarSavings.notes === undefined)) {
            return true;
        }
        if (dollarSavings.saving !== '' &&
            dollarSavings.saving !== undefined &&
            dollarSavings.type !== '' &&
            dollarSavings.type !== undefined &&
            dollarSavings.duration !== '' &&
            dollarSavings.duration !== undefined) {
            return true;
        }
        return false;
    };
    const savingController = useController({
        name: `dollarSavings.${index}.saving`,
        control,
        defaultValue: undefined,
        rules: {
            min: {
                value: 0,
                message: 'Saving amount has to be positive',
            },
            validate: (value) => validateDollarSavings(value, 'saving'),
        },
    });
    const typeController = useController({
        name: `dollarSavings.${index}.type`,
        control,
        defaultValue: '',
        rules: {
            validate: (value) => validateDollarSavings(value, 'type'),
        },
    });
    const durationController = useController({
        name: `dollarSavings.${index}.duration`,
        control,
        defaultValue: undefined,
        rules: {
            min: {
                value: 0,
                message: 'Duration must be between 0 and 100',
            },
            max: {
                value: 100,
                message: 'Duration must be between 0 and 100',
            },
            validate: (value) => validateDollarSavings(value, 'duration'),
        },
    });
    const notesController = useController({
        name: `dollarSavings.${index}.notes`,
        control,
        defaultValue: '',
        rules: {
            maxLength: {
                value: 250,
                message: 'Notes cannot exceed 250 characters',
            },
            validate: (value) => validateDollarSavings(value, 'notes'),
        },
    });
    const { field: { onChange: savingOnChange, value: savingValue }, } = savingController;
    const { field: { onChange: typeOnChange, value: typeValue }, } = typeController;
    const { field: { onChange: durationOnChange, value: durationValue }, } = durationController;
    const { field: { onChange: notesOnChange, value: notesValue }, } = notesController;
    const handleDurationChange = (e) => {
        let value = e.target.value;
        if (value < 0) {
            value = '0';
        }
        if (value > 100) {
            value = '100';
        }
        durationOnChange(value);
    };
    return (_jsxs(HStack, Object.assign({ w: '100%', align: 'start' }, { children: [_jsxs(HStack, Object.assign({ w: '60%' }, { children: [_jsx(CurrencyInput, { data: savingValue ? savingValue.toString() : '', maskOptions: { allowDecimal: false }, onInputChange: savingOnChange, placeholder: '' }), _jsx(SelectInput, { editable: true, data: typeValue, choices: typeOptions.map((type) => ({
                            label: type,
                            value: type,
                        })), placeholder: 'Select', onInputChange: typeOnChange }), _jsx(Text, { children: "saved per year for" }), _jsx(Input, { ml: '5px', mr: '5px', w: '145px', type: 'number', value: durationValue, onChange: handleDurationChange }), _jsx(Text, { children: "years" })] })), _jsxs(HStack, Object.assign({ w: '40%' }, { children: [_jsx(Textarea, { value: notesValue, placeholder: 'Type notes here...', onChange: notesOnChange, maxLength: 250, fontSize: '14px', lineHeight: '22px', minH: '58px' }), canRemove && (_jsx(Button, Object.assign({ colorScheme: 'none', background: 'transparent', color: slgqColorSchemes.primBlue, height: 'fit-content', onClick: () => removeSaving(index) }, { children: _jsx(BiSolidTrashAlt, {}) })))] }))] })));
};
