import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, HStack, IconButton, Spacer, Tab, TabList, TabPanel, TabPanels, Tabs, Text, } from '@chakra-ui/react';
import { useCarousel } from '@frontend/design-system/components/Carousel/Carousel';
import { appColors } from '@frontend/design-system/theme/constants';
import { parseCurrencyAsFloat } from '@frontend/domain/conversion/NumberConversions';
import React from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { convertToCurrency } from '../../../config/CPHelperFunctions';
import { slgqColorSchemes } from '@frontend/design-system/theme/theme';
import { FundingTypeInput } from './FundingTypeInput';
export const FundingTypeByYearInputs = ({ formContext, fields, updateFundingType, }) => {
    const getTabMap = (fields) => {
        // assumes form contains all potential entires from conversion
        const tabMap = new Map();
        fields.forEach((field, index) => {
            if (field.year) {
                const foundEntry = tabMap.get(field.year);
                if (foundEntry) {
                    foundEntry.push(_jsx(FundingTypeInput, { formContext: formContext, fundingTypeBudget: field, index: index, updateOnChange: updateFundingType }, field.id));
                    tabMap.set(field.year, foundEntry);
                }
                else {
                    tabMap.set(field.year, [
                        _jsx(FundingTypeInput, { formContext: formContext, fundingTypeBudget: field, index: index, updateOnChange: updateFundingType }, field.id),
                    ]);
                }
            }
        });
        return tabMap;
    };
    const getBudgetForYear = (year) => {
        const sum = fields
            .filter((field) => field.year === year)
            .reduce((sum, field) => field.budget ? sum + parseCurrencyAsFloat(field.budget) : sum, 0);
        return convertToCurrency(`${sum}`);
    };
    const carouselItems = 5;
    const tabMap = getTabMap(fields);
    const { currentItems, prev, canGoPrev, next, canGoNext, index, setIndex } = useCarousel(Array.from(tabMap.entries()), carouselItems);
    const lightGreyFromDS = appColors.SEC_LIGHT_GREY;
    const textColorFromDS = appColors.TEXT_COLOR;
    const primaryBlueFromDS = appColors.PRIM_BLUE;
    return (_jsxs(Tabs, Object.assign({ w: '100%', index: index, onChange: setIndex }, { children: [_jsxs(HStack, Object.assign({ p: 0, m: 0, justify: 'space-between' }, { children: [tabMap.size > carouselItems && (_jsx(IconButton, { variant: 'ghost', borderRadius: 0, "aria-label": 'previous year', icon: _jsx(MdChevronLeft, {}), onClick: prev, isDisabled: !canGoPrev })), _jsx(TabList, { children: currentItems.map(([year]) => (_jsx(Tab, Object.assign({ fontWeight: 400, fontSize: '14px', color: textColorFromDS, _selected: {
                                bg: lightGreyFromDS,
                                color: primaryBlueFromDS,
                                borderBottom: `3px solid ${lightGreyFromDS}`,
                                fontWeight: 600,
                                fontSize: '14px',
                                shadow: '1px -1px 1px 0 rgba(0, 0, 0, 0.15)',
                            } }, { children: year }), year))) }), tabMap.size > carouselItems && (_jsx(IconButton, { variant: 'ghost', borderRadius: 0, "aria-label": 'next year', icon: _jsx(MdChevronRight, {}), onClick: next, isDisabled: !canGoNext }))] })), _jsx(TabPanels, Object.assign({ backgroundColor: lightGreyFromDS }, { children: currentItems.map((mapEntry) => (_jsxs(TabPanel, Object.assign({ shadow: '2px 1px 2px 0px rgba(0, 0, 0, 0.15)' }, { children: [_jsx(Box, Object.assign({ height: '400px', overflowY: 'auto' }, { children: mapEntry[1] })), _jsx(Spacer, { h: '20px' }), _jsxs(Text, Object.assign({ colorScheme: slgqColorSchemes.primBlue }, { children: ["My total budget in ", mapEntry[0], ' ', _jsx("b", { children: getBudgetForYear(mapEntry[0]) }), " USD"] }))] }), mapEntry[0]))) }))] })));
};
