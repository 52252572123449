import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Box, Checkbox, HStack, ListItem, Text, VStack, } from '@chakra-ui/react';
import React, { useState } from 'react';
import { appColors } from '../../../config/constants';
export const SelectProjectRow = ({ project, isSelected, handleProjectChange, }) => {
    const [expand, setExpand] = useState(false);
    const handleCheckboxChange = (e) => {
        handleProjectChange(project, e.target.checked);
    };
    return (_jsxs(ListItem, Object.assign({ mb: '15px', w: '100%', display: 'flex', justifyContent: 'space-between' }, { children: [_jsxs(Box, Object.assign({ w: '90%' }, { children: [_jsxs(HStack, Object.assign({ fontWeight: 400, fontSize: '14px', alignItems: 'flex-start' }, { children: [_jsx(Text, Object.assign({ whiteSpace: 'normal', wordBreak: 'break-word' }, { children: project.name })), expand ? (_jsx(ChevronUpIcon, { "data-testid": 'project-arrow-up', cursor: 'pointer', onClick: () => setExpand(false), boxSize: 4 })) : (_jsx(ChevronDownIcon, { "data-testid": 'project-arrow-down', cursor: 'pointer', onClick: () => setExpand(true), boxSize: 4 }))] })), expand && (_jsxs(VStack, Object.assign({ display: 'block', fontSize: '10px' }, { children: [_jsxs(Text, { children: [_jsx("b", { children: "Facility:" }), " ", project.facilityName] }), _jsxs(Text, { children: [_jsx("b", { children: "Scope:" }), " ", project.scope] })] })))] })), _jsx(Box, { children: _jsx(Checkbox, { iconColor: appColors.SEC_ORANGE, isChecked: isSelected, onChange: handleCheckboxChange, color: appColors.PRIM_BLUE, sx: {
                        '& .chakra-checkbox__control': {
                            borderColor: appColors.PRIM_LIGHT_GREY,
                            borderWidth: '2px',
                        },
                    } }) })] })));
};
