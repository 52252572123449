import { parseCurrencyAsFloat, parseNumberAsString, } from '../conversion/NumberConversions';
export const validateMinMax = (value, min, max) => {
    if (value !== undefined) {
        const parsedNum = typeof value === 'number' ? value : parseCurrencyAsFloat(value);
        if (parsedNum < min || parsedNum > max) {
            return `Must be between ${parseNumberAsString(min)} and ${parseNumberAsString(max)}`;
        }
    }
};
export const validateDecimal = (value, includeDecimal = false) => {
    const decimalPattern = /^\d+(\.\d{1,2})?$/; // Matches up to 2 decimal places
    if (value !== undefined) {
        const valueStr = typeof value === 'number' ? value.toString() : value;
        if (includeDecimal) {
            // Check if value has more than 2 decimal places
            if (!decimalPattern.test(valueStr)) {
                return 'Must only be two digits after the decimal';
            }
        }
        else {
            // Check if value contains any decimals
            if (valueStr.includes('.')) {
                return 'Decimals are not allowed';
            }
        }
    }
};
