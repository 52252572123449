var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Heading, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, } from '@chakra-ui/react';
import '@fontsource/montserrat';
import { Step, Steps, useSteps } from 'chakra-ui-steps';
import { slgqColorSchemes, variants, } from '@frontend/design-system/theme/theme';
export const FormModal = ({ title, pages, isOpen, onClose, onSubmit, handleSubmit, errors, onCancel, watch, submitDisabled, stepOverride, }) => {
    const { nextStep, prevStep, reset: resetStep, setStep, activeStep, } = useSteps({
        initialStep: 0,
    });
    const handleNextStep = () => {
        if ((stepOverride === null || stepOverride === void 0 ? void 0 : stepOverride.nextStep) && stepOverride.nextStep < pages.length) {
            const currStep = activeStep;
            setStep(stepOverride.nextStep);
            stepOverride.setNextStep(undefined);
            stepOverride.setPrevStep(currStep);
        }
        else {
            nextStep();
        }
    };
    const handlePrevStep = () => {
        var _a;
        const prevStepOverride = (_a = stepOverride === null || stepOverride === void 0 ? void 0 : stepOverride.prevStep) !== null && _a !== void 0 ? _a : -1;
        if (stepOverride && prevStepOverride >= 0) {
            const currStep = activeStep;
            setStep(prevStepOverride);
            stepOverride.setPrevStep(undefined);
            stepOverride.setNextStep(currStep);
        }
        else {
            prevStep();
        }
    };
    const renderStepBtns = () => {
        return (_jsxs(HStack, Object.assign({ spacing: 5, justifyContent: 'flex-end' }, { children: [activeStep > 0 && (_jsx(Button, Object.assign({ borderRadius: '40px', variant: variants.cpBlueBtn, onClick: handlePrevStep }, { children: "Back" }))), activeStep < pages.length - 1 && (_jsx(Button, Object.assign({ borderRadius: '40px', colorScheme: errors ? slgqColorSchemes.secDarkGray : slgqColorSchemes.primBlue, variant: variants.cpBlueBtn, onClick: handleNextStep, isDisabled: errors }, { children: "Next" }))), activeStep === pages.length - 1 && (_jsx(Button, Object.assign({ type: 'submit', borderRadius: '40px', variant: variants.cpBlueBtn, colorScheme: errors || submitDisabled === true
                        ? slgqColorSchemes.secDarkGray
                        : slgqColorSchemes.primBlue, isDisabled: errors || submitDisabled === true }, { children: "Finish" })))] })));
    };
    const onFormSubmit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        yield onSubmit(data).then(() => {
            resetStep();
        });
    });
    return (_jsxs(Modal, Object.assign({ isOpen: isOpen, onClose: () => {
            onClose();
            resetStep();
        } }, { children: [_jsx(ModalOverlay, {}), _jsx("form", Object.assign({ onSubmit: handleSubmit(onFormSubmit) }, { children: _jsxs(ModalContent, Object.assign({ maxW: '475px', maxH: '850px' }, { children: [_jsxs(ModalHeader, { children: [_jsx(Heading, Object.assign({ as: 'h1' }, { children: title })), watch && activeStep > 0 && (_jsx(Text, Object.assign({ fontSize: 18, fontWeight: 600, my: 1 }, { children: watch('name') })))] }), _jsx(ModalCloseButton, { "aria-label": 'close' }), _jsx(ModalBody, { children: _jsx(Steps, Object.assign({ orientation: 'horizontal', responsive: false, size: 'sm', colorScheme: slgqColorSchemes.primBlue, activeStep: activeStep, marginBottom: 5 }, { children: pages === null || pages === void 0 ? void 0 : pages.map((element, index) => (_jsxs(Step, { children: [_jsx("p", Object.assign({ className: 'required-label' }, { children: '(*) Required fields' })), element] }, index))) })) }), _jsx(ModalFooter, { children: _jsxs(HStack, Object.assign({ width: '100%', justify: 'space-between' }, { children: [_jsx(Button, Object.assign({ variant: variants.cpBlueTransparentBtn, borderRadius: '40px', colorScheme: 'blue', onClick: () => {
                                            onCancel();
                                            onClose();
                                            resetStep();
                                        } }, { children: "Cancel" })), renderStepBtns()] })) })] })) }))] })));
};
