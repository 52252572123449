import { createGetHandler } from '../testing/msw/handlerUtils';
import { ProjectDetailResponseBuilder } from '../testing/platformProjectMocks';
const projectEndpoints = {
    load: '/api/v1/Project/load/:projectId',
    configure: '/api/v1/Project/configure',
};
const projectLoadHandler = createGetHandler(projectEndpoints.load, new ProjectDetailResponseBuilder({}).build());
const projectHandlers = [projectLoadHandler];
export const projectHandler = {
    endpoints: projectEndpoints,
    handlers: projectHandlers,
};
