import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Grid, GridItem, ListItem, Text, Tooltip, UnorderedList, VStack, } from '@chakra-ui/react';
import { ProjectRelationshipType, } from '@frontend/domain/models/Projects/ProjectsInterfaces';
import { useFieldArray } from 'react-hook-form';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { appColors } from '../../../../config/constants';
import { AssociatedProjectRow } from './AssociatedProjectRow';
import { AssociatedProjectSearch } from './AssociatedProjectSearch';
export const associatedProjectGridStyle = {
    templateColumns: '40% 1fr 1fr',
    columnGap: 5,
    templateRows: '45px',
    alignItems: 'center',
    paddingLeft: 5,
    borderBottom: `1px solid ${appColors.PRIM_LIGHT_GREY}`,
};
export const AssociatedProjects = ({ project, isEditing, allProjects, projectUseForm, }) => {
    var _a, _b;
    const { fields, append, remove, update } = useFieldArray({
        control: projectUseForm.control,
        name: 'relatedProjects',
    });
    const showAssociatedProjectInstruction = fields.length < 1 && ((_a = project.requiredByProjects) === null || _a === void 0 ? void 0 : _a.length) < 1;
    const hasMaxFields = fields.length >= 10;
    return (_jsxs(VStack, Object.assign({ w: '100%', align: 'left', spacing: 5 }, { children: [_jsx(Text, Object.assign({ color: 'brand.primBlue', fontWeight: 'semibold' }, { children: "Associated Projects" })), isEditing && (_jsx(AssociatedProjectSearch, { currentProject: project, allProjects: allProjects, associatedProjects: fields, requiredByProjects: project.requiredByProjects, onSearchSelect: (item) => {
                    if (item) {
                        append({
                            identity: item.identity,
                            projectName: item.name,
                            relationshipType: ProjectRelationshipType.REQUIRES,
                        });
                    }
                }, isDisabled: hasMaxFields })), _jsxs(Box, Object.assign({ maxW: '100%', border: `1px solid ${appColors.PRIM_LIGHT_GREY}` }, { children: [_jsx(AssociatedProjectsHeader, { gridStyle: associatedProjectGridStyle }), showAssociatedProjectInstruction && (_jsx(NoAssociatedProjectsInstruction, { isEditing: isEditing, gridTemplateColumns: associatedProjectGridStyle.templateColumns })), _jsxs(Box, Object.assign({ w: '100%', maxHeight: isEditing ? '275px' : '320px', overflowY: 'auto', overflowX: 'hidden' }, { children: [fields.map((associatedProject, index) => (_jsx(AssociatedProjectRow, { associatedProject: associatedProject, isEditing: isEditing, removeItem: () => remove(index), updateItem: (value) => update(index, Object.assign(Object.assign({}, associatedProject), { relationshipType: value })), gridStyle: Object.assign(Object.assign({}, associatedProjectGridStyle), { templateRows: '70px', borderBottom: index === fields.length - 1 && !project.requiredByProjects
                                        ? 'none'
                                        : associatedProjectGridStyle.borderBottom }) }, `associated-project-row-${index}`))), (_b = project.requiredByProjects) === null || _b === void 0 ? void 0 : _b.map((project, index) => (_jsx(AssociatedProjectRow, { associatedProject: project, isRequiredBy: true, isEditing: isEditing, removeItem: () => { }, updateItem: (value) => update(index, Object.assign(Object.assign({}, project), { relationshipType: value })), gridStyle: Object.assign(Object.assign({}, associatedProjectGridStyle), { templateRows: '70px', borderBottom: index === fields.length - 1
                                        ? 'none'
                                        : associatedProjectGridStyle.borderBottom }) }, `required-project-row-${index}`)))] }))] }))] })));
};
const AssociatedProjectsHeader = ({ gridStyle, }) => {
    return (_jsxs(Grid, Object.assign({}, gridStyle, { bg: 'secDarkGray.300', color: appColors.TEXT_SEC_DARK_GRAY, fontWeight: '400' }, { children: [_jsx(GridItem, Object.assign({ colSpan: 1 }, { children: _jsx(Text, Object.assign({ textAlign: 'left', fontSize: 'sm' }, { children: "Associated Projects" })) })), _jsx(GridItem, Object.assign({ colSpan: 2 }, { children: _jsxs(Text, Object.assign({ textAlign: 'left', fontSize: 'sm', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }, { children: ["Link Type", _jsx(Tooltip, Object.assign({ label: displayLinkTypes('white') }, { children: _jsx("span", { children: _jsx(AiOutlineInfoCircle, { style: { marginRight: '20px' }, fontSize: '1.5rem' }) }) }))] })) }))] })));
};
export const NoAssociatedProjectsEditingInstructions = 'Use the search bar to find the project and assign it a link type.';
export const NoAssociatedProjectsViewingInstructions = 'To link projects together, edit the project.';
const displayLinkTypes = (textColor) => {
    return (_jsxs(Box, { children: [_jsx(Text, Object.assign({ fontWeight: '500', pb: '20px', color: textColor }, { children: "Project Link Types" })), _jsxs(UnorderedList, Object.assign({ spacing: 1, pl: 3 }, { children: [_jsx(InstructionListItem, { label: 'Requires', info: 'This project requires that the other project exist in the scenario. The other project can be done without this one.', color: textColor }), _jsx(InstructionListItem, { label: 'Interdependent', info: 'Both projects are required to exist in a scenario.', color: textColor }), _jsx(InstructionListItem, { label: 'Recommended', info: 'If one project exists, the other is also recommended to exist in the scenario.', color: textColor }), _jsx(InstructionListItem, { label: 'Alternate', info: 'Mutually exclusive projects that should not exist in the same scenario.', color: textColor })] }))] }));
};
const NoAssociatedProjectsInstruction = ({ isEditing, gridTemplateColumns, }) => {
    return (_jsx(Grid, Object.assign({ templateColumns: gridTemplateColumns }, { children: _jsx(GridItem, Object.assign({ colSpan: 3 }, { children: _jsxs(VStack, Object.assign({ fontSize: '14px', align: 'flex-start', p: 5, pt: 2, spacing: 5 }, { children: [_jsx(Text, Object.assign({ color: appColors.SEC_DARK_GRAY, whiteSpace: 'normal', wordBreak: 'break-word' }, { children: isEditing
                            ? NoAssociatedProjectsEditingInstructions
                            : NoAssociatedProjectsViewingInstructions })), displayLinkTypes(appColors.SEC_DARK_GRAY)] })) })) })));
};
const InstructionListItem = ({ label, info, color, }) => {
    return (_jsxs(ListItem, Object.assign({ whiteSpace: 'normal', wordBreak: 'break-word' }, { children: [_jsxs(Text, Object.assign({ as: 'span', fontWeight: '500', size: 'sm', color: color }, { children: [label, ":", ' '] })), _jsx(Text, Object.assign({ as: 'span', color: color, fontWeight: '400' }, { children: info }))] })));
};
