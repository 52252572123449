export class AssetService {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Retrieves asset detail information based on the provided filter object.
     * @param requestBody Object used to filter to specific assets
     * @returns AssetDetailResponse OK
     * @throws ApiError
     */
    postApiV1AssetLoad(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/Asset/load',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Retrieves asset summary information based on the provided filter object.
     * @param requestBody Object used to filter to specific assets
     * @returns AssetSummaryResponse OK
     * @throws ApiError
     */
    postApiV1AssetList(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/Asset/list',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Creates or updates an asset assessment
     * @param requestBody Assets to assess for the given space
     * @returns AssetDetailResponse OK
     * @throws ApiError
     */
    postApiV1AssetAssess(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/Asset/assess',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Re-assesses an asset's condition
     * @param requestBody Asset re-assessment
     * @returns AssetDetailResponse OK
     * @throws ApiError
     */
    postApiV1AssetReassess(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/Asset/reassess',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Removes the given asset from the system.
     * @param assetId The ID of the asset to remove
     * @returns any OK
     * @throws ApiError
     */
    deleteApiV1AssetRemove(assetId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/Asset/remove/{assetId}',
            path: {
                assetId: assetId,
            },
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Copies an asset into the target space(s)
     * @param requestBody Copy settings
     * @returns AssetSummaryResponse OK
     * @throws ApiError
     */
    postApiV1AssetCopy(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/Asset/copy',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
}
