import { useState } from 'react';
import { useDependencies } from '../../contexts/Dependencies/DependenciesContext';
import { convertToIReportList } from '../../models/Report/ReportConversions';
import { useReportService } from '../../services/ReportService';
// TODO: Report Provider should be connected to this
// We might not need a provider - look into a refactor or reports in general
export const useReportList = () => {
    const { platformApi } = useDependencies();
    const { report } = useReportService({ platformApi });
    const [isLoadingList, setIsLoadingList] = useState(false);
    const [reports, setReports] = useState(undefined);
    const fetchList = (orgId) => {
        setIsLoadingList(true);
        report
            .fetchReportList(orgId)
            .then((response) => {
            setReports(convertToIReportList(response));
        })
            .finally(() => setIsLoadingList(false));
    };
    return {
        fetch: fetchList,
        isLoading: isLoadingList,
        reports,
    };
};
