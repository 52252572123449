import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useDependencies } from '@frontend/domain/contexts/Dependencies/DependenciesContext';
import { useFeatureFlags } from '@frontend/domain/contexts/FeatureFlags/FeatureFlagContext';
import { useEffect } from 'react';
import { AppLoading } from '../Loading/AppLoading';
export function RequireDependenciesReady({ accessTokenContext, children, }) {
    const { isReady } = useDependencies();
    const { isLoaded: areFeatureFlagsLoaded } = useFeatureFlags();
    const { token } = accessTokenContext();
    const { setToken } = useDependencies();
    useEffect(() => {
        if (token) {
            setToken(token);
        }
    }, [token]);
    return isReady && areFeatureFlagsLoaded ? _jsx(_Fragment, { children: children }) : _jsx(AppLoading, {});
}
