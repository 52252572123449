import { useState } from 'react';
import { instanceOfRangeValue } from '../Inputs/RangeInput';
export var FilterObjectFieldType;
(function (FilterObjectFieldType) {
    FilterObjectFieldType[FilterObjectFieldType["BOOLEAN"] = 0] = "BOOLEAN";
    FilterObjectFieldType[FilterObjectFieldType["STRING"] = 1] = "STRING";
    FilterObjectFieldType[FilterObjectFieldType["OPTION_ARRAY"] = 2] = "OPTION_ARRAY";
    FilterObjectFieldType[FilterObjectFieldType["NESTED_OPTION_ARRAY"] = 3] = "NESTED_OPTION_ARRAY";
    FilterObjectFieldType[FilterObjectFieldType["RANGE"] = 4] = "RANGE";
    FilterObjectFieldType[FilterObjectFieldType["NULL"] = 5] = "NULL";
})(FilterObjectFieldType || (FilterObjectFieldType = {}));
export const getFilterObjectFieldType = (value) => {
    if (typeof value === 'boolean') {
        return FilterObjectFieldType.BOOLEAN;
    }
    else if (typeof value === 'string') {
        return FilterObjectFieldType.STRING;
    }
    else if (value instanceof Array) {
        return FilterObjectFieldType.OPTION_ARRAY;
    }
    else if (value instanceof Map) {
        return FilterObjectFieldType.NESTED_OPTION_ARRAY;
    }
    else if (instanceOfRangeValue(value)) {
        return FilterObjectFieldType.RANGE;
    }
    else {
        return FilterObjectFieldType.NULL;
    }
};
export function useFilter({ itemsToFilter, filterMap, initialFilter, }) {
    const [originalItems, setOriginalItems] = useState(itemsToFilter);
    const [filteredItems, setFilteredItems] = useState(itemsToFilter);
    const [filter, setFilter] = useState(initialFilter);
    const resetFilterItems = (newItemsToFilter) => {
        setOriginalItems(newItemsToFilter);
        const newFilteredItems = filterList(newItemsToFilter, filter);
        setFilteredItems(newFilteredItems);
    };
    const filterList = (filterItems, filterables) => {
        const toApply = [];
        Object.entries(filterables).forEach(([key, value]) => {
            if (value) {
                const filter = filterMap.get(key);
                if (filter) {
                    toApply.push(filter);
                }
            }
        });
        let newFilteredItems = filterItems;
        toApply.forEach((filterFunction) => {
            newFilteredItems = newFilteredItems.filter(filterFunction(filterables));
        });
        return newFilteredItems;
    };
    const applyFilter = (filterables) => {
        setFilter(filterables);
        const newFilteredItems = filterList(originalItems, filterables);
        setFilteredItems(newFilteredItems);
    };
    const clearFilter = (toClear) => {
        const newFilter = Object.assign({}, filter);
        if (toClear) {
            newFilter[toClear] = null;
        }
        else {
            Object.keys(filter).forEach((key) => {
                newFilter[key] = null;
            });
        }
        applyFilter(newFilter);
    };
    // Should only be used for Option Filters
    const clearListFilter = (toClear, indexToClear) => {
        const newFilter = Object.assign({}, filter);
        // assumes the only time we have an index to clear is when clearing multi value filter
        const filterValue = [...newFilter[toClear]];
        filterValue.splice(indexToClear, 1);
        // set to null to clear filter if all items in array are cleared
        newFilter[toClear] = filterValue.length > 0 ? [...filterValue] : null;
        applyFilter(newFilter);
    };
    // Should only be used for NestedOptionFilters / NestedFilters
    const clearNestedListFilter = (toClear, subField, indexToClear) => {
        const newFilter = Object.assign({}, filter);
        // can make assumptions on type based on use of method
        const nestedFilter = newFilter[toClear];
        if (nestedFilter) {
            const filterValue = nestedFilter.get(subField);
            if (Array.isArray(filterValue) && indexToClear !== undefined) {
                filterValue.splice(indexToClear, 1);
                if (filterValue.length < 1) {
                    nestedFilter.delete(subField);
                }
                if (nestedFilter.size === 0) {
                    newFilter[toClear] = null;
                }
            }
            else {
                nestedFilter.delete(subField);
                if (!nestedFilter.size) {
                    newFilter[toClear] = null;
                }
            }
            applyFilter(newFilter);
        }
    };
    const determineHasFilter = (filter) => {
        return Object.values(filter).some((value) => value !== null);
    };
    return {
        filteredItems,
        filter,
        hasFilter: determineHasFilter(filter),
        initialFilter,
        applyFilter,
        clearFilter,
        clearListFilter,
        clearNestedListFilter,
        resetFilterItems,
    };
}
