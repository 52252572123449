import { jsx as _jsx } from "react/jsx-runtime";
import { MySiteIQAPI } from '@frontend/clients/mysiteiq/MySiteIQAPI';
import { PlatformAPI } from '@frontend/clients/platformApi/PlatformAPI';
import React, { useMemo } from 'react';
import { DependenciesContext } from './DependenciesContext';
export function DependenciesProvider({ children, mysiteApi, platformApi, }) {
    const [token, setToken] = React.useState('');
    const mysite = useMemo(() => new MySiteIQAPI({
        BASE: mysiteApi.baseUrl,
        TOKEN: token,
    }), [token, mysiteApi.baseUrl]);
    const platform = useMemo(() => new PlatformAPI({
        BASE: platformApi.baseUrl,
        TOKEN: token,
    }), [token, platformApi.baseUrl]);
    return (_jsx(DependenciesContext.Provider, Object.assign({ value: {
            mysiteIQApi: mysite,
            platformApi: platform,
            setToken,
            isReady: token !== '' && token !== '',
        } }, { children: children })));
}
