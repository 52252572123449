import { useEffect, useState } from 'react';
import { useDependencies } from '../../contexts/Dependencies/DependenciesContext';
import { sortObjectsByName } from '../../conversion/NameConversions';
import { useOrganizationService } from '../../services/OrganizationService';
export const useOrganizationList = ({ context = undefined }) => {
    const { platformApi } = useDependencies();
    const { organization } = useOrganizationService({ platformApi });
    const [isLoadingList, setIsLoadingList] = useState(false);
    const [organizations, setOrganizations] = useState([]);
    useEffect(() => {
        if (platformApi)
            fetchList();
    }, [platformApi]);
    const fetchList = () => {
        setIsLoadingList(true);
        organization
            .fetchList(context)
            .then((response) => {
            setOrganizations(sortObjectsByName(response) || []);
        })
            .finally(() => setIsLoadingList(false));
    };
    const onUpdateOrganization = (org, orgIds) => {
        setOrganizations((prevOrganizations) => {
            const orgIndex = prevOrganizations.findIndex((o) => o.identity === orgIds.identity);
            if (orgIndex !== -1) {
                const updatedOrganizations = [...prevOrganizations];
                updatedOrganizations[orgIndex] = Object.assign(Object.assign({}, updatedOrganizations[orgIndex]), { name: org.nameOfOrg, businessUnit: org.businessUnit, state: org.address.state, userCount: updatedOrganizations[orgIndex].userCount, apps: updatedOrganizations[orgIndex].apps });
                return updatedOrganizations;
            }
            else {
                return [
                    ...prevOrganizations,
                    {
                        id: orgIds.id,
                        identity: orgIds.identity,
                        name: org.nameOfOrg,
                        businessUnit: org.businessUnit,
                        state: org.address.state,
                        userCount: 0,
                        apps: [],
                    },
                ];
            }
        });
    };
    return {
        fetch: fetchList,
        isLoading: isLoadingList,
        organizations,
        onUpdateOrganization,
    };
};
