import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TriangleDownIcon } from '@chakra-ui/icons';
import { Box, Tooltip } from '@chakra-ui/react';
import { Table } from '@frontend/design-system/components/Table/Table';
import { createColumnHelper } from '@tanstack/react-table';
import { ConditionCircle } from '../../components/dataDisplay/ConditionCircle';
import { LifeRemainingCircle } from '../../components/dataDisplay/LifeRemainingCircle';
import { usePlanAssetSummaryFilter } from '../../contexts/Assets/PlanAssetFilterHook';
import { AssetsTableHeader } from './AssetsTableHeader';
import { PopoverProjectsCard } from './PopoverProjectCard';
const columnHelper = createColumnHelper();
export const AssetsTable = ({ planAssetDetail, }) => {
    const planAssetFilterContext = usePlanAssetSummaryFilter(planAssetDetail.assets);
    const mapToAssetTable = (asset) => {
        return {
            assetIdentity: asset.assetIdentity,
            name: asset.name,
            assetType: asset.assetType,
            facilityIdentity: asset.facilityIdentity,
            facilityName: asset.facilityName,
            category: asset.assetCategory,
            condition: asset.currentCondition,
            projectedLifeRemaining: asset.projectedLifeRemaining,
            spaceIdentity: asset.spaceIdentity,
            spaceName: asset.spaceName,
            projects: asset.projects,
            quantity: asset.quantity > 1 ? asset.quantity : undefined,
        };
    };
    const customSort = (rowA, rowB, columnId) => {
        const a = rowA.getValue(columnId);
        const b = rowB.getValue(columnId);
        // Places values with no name last alphabetically
        if (!a)
            return 1;
        if (!b)
            return -1;
        // Sort numbers before letters
        return a.localeCompare(b, undefined, {
            numeric: true,
            sensitivity: 'base',
        });
    };
    const columns = [
        columnHelper.accessor('name', {
            cell: (info) => {
                const { name } = info.row.original;
                const { quantity } = info.row.original;
                return (_jsx(Tooltip, Object.assign({ label: name + (quantity ? ' (' + quantity + ')' : ''), openDelay: 750 }, { children: _jsx(Box, Object.assign({ display: '-webkit-box', "data-testid": 'name', w: '10em', whiteSpace: 'pre-line', overflow: 'hidden', textOverflow: 'ellipsis', style: {
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                        } }, { children: name + (quantity ? ' (' + quantity + ')' : '') })) })));
            },
            header: 'Assets (QTY)',
            sortingFn: customSort,
        }),
        columnHelper.accessor('assetType', {
            cell: (info) => {
                const { assetType } = info.row.original;
                return (_jsx(Tooltip, Object.assign({ label: assetType, openDelay: 750 }, { children: _jsx(Box, Object.assign({ display: '-webkit-box', "data-testid": 'assetType', w: '10em', whiteSpace: 'pre-line', overflow: 'hidden', textOverflow: 'ellipsis', style: {
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                        } }, { children: assetType })) })));
            },
            header: 'Asset Type',
        }),
        columnHelper.accessor('facilityName', {
            cell: (info) => {
                const { facilityName } = info.row.original;
                return (_jsx(Tooltip, Object.assign({ label: facilityName, openDelay: 750 }, { children: _jsx(Box, Object.assign({ display: '-webkit-box', "data-testid": 'facilityName', w: '10em', whiteSpace: 'pre-line', overflow: 'hidden', textOverflow: 'ellipsis', style: {
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                        } }, { children: facilityName })) })));
            },
            header: 'Facility',
        }),
        columnHelper.accessor('category', {
            cell: (info) => {
                const { category } = info.row.original;
                return (_jsx(Tooltip, Object.assign({ label: category, openDelay: 750 }, { children: _jsx(Box, Object.assign({ display: '-webkit-box', "data-testid": 'category', w: '10em', whiteSpace: 'pre-line', overflow: 'hidden', textOverflow: 'ellipsis', style: {
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                        } }, { children: category })) })));
            },
            header: 'Category',
        }),
        columnHelper.accessor('condition', {
            cell: (info) => {
                const { condition } = info.row.original;
                return _jsx(ConditionCircle, { condition: condition });
            },
            header: 'Condition',
        }),
        columnHelper.accessor('projectedLifeRemaining', {
            cell: (info) => {
                const { projectedLifeRemaining } = info.row.original;
                return _jsx(LifeRemainingCircle, { years: projectedLifeRemaining });
            },
            header: 'Projected Life Remaining',
        }),
        columnHelper.accessor('spaceName', {
            cell: (info) => {
                const { spaceName } = info.row.original;
                return (_jsx(Tooltip, Object.assign({ label: spaceName, openDelay: 750 }, { children: _jsx(Box, Object.assign({ display: '-webkit-box', "data-testid": 'spaceName', w: '10em', whiteSpace: 'pre-line', overflow: 'hidden', textOverflow: 'ellipsis', style: {
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                        } }, { children: spaceName })) })));
            },
            header: 'Space',
        }),
        columnHelper.accessor('projects', {
            cell: (info) => {
                const { projects } = info.row.original;
                const { facilityName } = info.row.original;
                return (_jsx(Box, Object.assign({ as: 'button', "data-testid": 'projects', style: {
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: 'vertical',
                    } }, { children: _jsx(PopoverProjectsCard, Object.assign({ projects: projects, facility: facilityName, emptyText: 'No projects found' }, { children: _jsxs(Box, { children: ["Projects (", projects.length, ") ", _jsx(TriangleDownIcon, {})] }) })) })));
            },
            header: 'projects',
        }),
    ];
    const tableData = planAssetFilterContext.filteredItems.map((asset) => {
        const assetTable = mapToAssetTable(asset);
        const bgColor = '';
        return Object.assign(Object.assign({}, assetTable), { style: {
                backgroundColor: bgColor,
                paddingTop: '0px',
                paddingBottom: '0px',
                paddingRight: '5px',
            } });
    });
    return (_jsx(Box, { children: _jsx(Table, { maxHeight: '85vh', data: tableData, columns: columns, header: _jsx(AssetsTableHeader, { planAssetDetail: planAssetDetail, planAssetFilterContext: planAssetFilterContext, loading: false }), 
            // TODO: the styling of this tables margins don't match the other pages; should at least match what is happening with projects
            paddingX: '20px', initialSort: [
                { id: 'category', desc: false },
                { id: 'assetType', desc: false },
                { id: 'name', desc: false },
            ] }) }));
};
