import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Box, Button, Flex, Heading, List, ListItem, Text, } from '@chakra-ui/react';
import FileUpload from '@frontend/design-system/components/FileUpload/FileUpload';
import { validateFileUpload, } from '@frontend/domain/formValidations/FileValidation';
import React from 'react';
import { BiSolidTrashAlt } from 'react-icons/bi';
import { MdUpload } from 'react-icons/md';
import { appColors } from '../../../config/constants';
import { slgqColorSchemes } from '@frontend/design-system/theme/theme';
export const AttachmentsTab = ({ projectFiles, editMode, register, errors, setError, clearErrors, addFilesToProject, removeFileFromProject, newlyAddedFiles, }) => {
    var _a;
    const validFileExtensions = '.pdf, .png, .jpeg, .jpg, .tif, .tiff, .docx, .pptx, .xlsx, .txt, .mp4, .mpg';
    const validFileSize = 50 * 1024 * 1024; // 50 MB
    const validFileNameSize = 255;
    const fileRegister = register('files', {
        onChange: (e) => {
            const files = e.target.files ? Array.from(e.target.files) : [];
            const validatedFiles = validateFileUpload(files, validFileExtensions, validFileSize, validFileNameSize, projectFiles, newlyAddedFiles);
            if (validatedFiles.errorMessage) {
                setError('files', {
                    message: validatedFiles.errorMessage,
                });
            }
            else {
                clearErrors('files');
            }
            if (validatedFiles.validFiles.length > 0) {
                addFilesToProject(validatedFiles.validFiles);
            }
        },
    });
    const openAttachment = (url) => {
        const a = document.createElement('a');
        if (url) {
            a.href = url;
        }
        const pdfWindow = window.open();
        if (pdfWindow) {
            pdfWindow.location.href = a.href;
        }
    };
    return (_jsxs(Flex, Object.assign({ p: '24px', direction: 'column', gap: '20px' }, { children: [_jsxs(Flex, Object.assign({ justifyContent: 'space-between' }, { children: [_jsxs(Heading, Object.assign({ as: 'h3', variant: 'h3', color: appColors.PRIM_BLUE }, { children: ["Attached files (", (_a = projectFiles.length) !== null && _a !== void 0 ? _a : 0, ")"] })), editMode && (_jsx(Box, { children: _jsx(FileUpload, Object.assign({ accept: validFileExtensions, register: fileRegister, multiple: true }, { children: _jsx(Button, Object.assign({ "aria-label": 'file-upload-button', leftIcon: _jsx(MdUpload, {}), variant: 'solid', borderRadius: '40px', colorScheme: slgqColorSchemes.primBlue }, { children: "Upload" })) })) }))] })), errors.files && errors.files.message && (_jsx(Text, Object.assign({ color: 'red', whiteSpace: 'pre-wrap' }, { children: errors.files.message }))), _jsx(List, Object.assign({ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: '10px 45px', alignItems: 'center' }, { children: projectFiles.map((file, i) => (_jsxs(ListItem, Object.assign({ display: 'flex', flexDirection: 'row', alignItems: 'center' }, { children: [_jsx(Text, Object.assign({ cursor: 'pointer', onClick: () => openAttachment(file.url) }, { children: file.name })), editMode && file.name && (_jsx(Button, Object.assign({ colorScheme: 'none', background: 'transparent', color: slgqColorSchemes.primBlue, height: 'fit-content', onClick: () => removeFileFromProject(file) }, { children: _jsx(BiSolidTrashAlt, {}) })))] }), `${i}-${file.name}`))) }))] })));
};
