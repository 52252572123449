import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, FormControl, HStack, Select } from '@chakra-ui/react';
import { appColors } from '@frontend/design-system/theme/constants';
import { useState } from 'react';
import { ArrowRightDisabledIcon, ArrowRightIcon } from '../../../config/icons';
const FACILITY_KEY = 'facilityIdentity';
export const FacilitySelector = ({ sites, form, }) => {
    const [site, setSite] = useState();
    const facilityRegister = form.register(FACILITY_KEY, {
        required: true,
    });
    const onChangeSite = (e) => {
        const siteId = e.target.value;
        setSite(sites.find((site) => site.identity.toString() === siteId));
        form.resetField(FACILITY_KEY);
    };
    const currentFacilities = (site === null || site === void 0 ? void 0 : site.facilities) || [];
    return (_jsxs(HStack, Object.assign({ alignContent: 'center', spacing: '15px' }, { children: [_jsx("span", Object.assign({ className: 'required' }, { children: "*" })), _jsx(Select, Object.assign({ placeholder: 'Select a site', 
                // unstyled was resulting in conflicts with the default Select styles
                variant: 'cp-unstyled', color: appColors.PRIM_BLUE, minW: '8em', w: 'fit-content', "data-testid": 'site-select', value: (site === null || site === void 0 ? void 0 : site.identity) || '', onChange: onChangeSite }, { children: sites.map((site, i) => (_jsx("option", Object.assign({ value: site.identity }, { children: site.name }), `${site.name} ${i}`))) })), _jsx(Box, Object.assign({ pr: '12px' }, { children: !site ? _jsx(ArrowRightDisabledIcon, {}) : _jsx(ArrowRightIcon, {}) })), _jsx("span", Object.assign({ className: 'required' }, { children: "*" })), _jsx(FormControl, { children: _jsx(Select, Object.assign({ placeholder: 'Select a facility', 
                    // unstyled was resulting in conflicts with the default Select styles
                    variant: 'cp-unstyled', isDisabled: !site, color: appColors.PRIM_BLUE, w: 'fit-content', defaultValue: '', "data-testid": 'facility-select', pr: '4' }, facilityRegister, { children: currentFacilities.map((facility, i) => (_jsx("option", Object.assign({ value: facility.identity }, { children: facility.name }), `${facility.name} ${i}`))) })) })] })));
};
