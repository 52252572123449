import { useLocalStorage } from 'usehooks-ts';
const SESSION_ORG_KEY = 'oid';
export const organizationSessionStore = () => {
    const [value, setValue, removeValue] = useLocalStorage(SESSION_ORG_KEY, undefined);
    const getSessionOrgIdentity = () => {
        const sessionOrgIdentity = value;
        return sessionOrgIdentity;
    };
    const setSessionOrgIdentity = (orgIdentity) => {
        if (orgIdentity === undefined) {
            removeValue();
        }
        else {
            setValue(orgIdentity);
        }
    };
    return { getSessionOrgIdentity, setSessionOrgIdentity };
};
