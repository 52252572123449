var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { PlanGoalEnum, } from '@frontend/domain/models/Scenario/ScenarioInterfaces';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { FormModal, } from '../../components/overlay/FormModal';
import { convertIScenarioDetailsToScenarioUpdateForm } from '../../contexts/Scenarios/ScenarioConversions';
import { BudgetPage, PLAN_FUNDING_TYPE_BUDGET_KEY, } from './modalPages/BudgetPage';
import { ImportantFactorsPage } from './modalPages/ImportantFactorsPage';
import { PlanningByPage } from './modalPages/PlanningByPage';
import { ScenarioDetailsPage } from './modalPages/ScenarioDetailsPage';
export const useFormSectionErrorContext = (fieldsToWatch, formContext, onHasErrorChange) => {
    const { formState: { errors }, } = formContext;
    const [hasErrors, setHasErrors] = useState(true);
    const isErrorPresent = (fields) => {
        for (const field of fields) {
            if (errors[field] &&
                (errors[field].type === 'required' ||
                    (errors[field].type === 'validate' && errors[field].message !== ''))) {
                return true;
            }
        }
        return false;
    };
    useEffect(() => {
        const errorPresent = isErrorPresent(fieldsToWatch);
        setHasErrors(errorPresent);
        onHasErrorChange(errorPresent);
    });
    // trigger initial error
    useEffect(() => {
        formContext.trigger();
    }, []);
    return { hasErrors };
};
export const ScenarioModal = ({ disclosure, fundingTypes, isEditModal, scenario, upsertScenario, scenarioList, }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [nextStepOverride, setNextStepOverride] = useState();
    const [prevStepOverride, setPrevStepOverride] = useState();
    const [canContinueOverride, setCanContinueOverride] = useState(false);
    const stepOverride = {
        nextStep: nextStepOverride,
        setNextStep: setNextStepOverride,
        prevStep: prevStepOverride,
        setPrevStep: setPrevStepOverride,
        canContinue: canContinueOverride,
        setCanContinue: setCanContinueOverride,
    };
    const scenarioForm = useForm({
        values: convertIScenarioDetailsToScenarioUpdateForm(scenario, fundingTypes),
        mode: 'onChange',
    });
    const fundingTypeBudgetFieldContext = useFieldArray({
        name: PLAN_FUNDING_TYPE_BUDGET_KEY,
        control: scenarioForm.control,
    });
    const { handleSubmit, reset } = scenarioForm;
    useEffect(() => {
        resetForm();
    }, [scenario]);
    useEffect(() => {
        // set initial step override
        if ((scenario === null || scenario === void 0 ? void 0 : scenario.planningNeed) === PlanGoalEnum.NEEDS) {
            setNextStepOverride(3);
        }
        else {
            setNextStepOverride(undefined);
        }
    }, [scenario === null || scenario === void 0 ? void 0 : scenario.planningNeed]);
    const onModalClose = () => {
        disclosure.onClose();
        resetForm();
    };
    const resetForm = () => {
        reset(convertIScenarioDetailsToScenarioUpdateForm(scenario, fundingTypes));
    };
    const onSubmit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        setIsSubmitting(true);
        yield upsertScenario(data)
            .then(() => {
            onModalClose();
        })
            .catch(() => { }) //needs empty function to properly catch error
            .finally(() => setIsSubmitting(false));
    });
    const existingScenarioNames = () => {
        return scenarioList
            .filter((existingScenario) => !isEditModal || (scenario === null || scenario === void 0 ? void 0 : scenario.name) !== existingScenario.scenarioName)
            .map((scenario) => scenario.scenarioName);
    };
    return (_jsx(FormModal, { title: isEditModal ? `Edit ${scenario === null || scenario === void 0 ? void 0 : scenario.name}` : 'Create New Scenario', isOpen: disclosure.isOpen, onClose: onModalClose, onSubmit: onSubmit, handleSubmit: handleSubmit, errors: !stepOverride.canContinue, onCancel: onModalClose, pages: [
            _jsx(ScenarioDetailsPage, { formContext: scenarioForm, stepOverride: stepOverride, existingScenarioNames: existingScenarioNames(), fundingTypes: fundingTypes, fundingTypeFieldContext: fundingTypeBudgetFieldContext, isEditModal: isEditModal }, 'page1'),
            _jsx(PlanningByPage, { formContext: scenarioForm, stepOverride: stepOverride, fundingTypes: fundingTypes }, 'page2'),
            _jsx(BudgetPage, { formContext: scenarioForm, stepOverride: stepOverride, fundingTypeFieldContext: fundingTypeBudgetFieldContext }, 'page3'),
            _jsx(ImportantFactorsPage, { formContext: scenarioForm, stepOverride: stepOverride }, 'page4'),
        ], submitDisabled: isSubmitting, stepOverride: stepOverride }));
};
