import { jsx as _jsx } from "react/jsx-runtime";
import { PlanningFactors } from '@frontend/domain/models/Scenario/ScenarioInterfaces';
import React from 'react';
import { Controller } from 'react-hook-form';
import { RadioGroupInput } from '../../../components/forms/RadioGroup';
import { useFormSectionErrorContext, } from '../ScenarioModal';
const PLAN_FACTORS_KEY = 'planningFactor';
export const ImportantFactorsPage = ({ formContext, stepOverride, }) => {
    // not currently needing to return error context
    useFormSectionErrorContext([PLAN_FACTORS_KEY], formContext, (hasError) => stepOverride.setCanContinue(!hasError));
    const radios = [
        {
            label: 'Funding Type',
            value: PlanningFactors.FUNDING_TYPE,
        },
        {
            label: 'Facility',
            value: PlanningFactors.FACILITY,
        },
    ];
    return (_jsx(Controller, { name: PLAN_FACTORS_KEY, control: formContext.control, render: (controller) => (_jsx(RadioGroupInput, { label: 'What factor is most important for you when deciding which projects to include in a scenario? - Select one:', radios: radios, controller: controller, required: true })), rules: { required: true } }));
};
