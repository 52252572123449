import { rest } from 'msw';
export const createGetHandler = (pathMatcher, responseObject) => {
    return rest.get(`*${pathMatcher}*`, (req, res, ctx) => {
        return res(ctx.json(responseObject));
    });
};
export const createPostHandler = (pathMatcher, responseObject) => {
    return rest.post(`*${pathMatcher}*`, (req, res, ctx) => {
        return res(ctx.json(responseObject));
    });
};
/** Override a defined endpoint with specific implementation
 * For use during testing only
 */
export const getHandlerOverride = (server, path, responseObject) => {
    server.use(createGetHandler(path, responseObject));
};
/** Override a defined endpoint with specific implementation
 * For use during testing only
 */
export const postHandlerOverride = (server, path, responseObject) => {
    server.use(createPostHandler(path, responseObject));
};
