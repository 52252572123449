export class PhotoService {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Requests generation of a ZIP file containing the selected photos
     * If the request results in more than forty-two (42) photos being requested at the same time, the user will
     * receive an email indicating the call has failed.
     *
     * The request must specify an organization ID, but the photo IDs are optional. If no photo IDs are specified,
     * the resulting file will contain all the photos from the organization, subject to the download limit.
     * @param requestBody The request specifying the organization and individual photos to download
     * @returns any Accepted
     * @throws ApiError
     */
    postApiV1PhotoDownload(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/Photo/download',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
