import { jsxs as _jsxs } from "react/jsx-runtime";
import { Text } from '@chakra-ui/react';
import { appColors } from '@frontend/design-system/theme/constants';
export const EstimatedBudget = ({ escalatedBudget, estimatedBudget, isEscalated, scenarioIsLocked, }) => {
    const getBudgetValue = () => {
        if (isEscalated) {
            if (escalatedBudget) {
                return `$${new Intl.NumberFormat().format(escalatedBudget)}`;
            }
            if (escalatedBudget === 0) {
                return '$0';
            }
            return '';
        }
        else {
            if (estimatedBudget) {
                return `$${new Intl.NumberFormat().format(estimatedBudget)}`;
            }
            if (estimatedBudget === 0) {
                return '$0';
            }
            return '';
        }
    };
    return (_jsxs(Text, Object.assign({ pb: '5px', "data-testid": 'estimated-budget', fontSize: '12px', color: isEscalated && escalatedBudget !== estimatedBudget
            ? 'teal'
            : scenarioIsLocked
                ? appColors.SEC_DARK_GRAY
                : undefined }, { children: ["EB: ", getBudgetValue()] })));
};
