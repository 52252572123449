import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Line } from 'react-konva';
export const SimpleShape = ({ shape, style, isSelected, onSelect, }) => {
    const { stroke, fill, pattern } = style;
    const getPointsArrayFromShape = (shape) => {
        const points = [];
        shape.forEach((point) => points.push(point.x, point.y));
        return points;
    };
    return (_jsxs(_Fragment, { children: [_jsx(Line, { points: getPointsArrayFromShape(shape), stroke: isSelected ? stroke.selected.color : stroke.unselected.color, strokeWidth: isSelected ? stroke.selected.width : stroke.unselected.width, dash: isSelected ? [] : [5, 2], closed: true, fill: isSelected ? fill.selected : fill.unselected, onMouseDown: onSelect, onTouchEnd: onSelect }), _jsx(Line, { points: getPointsArrayFromShape(shape), stroke: isSelected ? stroke.selected.color : stroke.unselected.color, strokeWidth: isSelected ? stroke.selected.width : stroke.unselected.width, dash: isSelected ? [] : [5, 2], closed: true, fillPatternImage: pattern, onMouseDown: onSelect, onTouchEnd: onSelect })] }));
};
