/** Converts list of flat objects to form data array object for platformAPI conversion
 * PlatformAPI expects flat object structure with index qualifiers for list fields
 * (ex. User[0].firstName, User[0].lastName, User[1].firstName, User[1].lastName)
 */
export const convertSimpleObjectListToFormDataArray = (fieldName, list) => {
    const data = {};
    list.forEach((item, index) => {
        Object.keys(item).forEach((itemKey) => {
            const field = `${fieldName}[${index}].${itemKey}`;
            data[field] = item[itemKey];
        });
    });
    return data;
};
