import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Shape } from 'react-konva';
import { appColors } from '../../../theme/constants';
export const SimplePin = ({ location, isSelected, scale, color = appColors.KONVA_RED, }) => {
    const xScale = scale ? 20 / scale : 20;
    const currentXscale = scale ? 30 / scale : 30;
    const yScale = scale ? 30 / scale : 30;
    const currentYscale = scale ? 40 / scale : 40;
    const xModifier = isSelected ? currentXscale : xScale;
    const yModifier = isSelected ? currentYscale : yScale;
    return (_jsx(Shape, { sceneFunc: (context, shape) => {
            context.beginPath();
            context.moveTo(location.x, location.y);
            // context.quadraticCurveTo(50, 0, 100, 0);
            context.quadraticCurveTo(location.x - xModifier, location.y - yModifier, location.x, location.y - yModifier);
            context.quadraticCurveTo(location.x + xModifier, location.y - yModifier, location.x, location.y);
            context.closePath();
            // (!) Konva specific method, it is very important
            context.fillStrokeShape(shape);
        }, fill: color, stroke: appColors.KONVA_BLACK, strokeWidth: 1 }));
};
