import { parseCurrencyAsFloat } from '@frontend/domain/conversion/NumberConversions';
import { BudgetTypeEnum, PlanGoalEnum, } from '@frontend/domain/models/Scenario/ScenarioInterfaces';
import { convertStringToNumber } from '../../config/CPHelperFunctions';
import { defaultScenarioUpdateForm, } from '../../objects/CPHelperInterfaces';
const cleanBudgetDataForPlatform = (data) => {
    var _a;
    if (data.planningNeed === PlanGoalEnum.NEEDS) {
        // if planning need is NEEDS budget type has to overridden since it does not show as option in modal
        data.budgetType = BudgetTypeEnum.NEEDS;
    }
    // platform only accepts totalBudget field if budget type is TotalBudget
    data.totalBudget =
        data.budgetType === BudgetTypeEnum.TOTAL_BUDGET && data.totalBudget
            ? // TODO: Needed until form is refactored for create/edit since total budget does not get parsed on change
                data.totalBudget
            : '';
    switch (data.budgetType) {
        // platform does not accept fundingType budgets if budget type is needs or total budget
        case BudgetTypeEnum.NEEDS:
        case BudgetTypeEnum.TOTAL_BUDGET: {
            data.fundingTypeBudgets = undefined;
            break;
        }
        // platform does not accept budget year if budget type is fundingTypeBudget
        case BudgetTypeEnum.FUNDING_TYPE: {
            data.fundingTypeBudgets = (_a = data.fundingTypeBudgets) === null || _a === void 0 ? void 0 : _a.map((budgets) => (Object.assign(Object.assign({}, budgets), { year: undefined })));
            break;
        }
    }
};
export const convertScenarioFormToIUpdateScenario = (data, orgIdentity) => {
    cleanBudgetDataForPlatform(data);
    // Platform has data constraints on what type of data to send depending on budget type
    const updateScenario = {
        scenarioId: data.identity,
        organizationId: orgIdentity,
        scenarioName: data.name,
        scenarioDescription: data.description,
        planDuration: parseInt(data.planDurationYrs),
        startYear: parseInt(data.startYear),
        planningNeed: data.planningNeed,
        budgetType: data.budgetType,
        planningFactors: data.planningFactor,
        budgetByFundingType: data.fundingTypeBudgets
            ? convertFundingTypeBudgetFormToIUpdateScenarioFundingTypeBudget(data.fundingTypeBudgets)
            : undefined,
        lumpSumBudget: data.totalBudget
            ? parseCurrencyAsFloat(data.totalBudget)
            : undefined,
        useEscalationFactor: data.useEscalationFactor,
    };
    return updateScenario;
};
export const convertFundingTypeBudgetFormToIUpdateScenarioFundingTypeBudget = (fundingTypeBudget) => {
    const budgetsToReturn = [];
    fundingTypeBudget.forEach((budget) => {
        if (budget.budget) {
            const newBudget = {
                fundingTypeId: budget.identity,
                budget: parseCurrencyAsFloat(budget.budget),
                year: budget.year ? parseInt(budget.year) : undefined,
            };
            budgetsToReturn.push(newBudget);
        }
    });
    return budgetsToReturn.length > 0 ? budgetsToReturn : undefined;
};
export const convertIScenarioDetailsToScenarioUpdateForm = (scenarioDetails, fundingTypes) => {
    var _a, _b;
    if (!scenarioDetails) {
        return defaultScenarioUpdateForm;
    }
    let fundingTypeBudgets = [];
    if (scenarioDetails.budgetType === BudgetTypeEnum.FUNDING_TYPE) {
        fundingTypeBudgets =
            convertIScenarioFundingTypeBudgetToFundingTypeBudgetForm(scenarioDetails.budgetsByFundingType, fundingTypes);
    }
    else if (scenarioDetails.budgetType === BudgetTypeEnum.FUNDING_TYPE_PER_YEAR) {
        fundingTypeBudgets =
            convertIScenarioFundingTypeBudgetToFundingTypeBudgetFormWithYear(scenarioDetails.budgetsByFundingType, scenarioDetails.startYear, scenarioDetails.planDuration, fundingTypes);
    }
    return {
        identity: scenarioDetails.id,
        name: scenarioDetails.name,
        description: scenarioDetails.description || '',
        planDurationYrs: scenarioDetails.planDuration.toString(),
        planningNeed: scenarioDetails.planningNeed.toString(),
        budgetType: scenarioDetails.budgetType.toString(),
        startYear: scenarioDetails.startYear.toString(),
        planningFactor: scenarioDetails.planningFactors,
        totalBudget: (_b = (_a = scenarioDetails.totalBudget) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : '',
        fundingTypeBudgets: fundingTypeBudgets,
        useEscalationFactor: scenarioDetails.applyEscalationFactor,
    };
};
export const convertIScenarioFundingTypeBudgetToFundingTypeBudgetForm = (budgets, fundingTypes) => {
    const fundingTypeBudgets = [];
    for (const fundingType of fundingTypes) {
        const foundBudget = budgets.find((budget) => budget.fundingTypeId === fundingType.identity);
        if (foundBudget) {
            fundingTypeBudgets.push({
                identity: fundingType.identity,
                name: fundingType.name,
                budget: foundBudget.budget.toString(),
            });
        }
        else {
            fundingTypeBudgets.push({
                identity: fundingType.identity,
                name: fundingType.name,
                budget: '',
            });
        }
    }
    return fundingTypeBudgets;
};
export const convertIScenarioFundingTypeBudgetToFundingTypeBudgetFormWithYear = (budgets, startYear, planDuration, fundingTypes) => {
    const fundingTypeBudgets = [];
    for (let i = 0; i < planDuration; i++) {
        const currentYear = startYear + i;
        for (const fundingType of fundingTypes) {
            const foundBudget = budgets.find((budget) => budget.fundingTypeId === fundingType.identity &&
                budget.year === currentYear);
            if (foundBudget) {
                fundingTypeBudgets.push({
                    identity: fundingType.identity,
                    name: fundingType.name,
                    budget: foundBudget.budget.toString(),
                    year: currentYear.toString(),
                });
            }
            else {
                fundingTypeBudgets.push({
                    identity: fundingType.identity,
                    name: fundingType.name,
                    budget: '',
                    year: currentYear.toString(),
                });
            }
        }
    }
    return fundingTypeBudgets;
};
export const convertITaxImpactDetailsToCreateTaxImpactForm = (taxImpact) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    return {
        totalReinvestment: (_a = taxImpact === null || taxImpact === void 0 ? void 0 : taxImpact.totalReinvestment) === null || _a === void 0 ? void 0 : _a.toString(),
        contributionYear: (_b = taxImpact === null || taxImpact === void 0 ? void 0 : taxImpact.contributionYear) === null || _b === void 0 ? void 0 : _b.toString(),
        contribution: (_c = taxImpact === null || taxImpact === void 0 ? void 0 : taxImpact.contribution) === null || _c === void 0 ? void 0 : _c.toString(),
        revenueBonded: taxImpact === null || taxImpact === void 0 ? void 0 : taxImpact.revenueBonded,
        revenueBondedPercentYear: (_d = taxImpact === null || taxImpact === void 0 ? void 0 : taxImpact.revenueBondedPercentageYear) === null || _d === void 0 ? void 0 : _d.toString(),
        revenueBondedPercent: (taxImpact === null || taxImpact === void 0 ? void 0 : taxImpact.revenueBondedPercentage)
            ? `${taxImpact.revenueBondedPercentage * 100}`
            : '',
        annualHomeValue: (taxImpact === null || taxImpact === void 0 ? void 0 : taxImpact.annualHomeValue)
            ? `${taxImpact.annualHomeValue}`
            : '',
        annualTaxImpact: (_f = (_e = taxImpact === null || taxImpact === void 0 ? void 0 : taxImpact.annualTaxImpact) === null || _e === void 0 ? void 0 : _e.toString()) !== null && _f !== void 0 ? _f : '',
        monthlyHomeValue: (taxImpact === null || taxImpact === void 0 ? void 0 : taxImpact.monthlyHomeValue)
            ? `${taxImpact === null || taxImpact === void 0 ? void 0 : taxImpact.monthlyHomeValue}`
            : '',
        monthlyTaxImpact: (_h = (_g = taxImpact === null || taxImpact === void 0 ? void 0 : taxImpact.monthlyTaxImpact) === null || _g === void 0 ? void 0 : _g.toString()) !== null && _h !== void 0 ? _h : '',
        acreValue: (_k = (_j = taxImpact === null || taxImpact === void 0 ? void 0 : taxImpact.acreValue) === null || _j === void 0 ? void 0 : _j.toString()) !== null && _k !== void 0 ? _k : '',
        annualAcreValueTaxImpact: (_m = (_l = taxImpact === null || taxImpact === void 0 ? void 0 : taxImpact.annualAcreValueTaxImpact) === null || _l === void 0 ? void 0 : _l.toString()) !== null && _m !== void 0 ? _m : '',
        ag2SchoolStateAid: (_o = taxImpact === null || taxImpact === void 0 ? void 0 : taxImpact.ag2SchoolStateAid) === null || _o === void 0 ? void 0 : _o.toString(),
        ltfmStateAid: (_p = taxImpact === null || taxImpact === void 0 ? void 0 : taxImpact.longTermFacilityMaintenanceStateAid) === null || _p === void 0 ? void 0 : _p.toString(),
        stateAidDescription: taxImpact === null || taxImpact === void 0 ? void 0 : taxImpact.stateAidDescription,
        stateAid: (_q = taxImpact === null || taxImpact === void 0 ? void 0 : taxImpact.stateAidAmount) === null || _q === void 0 ? void 0 : _q.toString(),
    };
};
export const convertCreateTaxImpactFormToICreateTaxImpact = (scenarioId, form) => {
    return {
        scenarioId: scenarioId,
        totalReinvestment: form.totalReinvestment
            ? convertStringToNumber(form.totalReinvestment)
            : undefined,
        contributionYear: form.contributionYear
            ? convertStringToNumber(form.contributionYear)
            : undefined,
        contribution: form.contribution
            ? convertStringToNumber(form.contribution)
            : undefined,
        revenueBonded: form.revenueBonded,
        revenueBondedPercentageYear: form.revenueBondedPercentYear
            ? convertStringToNumber(form.revenueBondedPercentYear)
            : undefined,
        revenueBondedPercentage: form.revenueBondedPercent
            ? convertStringToNumber(form.revenueBondedPercent) / 100
            : undefined,
        annualHomeValue: convertStringToNumber(form.annualHomeValue),
        annualTaxImpact: convertStringToNumber(form.annualTaxImpact),
        monthlyHomeValue: convertStringToNumber(form.monthlyHomeValue),
        monthlyTaxImpact: convertStringToNumber(form.monthlyTaxImpact),
        acreValue: convertStringToNumber(form.acreValue),
        annualAcreValueTaxImpact: convertStringToNumber(form.annualAcreValueTaxImpact),
        ag2SchoolStateAid: form.ag2SchoolStateAid
            ? convertStringToNumber(form.ag2SchoolStateAid)
            : undefined,
        longTermFacilityMaintenanceStateAid: form.ltfmStateAid
            ? convertStringToNumber(form.ltfmStateAid)
            : undefined,
        stateAidDescription: form.stateAidDescription,
        stateAidAmount: form.stateAid
            ? convertStringToNumber(form.stateAid)
            : undefined,
    };
};
