import { ProjectRelationshipType, } from '../Projects/ProjectsInterfaces';
import { IProjectRelationshipType, } from './PlanInterfaces';
export const convertPlanResponseToIScenarioPlan = (response) => {
    var _a;
    return {
        totalAllocation: response.totalAllocation,
        totalBudget: response.totalBudget,
        totalEscalationImpact: (_a = response.totalEscalationImpact) !== null && _a !== void 0 ? _a : undefined,
        hasRelatedProjects: response.hasRelatedProjects,
        budgetsByFundingType: convertBudgetsByFundingTypeToIBudgetByFundingType(response.fundingTypeBudgets),
        fundingTypeAllocations: convertFundingTypeAllocationResponseToIFundingTypeAllocation(response.fundingTypeAllocations),
        budgetsByFacility: convertBudgetsByFacilityToIBudgetsByFacility(response.facilityAllocations),
        projects: convertPlanProjectResponseToIPlanProjects(response.projects),
        relationshipViolations: convertRelationshipViolationsToIProjectRelationshipDetails(response.relationshipViolations),
    };
};
export const convertBudgetsByFundingTypeToIBudgetByFundingType = (budgetsByFundingType) => {
    return budgetsByFundingType.map((type) => {
        var _a, _b;
        return {
            fundingTypeId: type.fundingTypeId,
            fundingTypeName: type.fundingTypeName,
            planYear: (_a = type.planYear) !== null && _a !== void 0 ? _a : undefined,
            budget: (_b = type.budget) !== null && _b !== void 0 ? _b : undefined,
        };
    });
};
export const convertBudgetsByFacilityToIBudgetsByFacility = (budgetsByFacility) => {
    return budgetsByFacility.map((facility) => {
        var _a;
        return {
            facilityId: facility.facilityId,
            facilityName: facility.facilityName,
            allocationPercentage: facility.allocationPercentage,
            allocationAmount: facility.allocationAmount,
            allocationByArea: (_a = facility.allocationByArea) !== null && _a !== void 0 ? _a : undefined,
        };
    });
};
export const convertFundingTypeAllocationResponseToIFundingTypeAllocation = (fundingTypeAllocations) => {
    return fundingTypeAllocations.map((allocation) => {
        return {
            fundingTypeId: allocation.fundingTypeId,
            fundingTypeName: allocation.fundingTypeName,
            budget: allocation.allocation,
            planYear: allocation.planYear,
            escalationImpact: allocation.escalationImpact,
        };
    });
};
export const convertPlanProjectResponseToIPlanProjects = (planProjectsResponse) => {
    return planProjectsResponse.map((project) => {
        var _a, _b;
        return {
            projectId: project.projectId,
            planYear: project.planYear,
            fundingType: convertProjectFundingTypeToFundingType(project.fundingType),
            estimatedBudget: (_a = project.estimatedBudget) !== null && _a !== void 0 ? _a : undefined,
            escalatedBudget: (_b = project.escalatedBudget) !== null && _b !== void 0 ? _b : undefined,
        };
    });
};
export const convertProjectFundingTypeToFundingType = (fundingType) => {
    return {
        id: fundingType.fundingTypeId,
        name: fundingType.fundingTypeName,
    };
};
export const convertPlanAssetDetailResponseToPlanAssetDetail = (response) => {
    return {
        assetCount: response.assetCount,
        agedAssetCount: response.agedAssetCount,
        alarmAlertAssetCount: response.alarmAlertAssetCount,
        alarmAssetsNoProjectsCount: response.alarmAssetsNoProjectsCount,
        alertAssetsNoProjectsCount: response.alertAssetsNoProjectsCount,
        assets: convertPlanAssetResponseToIPlanAsset(response.assets),
    };
};
export const convertPlanAssetResponseToIPlanAsset = (response) => {
    return response.map((asset) => {
        var _a, _b;
        return {
            assetIdentity: asset.assetId,
            name: asset.name,
            assetCategory: asset.assetCategory,
            assetClass: asset.assetClass,
            assetType: asset.assetType,
            facilityIdentity: asset.facilityId,
            facilityName: asset.facilityName,
            currentCondition: (_a = asset.currentCondition) !== null && _a !== void 0 ? _a : undefined,
            projectedLifeRemaining: (_b = asset.projectedLifeRemaining) !== null && _b !== void 0 ? _b : undefined,
            spaceIdentity: asset.spaceId,
            spaceName: asset.spaceName,
            projects: convertAssetProjectResponseToIPlanProject(asset.projects),
            quantity: asset.quantity,
        };
    });
};
export const convertAssetProjectResponseToIPlanProject = (projectResponse) => {
    return projectResponse.map((project) => {
        return {
            projectIdentity: project.projectId,
            projectName: project.projectName,
            projectTypeName: project.projectTypeName,
            projectScope: project.projectScope,
        };
    });
};
export const convertIPlanProjectToIPlannedProject = (project, projectFromProjectList) => {
    return {
        planYear: project.planYear,
        projectSummary: convertIProjectSummaryToIncludeEscalatedBudget(projectFromProjectList, project),
    };
};
export const convertIProjectSummaryToIncludeEscalatedBudget = (projectFromProjectList, planProject) => {
    return {
        facilityIdentity: projectFromProjectList.facilityIdentity,
        name: projectFromProjectList.name,
        estimatedBudget: projectFromProjectList.estimatedBudget,
        scope: projectFromProjectList.scope,
        identity: projectFromProjectList.identity,
        facilityName: projectFromProjectList.facilityName,
        isLocked: projectFromProjectList.isLocked,
        fundingTypeName: planProject.fundingType.name,
        fundingTypeId: planProject.fundingType.id,
        projectTypeName: projectFromProjectList.projectTypeName,
        escalatedBudget: planProject.escalatedBudget,
        createdOn: projectFromProjectList.createdOn,
        priorityScore: projectFromProjectList.priorityScore,
        priorityRating: projectFromProjectList.priorityRating,
        plannedFundingTypes: projectFromProjectList.plannedFundingTypes,
        criteriaScores: projectFromProjectList.criteriaScores,
    };
};
export const convertToLockUnlockRequest = (scenarioIdentity, isLocking) => {
    return {
        scenarioId: scenarioIdentity,
        lock: isLocking,
    };
};
export const convertRelationshipViolationsToIProjectRelationshipDetails = (relationshipViolations) => {
    return relationshipViolations
        .map((relation) => {
        return {
            projectName: relation.projectName,
            relatedProjectName: relation.relatedProjectName,
            violationType: convertViolationType(relation.violationType),
            relationMessage: convertToRelationMessage(relation),
            actionMessage: `Add ${relation.relatedProjectName} to the scenario.`,
        };
    })
        .filter((violation) => {
        if (violation.violationType === IProjectRelationshipType.UNKNOWN) {
            console.warn(`Relation with unknown violationType: ${violation.violationType}`);
        }
        return violation.violationType !== IProjectRelationshipType.UNKNOWN;
    });
};
const convertViolationType = (type) => {
    switch (type) {
        case 'Required':
            return IProjectRelationshipType.REQUIRED;
        case 'Recommended':
            return IProjectRelationshipType.RECOMMENDED;
        case 'Alternate':
            return IProjectRelationshipType.ALTERNATE;
        default:
            return IProjectRelationshipType.UNKNOWN;
    }
};
const convertToRelationMessage = (violation) => {
    if (violation.violationType === IProjectRelationshipType.REQUIRED) {
        return `${violation.projectName} ${ProjectRelationshipType.REQUIRES.toLowerCase()} ${violation.relatedProjectName}.`;
    }
    else if (violation.violationType === IProjectRelationshipType.RECOMMENDED) {
        return `${violation.projectName}  is recommended with  ${violation.relatedProjectName}.`;
    }
    else if (violation.violationType === IProjectRelationshipType.ALTERNATE) {
        return `${violation.projectName} and ${violation.relatedProjectName} cannot be in the same scenario.`;
    }
    return '';
};
export const sortAndFilterRelationshipViolations = (violations) => {
    const sortedViolations = violations.sort((a, b) => {
        var _a, _b;
        const violationTypeOrder = {
            REQUIRED: 1,
            ALTERNATIVE: 2,
        };
        // the rest default to violation type order 3
        const violationTypeA = (_a = violationTypeOrder[a.violationType]) !== null && _a !== void 0 ? _a : 3;
        const violationTypeB = (_b = violationTypeOrder[b.violationType]) !== null && _b !== void 0 ? _b : 3;
        return violationTypeA - violationTypeB;
    });
    return sortedViolations
        .filter((violation) => violation.violationType !== IProjectRelationshipType.RECOMMENDED)
        .map((violation) => violation.relationMessage)
        .filter((message) => message !== '');
};
