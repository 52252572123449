import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Line, Shape } from 'react-konva';
const getGradientColorStops = (fill, isSelected) => {
    const colorStops = [];
    fill.forEach((colorConfig, index) => {
        const color = isSelected
            ? colorConfig.selected
            : colorConfig.unselected;
        const startPosition = index / fill.length;
        const endPosition = (index + 1) / fill.length;
        colorStops.push(startPosition, color);
        colorStops.push(endPosition, color);
    });
    return colorStops;
};
export const SplitShape = ({ shape, style, isSelected, onSelect, }) => {
    const getPointsArrayFromShape = (shape) => {
        const points = [];
        shape.forEach((point) => points.push(point.x, point.y));
        return points;
    };
    const points = getPointsArrayFromShape(shape);
    const minX = Math.min(...points.filter((_, i) => i % 2 === 0));
    const maxX = Math.max(...points.filter((_, i) => i % 2 === 0));
    const minY = Math.min(...points.filter((_, i) => i % 2 === 1));
    const maxY = Math.max(...points.filter((_, i) => i % 2 === 1));
    const drawShape = (context, shape) => {
        context.beginPath();
        points.forEach((value, index) => {
            if (index % 2 === 0) {
                const x = value;
                const y = points[index + 1];
                if (index === 0) {
                    context.moveTo(x, y);
                }
                else {
                    context.lineTo(x, y);
                }
            }
        });
        context.closePath();
        context.fillStrokeShape(shape);
    };
    const { stroke, gradientFill } = style;
    return (_jsxs(_Fragment, { children: [gradientFill && gradientFill.length > 0 && (_jsx(Shape, { sceneFunc: drawShape, fillLinearGradientStartPoint: { x: minX, y: minY }, fillLinearGradientEndPoint: { x: maxX, y: maxY }, fillLinearGradientColorStops: getGradientColorStops(gradientFill, isSelected), stroke: isSelected
                    ? stroke.selected.color
                    : stroke.unselected.color, strokeWidth: isSelected
                    ? stroke.selected.width
                    : stroke.unselected.width, dash: isSelected ? [] : [5, 2], onMouseDown: onSelect, onTouchEnd: onSelect })), _jsx(Line, { points: points })] }));
};
