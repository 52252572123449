import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, ButtonGroup, Modal, ModalBody, ModalContent, ModalFooter, ModalOverlay, Text, } from '@chakra-ui/react';
import { appColors } from '../../config/constants';
import { variants } from '@frontend/design-system/theme/theme';
export const UnlockScenarioWarningModal = ({ isOpen, onClose, toggleLockScenario, }) => {
    return (_jsxs(Modal, Object.assign({ isOpen: isOpen, onClose: onClose, isCentered: true }, { children: [_jsx(ModalOverlay, {}), _jsx("form", { children: _jsxs(ModalContent, Object.assign({ minW: '600px' }, { children: [_jsxs(ModalBody, Object.assign({ py: '0px', pt: '25px' }, { children: [_jsx(Text, Object.assign({ mb: '25px' }, { children: "Unlocking a scenario removes the tax impact and the ability to view planning reports. This action cannot be reversed." })), _jsx(Text, Object.assign({ mb: '25px' }, { children: "Are you sure you want to continue?" }))] })), _jsx(ModalFooter, { children: _jsxs(ButtonGroup, Object.assign({ w: '100%', justifyContent: 'space-between' }, { children: [_jsx(Button, Object.assign({ borderColor: appColors.DELETE_ERROR, variant: variants.redOutlineBtn, onClick: onClose }, { children: "Cancel" })), _jsx(Button, Object.assign({ variant: variants.blueBtn, onClick: () => {
                                            toggleLockScenario(false);
                                            onClose();
                                        } }, { children: "Continue" }))] })) })] })) })] })));
};
