import { faker } from '@faker-js/faker';
import { CriteriaType, ProjectRelationshipType, } from '../models/Projects/ProjectsInterfaces';
export class ProjectDetailResponseBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ projectId: faker.number.int({ min: undefined, max: undefined }), facilityId: faker.number.int({ min: undefined, max: undefined }), facilityName: faker.lorem.slug(1), siteName: faker.lorem.slug(1), name: faker.lorem.slug(1), projectType: {
                    projectTypeId: faker.number.int({
                        min: undefined,
                        max: undefined,
                    }),
                    projectTypeName: faker.lorem.slug(1),
                    algorithmId: faker.number.int({
                        min: undefined,
                        max: undefined,
                    }),
                }, fundingType: {
                    fundingTypeId: faker.number.int({
                        min: undefined,
                        max: undefined,
                    }),
                    fundingTypeName: faker.lorem.slug(1),
                }, scope: faker.lorem.slug(1), ideas: faker.lorem.slug(1), isLocked: faker.datatype.boolean(), estimatedBudget: parseFloat(faker.finance.amount()), quoteDate: faker.date.anytime().toLocaleDateString().slice(0, 10), createdOn: faker.date.anytime().toLocaleTimeString().slice(0, 10), criteriaScores: [], assetIds: [], supportingFiles: [], relatedProjects: [], scenarios: [], rebates: [], cO2eSavings: null, energySavings: null, thermsSavings: null, waterSavings: null, monetarySavings: [], requiredByProjects: [] }, this.overrides);
        };
        this.overrides = overrides;
    }
}
export class IProjectDetailsBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ identity: faker.number.int({ min: undefined, max: undefined }), facilityName: faker.lorem.slug(1), siteName: faker.lorem.slug(1), projectType: {
                    identity: faker.number.int({ min: undefined, max: undefined }),
                    name: faker.lorem.slug(1),
                    algorithmId: faker.number.int({
                        min: undefined,
                        max: undefined,
                    }),
                    isInUse: faker.datatype.boolean(),
                }, fundingType: {
                    identity: faker.number.int({ min: undefined, max: undefined }),
                    name: faker.lorem.slug(1),
                    isInUse: faker.datatype.boolean(),
                }, createdOn: faker.date.anytime(), facilityIdentity: faker.number.int({
                    min: undefined,
                    max: undefined,
                }), name: faker.lorem.slug(1), estimatedBudget: parseFloat(faker.finance.amount()), scope: faker.lorem.lines(2), quoteDate: faker.date.anytime().toISOString().slice(0, 10), isLocked: false, ideas: faker.lorem.lines(2), assetIdentities: [
                    faker.number.int({
                        min: undefined,
                        max: undefined,
                    }),
                ], criteriaScores: [
                    {
                        identity: faker.number.int({
                            min: undefined,
                            max: undefined,
                        }),
                        name: faker.lorem.slug(1),
                        type: faker.helpers.enumValue(CriteriaType),
                        weight: faker.number.float({
                            min: 0,
                            max: 1,
                            precision: 2,
                        }),
                    },
                ], scenarios: [], relatedProjects: [
                    {
                        identity: faker.number.int({
                            min: undefined,
                            max: undefined,
                        }),
                        projectName: faker.lorem.slug(1),
                        relationshipType: faker.helpers.enumValue(ProjectRelationshipType),
                    },
                ], supportingFiles: [], rebates: [new IProjectRebateBuilder({}).build()], savings: [], dollarSavings: [], requiredByProjects: [
                    {
                        identity: faker.number.int({
                            min: undefined,
                            max: undefined,
                        }),
                        projectName: faker.lorem.slug(1),
                        relationshipType: ProjectRelationshipType.REQUIRED_BY,
                    },
                ] }, this.overrides);
        };
        this.overrides = overrides;
    }
}
export class IProjectRebateBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ identity: faker.number.int({ min: undefined, max: undefined }), rebateProgram: faker.lorem.slug(3), amount: faker.number.int({ min: 0, max: 1000000000000 }), expirationDate: new Date(), notes: faker.lorem.paragraph(2) }, this.overrides);
        };
        this.overrides = overrides;
    }
}
export class IProjectSummaryBuilder {
    constructor(overrides) {
        this.build = () => {
            return Object.assign({ identity: faker.number.int({ min: undefined, max: undefined }), facilityName: faker.lorem.slug(1), isLocked: faker.datatype.boolean(), fundingTypeName: faker.lorem.slug(1), projectTypeName: faker.lorem.slug(1), createdOn: faker.date.anytime(), name: faker.lorem.slug(1), facilityIdentity: faker.number.int({
                    min: undefined,
                    max: undefined,
                }), fundingTypeId: faker.number.int({ min: undefined, max: undefined }), criteriaScores: [
                    {
                        name: faker.lorem.slug(1),
                        score: faker.number.int({ min: 1, max: 5 }).toString(),
                    },
                ], estimatedBudget: parseFloat(faker.finance.amount(undefined, undefined, 2)), scope: faker.lorem.lines(2), priorityScore: faker.number.float({ min: 0, max: 1, precision: 1 }), priorityRating: faker.lorem.slug(1), plannedFundingTypes: [] }, this.overrides);
        };
        this.overrides = overrides;
    }
}
