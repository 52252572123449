import { jsx as _jsx } from "react/jsx-runtime";
import { HStack, Stack } from '@chakra-ui/react';
import { SelectInput } from '@frontend/design-system/components/DynamicInput/SelectInput';
import { BudgetTypeEnum } from '@frontend/domain/models/Scenario/ScenarioInterfaces';
import React from 'react';
import { Controller } from 'react-hook-form';
import { convertIScenarioFundingTypeBudgetToFundingTypeBudgetForm, convertIScenarioFundingTypeBudgetToFundingTypeBudgetFormWithYear, } from '../../../contexts/Scenarios/ScenarioConversions';
import { useFormSectionErrorContext, } from '../ScenarioModal';
import { PLAN_FUNDING_TYPE_BUDGET_KEY, PLAN_TOTAL_BUDGET_KEY, } from './BudgetPage';
import { PLAN_DURATION_KEY, PLAN_START_KEY } from './ScenarioDetailsPage';
export const PLAN_BUDGET_TYPE_KEY = 'budgetType';
export const PlanningByPage = ({ formContext, stepOverride, fundingTypes, }) => {
    const { control, setValue, watch } = formContext;
    // not currently needing to return error context
    useFormSectionErrorContext([PLAN_BUDGET_TYPE_KEY], formContext, (hasError) => stepOverride.setCanContinue(!hasError));
    const startYear = watch(PLAN_START_KEY);
    const planDuration = watch(PLAN_DURATION_KEY);
    const onPlanningByChange = (planningBy) => {
        switch (planningBy) {
            case BudgetTypeEnum.TOTAL_BUDGET: {
                setValue(PLAN_FUNDING_TYPE_BUDGET_KEY, []);
                break;
            }
            case BudgetTypeEnum.FUNDING_TYPE: {
                setValue(PLAN_FUNDING_TYPE_BUDGET_KEY, convertIScenarioFundingTypeBudgetToFundingTypeBudgetForm([], fundingTypes));
                break;
            }
            case BudgetTypeEnum.FUNDING_TYPE_PER_YEAR: {
                setValue(PLAN_FUNDING_TYPE_BUDGET_KEY, convertIScenarioFundingTypeBudgetToFundingTypeBudgetFormWithYear([], parseInt(startYear), parseInt(planDuration), fundingTypes));
                break;
            }
        }
        setValue(PLAN_TOTAL_BUDGET_KEY, '');
    };
    return (_jsx(Stack, { children: _jsx(HStack, { children: _jsx(Controller, { control: control, name: PLAN_BUDGET_TYPE_KEY, render: ({ field }) => (_jsx(SelectInput, { label: 'I am planning by...', data: field.value, onInputChange: (e) => {
                        field.onChange(e);
                        onPlanningByChange(e);
                    }, choices: [
                        {
                            label: 'Total Budget',
                            value: BudgetTypeEnum.TOTAL_BUDGET.toString(),
                        },
                        {
                            label: 'Budget broken down by funding type',
                            value: BudgetTypeEnum.FUNDING_TYPE.toString(),
                        },
                        {
                            label: 'Budget broken down by funding type and year',
                            value: BudgetTypeEnum.FUNDING_TYPE_PER_YEAR.toString(),
                        },
                    ], editable: true, required: true })), rules: { required: true } }) }) }));
};
