import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, FormErrorMessage, FormLabel, Input, InputGroup, } from '@chakra-ui/react';
export const instanceOfRangeValue = (x) => {
    if (x instanceof Object) {
        return Object.keys(x).length === 2 && 'min' in x && 'max' in x;
    }
    else {
        return false;
    }
};
export const validateRangeValue = (range) => {
    if (range.min !== undefined &&
        range.max !== undefined &&
        range.min > range.max) {
        return 'Minimum cannot be greater than maximum';
    }
};
export function RangeInput({ label, value, onChange, inputControl, error, testId, }) {
    const formatNumberValue = (value) => {
        if (value !== undefined) {
            if (inputControl === null || inputControl === void 0 ? void 0 : inputControl.format) {
                return inputControl.format(value);
            }
            else {
                return value.toString();
            }
        }
        else {
            return '';
        }
    };
    const parseToNumber = (value) => {
        if (value !== undefined) {
            if (value !== '') {
                return parseInt(value);
            }
        }
        return undefined;
    };
    const parseNumberValue = (min, max) => {
        const parsingFunction = (inputControl === null || inputControl === void 0 ? void 0 : inputControl.parse)
            ? inputControl.parse
            : parseToNumber;
        return {
            target: {
                value: {
                    min: parsingFunction(min),
                    max: parsingFunction(max),
                },
            },
        };
    };
    const currMin = formatNumberValue(value === null || value === void 0 ? void 0 : value.min);
    const currMax = formatNumberValue(value === null || value === void 0 ? void 0 : value.max);
    return (_jsxs(FormControl, Object.assign({ isInvalid: error !== undefined && error !== '' }, { children: [_jsx(FormLabel, Object.assign({ htmlFor: 'range-input' }, { children: label })), _jsxs(InputGroup, Object.assign({ id: 'range-input', alignContent: 'space-between', gap: '10px' }, { children: [_jsx(Input, { size: 'sm', value: currMin, type: (inputControl === null || inputControl === void 0 ? void 0 : inputControl.type) || 'text', placeholder: 'Min', onChange: (e) => {
                            onChange(parseNumberValue(e.target.value, currMax));
                        }, "data-testid": `${testId}-min-input` }), _jsx(Input, { size: 'sm', value: currMax, type: (inputControl === null || inputControl === void 0 ? void 0 : inputControl.type) || 'text', placeholder: 'Max', onChange: (e) => {
                            onChange(parseNumberValue(currMin, e.target.value));
                        }, "data-testid": `${testId}-max-input` })] })), error && _jsx(FormErrorMessage, { children: error })] })));
}
