import { jsx as _jsx } from "react/jsx-runtime";
import { NavBar } from '@frontend/design-system/components/NavBar/NavBar';
import { OptimizeBoldIcon, OptimizeIcon, OrganizeBoldIcon, OrganizeIcon, OrgSettingsBoldIcon, OrgSettingsIcon, PrioritizeBoldIcon, PrioritizeIcon, SupportBoldIcon, SupportIcon, SystemBoldIcon, SystemIcon, } from '@frontend/design-system/theme/icons';
import { useAppAccess } from '@frontend/domain/contexts/Authorization/UserAccess';
import { IAppNames } from '@frontend/domain/models/App';
import { AppRoutes } from '@frontend/shared/src/components/Routing/RouteConstants';
import { useLocation } from 'react-router-dom';
import { environment } from '../../config/environment';
import LogoFull from '../../images/LogoFull.png';
import LogoSmall from '../../images/LogoSmall.png';
export var NavLinkName;
(function (NavLinkName) {
    NavLinkName["ORGANIZE"] = "Organize";
    NavLinkName["PRIORITIZE"] = "Prioritize";
    NavLinkName["OPTIMIZE"] = "Optimize";
    NavLinkName["SYSTEM_ADMIN"] = "System Admin";
    NavLinkName["ORGANIZATION_SETTINGS"] = "Organization Settings";
    NavLinkName["SUPPORT"] = "Support";
})(NavLinkName || (NavLinkName = {}));
export const ExpandableSidebar = ({ children, isAdmin, user, orgIdentity, reports, }) => {
    var _a, _b;
    const { hasAccess } = useAppAccess(IAppNames.PIQ);
    const location = useLocation();
    const currentURL = location.pathname;
    const isSublinkCurrent = (sublinkURL) => {
        return currentURL === sublinkURL;
    };
    const mapSublinks = (sublinks) => {
        return sublinks.map((sublink) => {
            var _a, _b;
            return (Object.assign(Object.assign({}, sublink), { isCurrentLink: isSublinkCurrent((_b = (_a = sublink.link) === null || _a === void 0 ? void 0 : _a.href) !== null && _b !== void 0 ? _b : '') }));
        });
    };
    const externalBasePaths = {
        base: environment.msiqUrl,
        organize: environment.fcaUrl,
        prioritize: environment.plannerUrl,
        opportunities: `${environment.plannerUrl}/opportunities`,
    };
    const spreadReports = (orgIdentity, reportList) => {
        return reportList && reportList.length > 0
            ? [
                {
                    title: 'Reports',
                    subGroups: reportList.map((report) => ({
                        title: report.name,
                        link: {
                            href: AppRoutes.reports(orgIdentity, report.id, externalBasePaths.base),
                        },
                    })),
                },
            ]
            : [];
    };
    return (_jsx(NavBar, Object.assign({ squishContent: true, user: {
            name: (_a = `${user === null || user === void 0 ? void 0 : user.firstName} ${user === null || user === void 0 ? void 0 : user.lastName}`) !== null && _a !== void 0 ? _a : '',
            systemRole: (_b = user === null || user === void 0 ? void 0 : user.systemRole) !== null && _b !== void 0 ? _b : '',
        }, assets: {
            logoSmallSrc: LogoSmall,
            logoLargeSrc: LogoFull,
        }, links: {
            home: {
                href: AppRoutes.landingPage(orgIdentity, externalBasePaths.base),
            },
            profile: {
                href: AppRoutes.profile(externalBasePaths.base),
                external: true,
            },
            logout: {
                href: AppRoutes.logout(),
            },
        }, linkGroups: [
            ...(orgIdentity
                ? [
                    {
                        title: NavLinkName.ORGANIZE,
                        icon: OrganizeIcon,
                        selectedIcon: OrganizeBoldIcon,
                        subLinks: mapSublinks([
                            {
                                title: 'FCA Tool',
                                link: {
                                    href: AppRoutes.myFacilities(orgIdentity, externalBasePaths.organize),
                                    external: true,
                                },
                            },
                            {
                                title: 'myGallery',
                                link: {
                                    href: AppRoutes.myGallery(orgIdentity, externalBasePaths.organize),
                                    external: true,
                                },
                            },
                            ...spreadReports(orgIdentity, reports === null || reports === void 0 ? void 0 : reports.organize),
                        ]),
                    },
                    {
                        title: NavLinkName.PRIORITIZE,
                        icon: PrioritizeIcon,
                        selectedIcon: PrioritizeBoldIcon,
                        subLinks: [
                            {
                                title: 'myProjects',
                                link: {
                                    href: AppRoutes.myProjects(orgIdentity),
                                },
                            },
                            {
                                title: 'myScenarios',
                                link: {
                                    href: AppRoutes.myScenarios(orgIdentity),
                                },
                            },
                            {
                                title: 'myAssets',
                                link: {
                                    href: AppRoutes.myAssets(orgIdentity),
                                },
                            },
                            ...(hasAccess
                                ? [
                                    {
                                        title: 'myOpportunities',
                                        link: {
                                            href: AppRoutes.myOpportunities(orgIdentity, externalBasePaths.opportunities),
                                            external: true,
                                        },
                                    },
                                ]
                                : []),
                            ...spreadReports(orgIdentity, reports === null || reports === void 0 ? void 0 : reports.prioritize),
                        ],
                    },
                    {
                        title: NavLinkName.OPTIMIZE,
                        icon: OptimizeIcon,
                        selectedIcon: OptimizeBoldIcon,
                        subLinks: [...spreadReports(orgIdentity, reports === null || reports === void 0 ? void 0 : reports.optimize)],
                        divideAfter: true,
                    },
                    ...(isAdmin
                        ? [
                            {
                                title: NavLinkName.SYSTEM_ADMIN,
                                icon: SystemIcon,
                                selectedIcon: SystemBoldIcon,
                                link: {
                                    href: AppRoutes.systemAdmin(externalBasePaths.base),
                                    external: true,
                                },
                            },
                        ]
                        : []),
                    {
                        title: NavLinkName.ORGANIZATION_SETTINGS,
                        icon: OrgSettingsIcon,
                        selectedIcon: OrgSettingsBoldIcon,
                        link: {
                            href: AppRoutes.organizationSettings(orgIdentity, externalBasePaths.base),
                            external: true,
                        },
                    },
                ]
                : []),
            {
                title: NavLinkName.SUPPORT,
                icon: SupportIcon,
                selectedIcon: SupportBoldIcon,
                link: {
                    href: AppRoutes.support(),
                },
            },
        ] }, { children: children })));
};
