import { faker } from '@faker-js/faker';
import { createGetHandler } from '../testing/msw/handlerUtils';
import { PlatformMapBuilder } from '../testing/platformMapMocks';
const assessmentEndpoints = {
    map: '/api/v1/Assessment/map/:floorPlanId',
};
const mapHandler = createGetHandler(assessmentEndpoints.map, [...new Array(faker.number.int({ min: 1, max: 10 })).keys()].map((_) => new PlatformMapBuilder({}).build()));
const assessmentHandlers = [mapHandler];
export const assessmentHandler = {
    endpoints: assessmentEndpoints,
    handlers: assessmentHandlers,
};
