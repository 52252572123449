import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { HStack, Input, InputGroup, InputLeftElement, Select, Text, VStack, } from '@chakra-ui/react';
import { validateMinMax } from '@frontend/domain/formValidations/RuleValidation';
import { Controller } from 'react-hook-form';
import MaskedInput from 'react-text-mask';
import { createNumberMask } from 'text-mask-addons';
import { TaxImpactText } from './TaxImpactText';
import { TaxImpactValueText } from './TaxImpactValueText';
const REVENUE_BONDED_KEY = 'revenueBonded';
const REVENUE_BONDED_PERCENT_YEAR_KEY = 'revenueBondedPercentYear';
const REVENUE_BONDED_PERCENT_KEY = 'revenueBondedPercent';
export const RevenueBondedInput = ({ options, taxImpactExists, formContext, }) => {
    const { control, resetField, formState: { errors }, watch, } = formContext;
    const revenueBonded = watch(REVENUE_BONDED_KEY);
    const revenueBondedYear = watch(REVENUE_BONDED_PERCENT_YEAR_KEY);
    const revenueBondedPercent = watch(REVENUE_BONDED_PERCENT_KEY);
    const percentMask = createNumberMask({
        prefix: '',
        suffix: '',
        includeThousandsSeparator: false,
        allowDecimal: false,
        integerLimit: 4,
        allowNegative: false,
        allowLeadingZeroes: false,
    });
    const anyFieldFilled = revenueBonded || revenueBondedYear || revenueBondedPercent;
    return taxImpactExists ? (_jsxs(VStack, Object.assign({ spacing: 0.5, alignSelf: 'flex-start', "aria-label": 'revenue-bonded-values' }, { children: [_jsx(TaxImpactText, { text: `Percent of ${revenueBonded} revenue bonded (for ${revenueBondedYear} years)` }), _jsx(TaxImpactValueText, { text: `${revenueBondedPercent} %` })] }))) : (_jsxs(VStack, Object.assign({ w: '100%', spacing: 1, "aria-label": 'revenue-bonded-form' }, { children: [_jsxs(HStack, Object.assign({ w: '100%', spacing: 1 }, { children: [_jsx(TaxImpactText, { text: 'Percent of' }), _jsx(Controller, { control: control, name: REVENUE_BONDED_KEY, rules: {
                            required: anyFieldFilled ? true : false,
                        }, render: ({ field }) => (_jsx(_Fragment, { children: _jsx(Input, { h: '25px', w: '150px', type: 'text', placeholder: 'Type here...', maxLength: 30, onChange: (e) => {
                                    if (!(e && e.target.value) || e.target.value === '') {
                                        resetField(REVENUE_BONDED_KEY);
                                    }
                                    field.onChange(e);
                                } }) })) })] })), _jsxs(HStack, Object.assign({ w: '100%', spacing: 1 }, { children: [_jsx(TaxImpactText, { text: 'revenue bonded for' }), _jsx(Controller, { control: control, name: REVENUE_BONDED_PERCENT_YEAR_KEY, rules: { required: anyFieldFilled ? true : false }, render: ({ field }) => (_jsx(_Fragment, { children: _jsx(Select, Object.assign({ h: '25px', w: '125px', placeholder: 'Select', onChange: (e) => {
                                    if (!(e && e.target.value) || e.target.value === '') {
                                        resetField(REVENUE_BONDED_PERCENT_YEAR_KEY);
                                    }
                                    field.onChange(e);
                                } }, { children: options })) })) })] })), _jsx(Controller, { control: control, name: REVENUE_BONDED_PERCENT_KEY, rules: {
                    required: anyFieldFilled ? true : false,
                    validate: (value) => {
                        return validateMinMax(value, 0, 100);
                    },
                }, render: ({ field }) => (_jsxs(_Fragment, { children: [_jsxs(InputGroup, { children: [_jsx(InputLeftElement, Object.assign({ pointerEvents: 'none' }, { children: "%" })), _jsx(Input, { as: MaskedInput, mask: percentMask, placeholder: 'Type here...', onChange: (e) => {
                                        if (!(e && e.target.value) || e.target.value === '') {
                                            resetField(REVENUE_BONDED_PERCENT_KEY);
                                        }
                                        field.onChange(e);
                                    } })] }), errors.revenueBondedPercent && (_jsx(Text, Object.assign({ alignSelf: 'start', color: 'red', fontSize: '13px' }, { children: errors.revenueBondedPercent.message })))] })) })] })));
};
