import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Flex, Grid, GridItem, Text } from '@chakra-ui/react';
import React from 'react';
import theme from '../../theme/theme';
import { truncateText } from '../../utils/uiUtils';
import { Popover } from '../Popover/Popover';
export const TableGridBody = ({ data, bodyStyles, cellStyles, popoverCellIndex, emptyStateText = '', }) => {
    const isWindows = /Win/i.test(navigator.userAgent);
    return (_jsx(_Fragment, { children: data.length === 0 ? (_jsx(Flex, Object.assign({ paddingX: 5, height: '40px', alignItems: 'center' }, { children: _jsx(Text, Object.assign({ fontSize: '10px', color: theme.colors.brand.secDarkGray, fontWeight: 500 }, { children: emptyStateText })) }))) : (_jsx(Box, Object.assign({ style: bodyStyles, overflow: 'auto' }, { children: data.map((rowData, index) => (_jsx(Grid, Object.assign({ templateColumns: `repeat(${rowData.length},${100 / rowData.length}% )`, templateRows: '40px', alignItems: 'center', paddingX: 5, borderBottom: index < data.length - 1
                    ? `1px solid ${theme.colors.brand.primLightGrey}`
                    : 'none', 
                // gap set for static table in Windows; needs testing with dynamic tables on Windows.
                gap: isWindows ? '8px' : '0px' }, { children: rowData.map((cellData, i) => {
                    const shouldShowPopover = popoverCellIndex === i;
                    return (_jsx(GridItem, Object.assign({ colSpan: 1 }, { children: shouldShowPopover ? (_jsx(Popover, Object.assign({ content: cellData, customStyles: {
                                borderRadius: '2px',
                                boxShadow: `0px 0px 4px 0px ${theme.colors.base.black}40`,
                                fontSize: '10px',
                            } }, { children: _jsx(Text, Object.assign({ fontSize: '10px', style: cellStyles[i] }, { children: truncateText(cellData, 12) })) }))) : (_jsx(Text, Object.assign({ fontSize: '10px', style: cellStyles[i] }, { children: cellData }))) }), `row-cell-${i}`));
                }) }), `row-${index}`))) }))) }));
};
