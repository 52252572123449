import { jsx as _jsx } from "react/jsx-runtime";
import { CurrencyInput } from '@frontend/design-system/components/Inputs/CurrencyInput';
import React from 'react';
import { useController } from 'react-hook-form';
export const FundingTypeInput = ({ formContext, fundingTypeBudget, index, updateOnChange, }) => {
    // cast required to properly accept typing on register
    const field = `fundingTypeBudgets.${index}`;
    const { field: ftField } = useController({
        name: field,
        control: formContext.control,
    });
    return (_jsx(CurrencyInput, { label: fundingTypeBudget.name, onInputChange: (e) => {
            const newFt = Object.assign(Object.assign({}, fundingTypeBudget), { budget: e.target.value });
            ftField.onChange({ target: { value: newFt } });
        }, onMouseLeave: (e) => {
            updateOnChange(fundingTypeBudget, e.target.value, index);
        }, data: ftField.value.budget, placeholder: 'Type here...', maskOptions: { allowDecimal: false } }));
};
