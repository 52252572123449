var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { InputGroup } from '@chakra-ui/react';
import React, { useRef } from 'react';
const FileUpload = ({ register, accept, multiple, children, }) => {
    const inputRef = useRef(null);
    const _a = register, { ref } = _a, rest = __rest(_a, ["ref"]);
    const handleClick = () => { var _a; return (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.click(); };
    return (_jsxs(InputGroup, Object.assign({ onClick: handleClick }, { children: [_jsx("input", Object.assign({ "data-testid": 'file-upload', type: 'file', multiple: multiple || false, hidden: true, accept: accept }, rest, { ref: (e) => {
                    ref(e);
                    inputRef.current = e;
                } })), _jsx(_Fragment, { children: children })] })));
};
export default FileUpload;
