import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Select, VStack } from '@chakra-ui/react';
import { CurrencyInput } from '@frontend/design-system/components/Inputs/CurrencyInput';
import { Controller } from 'react-hook-form';
import { convertToCurrency } from '../../../../config/CPHelperFunctions';
import { TaxImpactText } from './TaxImpactText';
import { TaxImpactValueText } from './TaxImpactValueText';
const CONTRIBUTION_YEAR_KEY = 'contributionYear';
const CONTRIBUTION_KEY = 'contribution';
export const FundContributionInput = ({ options, taxImpactExists, formContext, }) => {
    const { control, resetField, watch } = formContext;
    const contribution = watch(CONTRIBUTION_KEY);
    const contributionYear = watch(CONTRIBUTION_YEAR_KEY);
    const anyFieldFilled = contribution || contributionYear;
    return taxImpactExists && contribution ? (_jsxs(VStack, Object.assign({ spacing: 0.5, alignSelf: 'flex-start', "aria-label": 'contribution-values' }, { children: [_jsx(TaxImpactText, { text: `Annual district capital contribution (for ${contributionYear} years)` }), _jsx(TaxImpactValueText, { text: `${convertToCurrency(contribution)}` })] }))) : (_jsxs(VStack, Object.assign({ w: '100%', spacing: 1, "aria-label": 'contribution-form' }, { children: [_jsx(TaxImpactText, { text: 'Annual district capital contribution for' }), _jsx(Controller, { control: control, name: CONTRIBUTION_YEAR_KEY, rules: { required: anyFieldFilled ? true : false }, render: ({ field }) => (_jsx(Select, Object.assign({ alignSelf: 'flex-start', h: '25px', w: '125px', placeholder: 'Select', onChange: (e) => {
                        if (!(e && e.target.value) || e.target.value === '') {
                            resetField(CONTRIBUTION_YEAR_KEY);
                        }
                        field.onChange(e);
                    } }, { children: options }))) }), _jsx(Controller, { control: control, name: CONTRIBUTION_KEY, rules: { required: anyFieldFilled ? true : false }, render: ({ field }) => (_jsx(CurrencyInput, { data: field.value, onInputChange: (e) => {
                        if (!(e && e.target.value) || e.target.value === '') {
                            resetField(CONTRIBUTION_KEY);
                        }
                        field.onChange(e);
                    }, placeholder: 'Type here...' })) })] })));
};
