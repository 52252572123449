var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Flex, IconButton, Tooltip, useDisclosure, } from '@chakra-ui/react';
import { DeleteModal } from '@frontend/design-system/components/DeleteModal/DeleteModal';
import { useTableAccordion } from '@frontend/design-system/components/Table/TableAccordionContext';
import { InformationTab } from '@frontend/design-system/components/Tabs/InformationTabPanel';
import { Tabs } from '@frontend/design-system/components/Tabs/Tabs';
import { CriteriaType, ProjectRelationshipType, } from '@frontend/domain/models/Projects/ProjectsInterfaces';
import { toast } from '@frontend/domain/ToastContainer';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { BiSolidTrashAlt } from 'react-icons/bi';
import { MdEdit, MdLock } from 'react-icons/md';
import { appColors, opacityHex, PriorityCode } from '../../../config/constants';
import { calcAssetConditionAverage, doAnyAssetsHaveCondition, getPriorityInfo, handleInformationTabSubmit, roundToTenth, } from '../../../config/CPHelperFunctions';
import { convertIProjectDetailsToProjectUpdateForm } from '../../../contexts/Projects/ProjectsConversions';
import { AssociationsTab } from '../ProjectTabs/Associations/AssociationsTab';
import { AttachmentsTab } from '../ProjectTabs/AttachmentsTab';
import { EnvironmentalTab } from '../ProjectTabs/EnvironmentalTab';
import { GeneralInfoTab } from '../ProjectTabs/GeneralInfoTab';
import { SavingsTab } from '../ProjectTabs/Savings/SavingsTab';
import { variants } from '@frontend/design-system/theme/theme';
const PROJECT_PRIORITIES_KEY = 'priorities';
export const projectRelationshipWarning = 'This project has other required projects. Please check project';
export const ProjectDetails = ({ orgIdentity, canEdit, project, updateProject, assets, isProjectLoading, isLoadingAssetLocations, fundingTypes, showAgeAdjustedCondition, isProjectPage, deleteProject, index, }) => {
    var _a;
    const [editMode, setEditMode] = useState(false);
    const { togglePanel } = useTableAccordion();
    const [colorCode, setColorCode] = useState('');
    const [priorityCode, setPriorityCode] = useState(PriorityCode.NEEDS_ACTION);
    const initAssignedAssets = (_a = project === null || project === void 0 ? void 0 : project.assetIdentities) !== null && _a !== void 0 ? _a : [];
    const [assignedAssetIds, setAssignedAssetIds] = useState(initAssignedAssets);
    const [projectFiles, setProjectFiles] = useState([
        ...project.supportingFiles,
    ]);
    const [newlyAddedFiles, setNewlyAddedFiles] = useState([]);
    // TODO: Investigate into avoiding having separate state management for project files
    useEffect(() => {
        setProjectFiles(project.supportingFiles);
    }, [project.supportingFiles.length]);
    const projectUseForm = useForm({
        values: convertIProjectDetailsToProjectUpdateForm(project),
        mode: 'onChange',
    });
    const { register, handleSubmit, formState: { errors }, watch, reset, setValue, setError, clearErrors, } = projectUseForm;
    // This will cause additional renders on any project refetch
    useEffect(() => {
        if (project) {
            reset(convertIProjectDetailsToProjectUpdateForm(project));
        }
    }, [project]);
    const deleteProjectDisclosure = useDisclosure();
    const hasValidCondition = (assetIds) => {
        const assignedAssets = assets.filter((asset) => assetIds.includes(asset.identity));
        return doAnyAssetsHaveCondition(assignedAssets, showAgeAdjustedCondition);
    };
    // assumes at most one Condition Criteria in the criteria scores
    const conditionScoreIndex = project.criteriaScores.findIndex((criteria) => criteria.type === CriteriaType.Condition);
    const [isManualConditionScore, setIsManualConditionScore] = useState(!hasValidCondition(initAssignedAssets));
    /** Project Details orchestration on update of assigned assets
     * Update list of assets
     * Update condition criteria score to either calculated value or manual value
     */
    const onUpdateAssignedAssets = (newAssets) => {
        var _a;
        const hasValidConditions = hasValidCondition(newAssets);
        if (conditionScoreIndex >= 0) {
            const scoreFieldName = `${PROJECT_PRIORITIES_KEY}.${conditionScoreIndex}.score`;
            if (hasValidConditions) {
                const score = calculateAverageCondition(newAssets);
                setValue(scoreFieldName, score.toString());
                setIsManualConditionScore(false);
            }
            else {
                // if criteria score was previously calculated
                if (!isManualConditionScore) {
                    // if the project's current saved score is manual keep it
                    if (!hasValidCondition(initAssignedAssets)) {
                        setValue(scoreFieldName, ((_a = project.criteriaScores[conditionScoreIndex].score) === null || _a === void 0 ? void 0 : _a.toString()) ||
                            '');
                    }
                    else {
                        setValue(scoreFieldName, '');
                    }
                }
                setIsManualConditionScore(true);
            }
        }
        setAssignedAssetIds(newAssets);
    };
    const calculateAverageCondition = (assetIds) => {
        const assetsToAdd = assets.filter((asset) => assetIds.find((id) => id === asset.identity));
        const average = calcAssetConditionAverage(assetsToAdd, showAgeAdjustedCondition);
        return average;
    };
    const displayLockedScenariosList = () => {
        return (_jsxs(Box, { children: ["This project cannot be fully edited because it is part of:", _jsx("ul", Object.assign({ style: { paddingLeft: '25px' } }, { children: (project === null || project === void 0 ? void 0 : project.scenarios) &&
                        project.scenarios.map((sc, i) => {
                            if (sc.isLocked) {
                                return _jsx("li", { children: sc.scenarioName }, i);
                            }
                        }) }))] }));
    };
    const calculateAvgScore = () => {
        var _a;
        return ((_a = project === null || project === void 0 ? void 0 : project.criteriaScores) !== null && _a !== void 0 ? _a : []).reduce((acc, criteria) => {
            var _a;
            return acc + ((_a = criteria.score) !== null && _a !== void 0 ? _a : 0 * criteria.weight);
        }, 0);
    };
    const [priorityScoreAverage, setPriorityScoreAverage] = useState(calculateAvgScore());
    /** Calculate priority average in flight */
    useEffect(() => {
        let weightedScore = 0;
        let allScored = true;
        project.criteriaScores.forEach((criteria, i) => {
            const scoreFieldName = `${PROJECT_PRIORITIES_KEY}.${i}.score`;
            const score = watch(scoreFieldName);
            if (score === '' || score === null) {
                allScored = false;
            }
            weightedScore += Number(score) * criteria.weight;
        });
        if (isNaN(weightedScore)) {
            return;
        }
        const sum = roundToTenth(weightedScore);
        setPriorityScoreAverage(sum);
        const priorityInfo = getPriorityInfo(allScored ? sum : undefined);
        setPriorityCode(priorityInfo.enumCode);
        setColorCode(allScored
            ? `${priorityInfo.color}${opacityHex.thirty}`
            : priorityInfo.color);
    });
    const addFilesToProject = (newFiles) => {
        setNewlyAddedFiles((prevAddedFiles) => {
            return [
                ...prevAddedFiles,
                ...newFiles.map((newFile) => ({
                    name: newFile.name,
                    file: newFile,
                })),
            ];
        });
        setProjectFiles((prevProjectFiles) => {
            return [
                ...prevProjectFiles,
                ...newFiles.map((newFile) => ({
                    name: newFile.name,
                    file: newFile,
                })),
            ];
        });
    };
    const removeFileFromProject = (fileToRemove) => {
        const projectFilesFiltered = projectFiles.filter((file) => file.name !== fileToRemove.name);
        const newlyAddedFilesFiltered = newlyAddedFiles.filter((file) => file.name !== fileToRemove.name);
        setProjectFiles(projectFilesFiltered);
        setNewlyAddedFiles(newlyAddedFilesFiltered);
    };
    const clearEmptyDollarSavings = (savings) => {
        return savings.filter((saving) => saving.saving !== '' && saving.type !== '' && saving.duration !== '');
    };
    const clearEmptyRebates = (rebates) => {
        const cleanedRebates = [...rebates];
        return cleanedRebates.filter((rebate) => rebate.amount !== '' &&
            rebate.program !== '' &&
            rebate.expirationDate !== '');
    };
    const onSubmit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        data.assets = assignedAssetIds;
        data.files = projectFiles;
        if (data.dollarSavings) {
            data.dollarSavings = clearEmptyDollarSavings(data.dollarSavings);
        }
        if (data.rebates) {
            data.rebates = clearEmptyRebates(data.rebates);
        }
        /** Only send manual scores to platformAPI they perform condition criteria calculation */
        if (!isManualConditionScore &&
            data.priorities &&
            conditionScoreIndex >= 0) {
            data.priorities.splice(conditionScoreIndex, 1);
        }
        yield updateProject(project.facilityIdentity, data)
            .then(() => {
            setEditMode(false);
            setNewlyAddedFiles([]);
        })
            .catch(() => {
            setProjectFiles(project.supportingFiles);
            setNewlyAddedFiles([]);
        });
    });
    const showAssociationToasts = (projectAssociations) => {
        for (const projectAssociation of projectAssociations) {
            if ([
                ProjectRelationshipType.INTERDEPENDENT,
                ProjectRelationshipType.REQUIRES,
            ].includes(projectAssociation.relationshipType)) {
                // using standalone toast from domain to avoid overlap in toast display
                toast({
                    isClosable: true,
                    status: 'warning',
                    duration: null,
                    description: `${projectRelationshipWarning} ${projectAssociation.projectName}`,
                });
            }
        }
    };
    const onDeleteProject = () => __awaiter(void 0, void 0, void 0, function* () {
        yield deleteProject(project.identity)
            .then(() => {
            showAssociationToasts(project.relatedProjects);
        })
            .catch((err) => console.error(`An error occurred when deleting project: ${project.name}`, err));
        index && togglePanel(index);
    });
    const onCancel = () => {
        clearErrors();
        reset(convertIProjectDetailsToProjectUpdateForm(project));
        setProjectFiles([...project.supportingFiles]);
        setNewlyAddedFiles([]);
        setAssignedAssetIds(initAssignedAssets);
    };
    const tabData = [
        {
            column: 'General Information',
            nestedComponent: (_jsx(GeneralInfoTab, { project: project, projectUseForm: projectUseForm, editMode: editMode, fundingTypes: fundingTypes, isCriteriaTypeConditionLocked: hasValidCondition(assignedAssetIds), priorityScoreAverage: priorityScoreAverage, priorityCode: priorityCode, colorCode: colorCode }, `${project.identity}`)),
        },
        {
            column: 'Environmental',
            nestedComponent: (_jsx(EnvironmentalTab, { project: project, projectUseForm: projectUseForm, editMode: editMode })),
        },
        {
            column: 'Savings',
            nestedComponent: (_jsx(SavingsTab, { project: project, projectUseForm: projectUseForm, editMode: editMode })),
        },
        {
            column: 'Associated Assets & Projects',
            nestedComponent: (_jsx(AssociationsTab, { orgIdentity: orgIdentity, facilityName: project.facilityName, facilityIdentity: project.facilityIdentity, siteName: project.siteName, assets: assets, assignedAssetIds: assignedAssetIds, updateAssignedAssets: onUpdateAssignedAssets, editMode: editMode, showAgeAdjustedCondition: showAgeAdjustedCondition, projectUseForm: projectUseForm, isLoadingAssetLocations: isLoadingAssetLocations, project: project })),
        },
        {
            column: 'Attachments',
            nestedComponent: (_jsx(AttachmentsTab, { projectFiles: projectFiles, editMode: editMode, register: register, errors: errors, setError: setError, clearErrors: clearErrors, addFilesToProject: addFilesToProject, removeFileFromProject: removeFileFromProject, newlyAddedFiles: newlyAddedFiles })),
        },
    ];
    const bgColor = (project === null || project === void 0 ? void 0 : project.isLocked) ? appColors.TERCIARY_LIGHT_GRAY : '';
    return (_jsxs(InformationTab.PanelProvider, Object.assign({ onSubmit: handleInformationTabSubmit(handleSubmit, onSubmit), isEditMode: editMode, noValidate: true }, { children: [_jsxs(Flex, Object.assign({ w: '100%', justifyContent: 'space-between', alignItems: 'center' }, { children: [_jsx(Flex, { children: project.isLocked && (_jsxs(Flex, Object.assign({ "data-testid": 'locked', pb: '1em', gap: '1em' }, { children: [_jsx(MdLock, { color: appColors.PRIM_BLUE, fontSize: '1.5rem' }), _jsx(Tooltip, Object.assign({ label: displayLockedScenariosList() }, { children: _jsx("span", { children: _jsx(AiOutlineInfoCircle, { fontSize: '1.5rem' }) }) }))] }))) }), _jsx(Flex, Object.assign({ pb: '1em', justifyContent: 'flex-end', alignItems: 'center' }, { children: editMode ? (_jsxs(_Fragment, { children: [_jsx(Box, Object.assign({ ml: '1em' }, { children: _jsx(Button, Object.assign({ variant: variants.blueOutlineBtn, onClick: () => {
                                            setEditMode(false);
                                            onCancel();
                                        } }, { children: "Cancel" })) })), _jsx(Box, Object.assign({ ml: '1em' }, { children: _jsx(Button, Object.assign({ variant: variants.cpBlueBtn, type: 'submit', isDisabled: Object.keys(errors).length || isProjectLoading
                                            ? true
                                            : false, "data-testid": 'save-project-updates' }, { children: "Save" })) }))] })) : (canEdit && (_jsxs(Box, Object.assign({ ml: '1em' }, { children: [!project.isLocked && isProjectPage && (_jsx(IconButton, { w: '70px', mr: '15px', onClick: () => deleteProjectDisclosure.onOpen(), variant: variants.redOutlineBtn, icon: _jsx(BiSolidTrashAlt, {}), "aria-label": 'delete-project' })), _jsx(Button, Object.assign({ variant: variants.blueOutlineBtn, leftIcon: _jsx(MdEdit, {}), onClick: () => {
                                        setEditMode(!editMode);
                                    } }, { children: "Edit" }))] })))) }))] })), _jsx(Tabs, { tabs: tabData, style: { backgroundColor: bgColor } }), _jsx(DeleteModal, { disclosure: deleteProjectDisclosure, onDelete: onDeleteProject, text: 'You are about to permanently delete this project. This action cannot be reversed.' })] })));
};
