import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { HStack, IconButton } from '@chakra-ui/react';
import React, { useState } from 'react';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
export const useCarousel = (items, numItemsViewable = 4) => {
    const [itemIndex, setItemIndex] = useState(0);
    const [viewingIndex, setViewingIndex] = useState(0);
    const [viewingRange, setViewingRange] = useState({
        start: 0,
        end: numItemsViewable,
    });
    const next = () => {
        if (itemIndex < items.length - 1) {
            if (viewingIndex + 1 >= numItemsViewable) {
                setViewingRange({
                    start: viewingRange.start + 1,
                    end: viewingRange.end + 1,
                });
                setItemIndex(itemIndex + 1);
            }
            else {
                setViewingIndex(viewingIndex + 1);
                setItemIndex(itemIndex + 1);
            }
        }
    };
    const prev = () => {
        if (itemIndex > 0) {
            if (viewingIndex - 1 < 0) {
                setViewingRange({
                    start: viewingRange.start - 1,
                    end: viewingRange.end - 1,
                });
                setItemIndex(itemIndex - 1);
            }
            else {
                setViewingIndex(viewingIndex - 1);
                setItemIndex(itemIndex - 1);
            }
        }
    };
    // sets both the item and viewingIndex on selection of viewingIndex
    const setIndex = (viewingIndex) => {
        const itemIndex = viewingRange.start + viewingIndex;
        setViewingIndex(viewingIndex);
        setItemIndex(itemIndex);
    };
    const currentItems = items.slice(viewingRange.start, viewingRange.end);
    return {
        currentItems,
        next,
        prev,
        canGoNext: itemIndex < items.length - 1,
        canGoPrev: itemIndex > 0,
        setIndex: setIndex,
        index: viewingIndex,
    };
};
export const Carousel = ({ carouselContext, renderItem, iconStyleVariant, }) => {
    const { currentItems, next, prev, canGoNext, canGoPrev } = carouselContext;
    return (_jsxs(HStack, Object.assign({ w: '100%', justify: 'space-between' }, { children: [_jsx(IconButton, { variant: iconStyleVariant || 'ghost', borderRadius: 0, "aria-label": 'previous item', icon: _jsx(MdChevronLeft, {}), onClick: prev, isDisabled: !canGoPrev }), _jsx(HStack, Object.assign({ w: '100%', h: '100%', justify: 'space-between' }, { children: currentItems.map((item, index) => (_jsx(HStack, Object.assign({ w: '100%', h: '100%', justify: 'space-between' }, { children: renderItem(item, index) })))) })), _jsx(IconButton, { variant: iconStyleVariant || 'ghost', borderRadius: 0, "aria-label": 'next item', icon: _jsx(MdChevronRight, {}), onClick: next, isDisabled: !canGoNext })] })));
};
