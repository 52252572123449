import { toFacilityRole, toFriendlyFacilityRole, toOrgRoleEnum, toSystemRoleEnum, } from '../../conversion/roles';
import { IAppNames } from '../App';
import { OrganizationRoleEnum, } from '../Roles';
export const convertOrgContactToIUserContact = (user) => {
    return {
        identity: user.userIdentity,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.emailAddress,
        phoneNumber: user.phoneNumber || undefined,
        jobTitle: user.jobTitle || undefined,
    };
};
export const convertUserProfileResponseToIUser = (user) => {
    var _a, _b, _c;
    if (user.organizationRoles === null ||
        user.organizationRoles === undefined) {
        console.error('User organization roles is missing', user);
        throw new Error('User organization roles is missing');
    }
    if (!user.systemRole) {
        console.error('User system role is missing', user);
        throw new Error('User system role is missing');
    }
    return {
        identity: user.userIdentity,
        deprecatedId: user.userId,
        emailAddress: user.emailAddress,
        systemRole: toSystemRoleEnum(user.systemRole),
        firstName: user.firstName,
        lastName: user.lastName,
        isEulaAccepted: (_a = user.isEulaAccepted) !== null && _a !== void 0 ? _a : false,
        isActive: user.isActive,
        phoneNumber: (_b = user.phoneNumber) !== null && _b !== void 0 ? _b : undefined,
        jobTitle: (_c = user.jobTitle) !== null && _c !== void 0 ? _c : undefined,
        iconFileName: user.iconUrl,
        appAccess: user.apps.map(appToAccess),
        organizationRoles: user.organizationRoles.map(roleToOrgs),
        facilityExceptions: user.facilityRoles.map(roleToFacility),
        primaryContactOrganizations: user.primaryContactOrganizations.map(roleToPrimaryContact),
    };
};
function roleToOrgs(role) {
    var _a;
    if (!role.organizationIdentity) {
        console.error('User organization identity is missing', role);
        throw new Error('User organization identity is missing');
    }
    if (!role.role) {
        console.error('User organization role is missing', role);
        throw new Error('User organization role is missing');
    }
    return {
        organizationIdentity: role.organizationIdentity,
        role: toOrgRoleEnum(role.role),
        name: (_a = role.organizationName) !== null && _a !== void 0 ? _a : 'Unknown organization name',
    };
}
function orgToRole(role) {
    if (role.role === OrganizationRoleEnum.NONE) {
        console.error('User organization role is missing', role);
        throw new Error('User organization role is missing');
    }
    return {
        organizationIdentity: role.organizationIdentity,
        role: role.role.toString(),
    };
}
function roleToFacility(role) {
    if (!role.facilityIdentity) {
        console.error('User organization identity is missing', role);
        throw new Error('User organization identity is missing');
    }
    if (!role.role) {
        console.error('User organization role is missing', role);
        throw new Error('User organization role is missing');
    }
    return {
        facilityIdentity: role.facilityIdentity,
        role: toFriendlyFacilityRole(role.role),
    };
}
function facilityToRole(role) {
    if (role.role === 'None') {
        console.error('User organization role is missing', role);
        throw new Error('User organization role is missing');
    }
    return {
        facilityIdentity: role.facilityIdentity,
        role: toFacilityRole(role.role),
    };
}
function roleToPrimaryContact(response) {
    return {
        identity: response.organizationId,
        name: response.organizationName,
    };
}
function appToAccess(response) {
    const isValidEnum = Object.values(IAppNames).includes(response.app);
    if (!isValidEnum)
        console.warn('Invalid App Name:', response.app);
    return {
        deprecatedId: response.organizationId,
        name: response.app,
        organizationIdentity: response.organizationIdentity,
    };
}
function accessToApp(response) {
    return {
        app: response.name,
        organizationId: response.deprecatedId,
        organizationIdentity: response.organizationIdentity,
        unassign: response.unassign || false,
    };
}
export const convertIUserToUserProfileRequest = (user) => {
    return {
        userId: user.identity,
        systemRole: user.systemRole.toString(),
        firstName: user.firstName,
        lastName: user.lastName,
        phoneNumber: user.phoneNumber,
        jobTitle: user.jobTitle,
        isEulaAccepted: user.isEulaAccepted,
        iconFileName: user.iconFileName !== '' ? user.iconFileName : null,
        organizationRoles: user.organizationRoles.map(orgToRole),
        facilityRoles: user.facilityExceptions.map(facilityToRole),
        apps: user.appAccess.map(accessToApp),
    };
};
export const convertUserProfileFormToIUser = (profile, user) => {
    return {
        identity: user.identity,
        deprecatedId: user.deprecatedId,
        emailAddress: user.emailAddress,
        firstName: profile.firstName,
        lastName: profile.lastName,
        systemRole: user.systemRole,
        isActive: user.isActive,
        isEulaAccepted: profile.isEulaAccepted,
        phoneNumber: profile.phoneNumber,
        jobTitle: profile.jobTitle,
        iconFileName: user.iconFileName,
        appAccess: [],
        organizationRoles: [],
        facilityExceptions: [],
        primaryContactOrganizations: user.primaryContactOrganizations,
    };
};
