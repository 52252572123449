import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { VStack } from '@chakra-ui/react';
import { AnnualAcreInput } from './Inputs/AnnualAcreInput';
import { MonthlyAnnualInput } from './Inputs/MonthlyAnnualInput';
import { TaxImpactTitleWrapper } from './TaxImpactTitleWrapper';
const ANNUAL_HOME_VALUE_KEY = 'annualHomeValue';
const ANNUAL_TAX_IMPACT_KEY = 'annualTaxImpact';
const MONTHLY_HOME_VALUE_KEY = 'monthlyHomeValue';
const MONTHLY_TAX_IMPACT_KEY = 'monthlyTaxImpact';
export const TaxImpactSection = ({ formContext, taxImpactExists, }) => {
    return (_jsx(TaxImpactTitleWrapper, Object.assign({ title: 'Tax Impact' }, { children: _jsxs(VStack, Object.assign({ w: '100%', spacing: 5 }, { children: [_jsx(MonthlyAnnualInput, { taxImpactExists: taxImpactExists, formContext: formContext, dropdownFormKey: ANNUAL_HOME_VALUE_KEY, currencyFormKey: ANNUAL_TAX_IMPACT_KEY, beforeText: 'Annual' }), _jsx(MonthlyAnnualInput, { taxImpactExists: taxImpactExists, formContext: formContext, dropdownFormKey: MONTHLY_HOME_VALUE_KEY, currencyFormKey: MONTHLY_TAX_IMPACT_KEY, beforeText: 'Monthly' }), _jsx(AnnualAcreInput, { taxImpactExists: taxImpactExists, formContext: formContext })] })) })));
};
