import { jsx as _jsx } from "react/jsx-runtime";
import { Stack } from '@chakra-ui/react';
import React from 'react';
import { FundingTypeInput } from './FundingTypeInput';
export const formatBudgetTotal = (total) => {
    return total.toLocaleString('en-US');
};
export const FundingTypeInputs = ({ formContext, fields, updateFundingType, }) => {
    return (_jsx(Stack, Object.assign({ w: '100%', h: '400px', gap: 3, overflowY: 'auto' }, { children: fields.map((field, index) => (_jsx(FundingTypeInput, { formContext: formContext, fundingTypeBudget: field, index: index, updateOnChange: updateFundingType }, field.id))) })));
};
