import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Box, Flex, Menu, MenuButton, MenuList, MenuItem, Text, } from '@chakra-ui/react';
import { BiChevronDown } from 'react-icons/bi';
const CustomSelect = ({ options, onSelectOption, renderSelection, renderOption, defaultOption, }) => {
    const [selectedOption, setSelectedOption] = useState(defaultOption);
    useEffect(() => {
        setSelectedOption(defaultOption);
    }, [defaultOption]);
    const onSelectHandler = (option) => {
        onSelectOption(option);
        setSelectedOption(option);
    };
    return (_jsxs(Menu, { children: [_jsx(MenuButton, Object.assign({ as: Box, borderWidth: '1px', borderRadius: 'md', paddingY: 2, paddingX: 3, cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }, { children: _jsxs(Box, Object.assign({ display: 'flex', alignItems: 'center', gap: 2 }, { children: [selectedOption ? (renderSelection ? (renderSelection(selectedOption)) : (_jsx(Flex, Object.assign({ alignItems: 'center', flex: '1', gap: 2 }, { children: _jsx(Text, Object.assign({ noOfLines: 1 }, { children: selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.name })) })))) : ('Select option'), _jsx(BiChevronDown, {})] })) })), _jsx(MenuList, { children: options.map((option) => (_jsx(MenuItem, Object.assign({ onClick: () => onSelectHandler(option) }, { children: renderOption ? (renderOption(option)) : (_jsx(Flex, Object.assign({ alignItems: 'center', flex: '1', gap: 2 }, { children: _jsx(Text, Object.assign({ noOfLines: 1 }, { children: option === null || option === void 0 ? void 0 : option.name })) }))) }), option.identity))) })] }));
};
export default CustomSelect;
