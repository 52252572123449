export class AssessmentService {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Triggers a refresh of assessment reporting data for the given organization.
     * @param organizationId The ID of the organization to refresh
     * @returns any OK
     * @throws ApiError
     */
    postApiV1AssessmentRefresh(organizationId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/Assessment/refresh/{organizationId}',
            path: {
                organizationId: organizationId,
            },
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Retrieves asset classes based on provided query string filters.
     * @param spaceCategory The space category to filter by (ex. Facility, Grounds)
     * @param isStandard Whether to pull asset classes for standard or non-standard assets
     * @returns AssetClassResponse OK
     * @throws ApiError
     */
    getApiV1AssessmentLoadAsset(spaceCategory, isStandard) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/Assessment/load/asset/{spaceCategory}',
            path: {
                spaceCategory: spaceCategory,
            },
            query: {
                isStandard: isStandard,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }
    /**
     * Retrieves space types based on provided query string filters.
     * @param spaceCategory The space category to filter by (ex. Facility, Grounds)
     * @param organizationId ID of the organization the request is being made for
     * @returns SpaceTypeResponse OK
     * @throws ApiError
     */
    getApiV1AssessmentLoadSpace(spaceCategory, organizationId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/Assessment/load/space/{spaceCategory}/{organizationId}',
            path: {
                spaceCategory: spaceCategory,
                organizationId: organizationId,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }
    /**
     * Retrieves data necessary for mapping spaces, assets, and their assessments
     * @param floorPlanId The ID of the floor plan to load map data for
     * @returns MapSpaceResponse OK
     * @throws ApiError
     */
    getApiV1AssessmentMap(floorPlanId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/Assessment/map/{floorPlanId}',
            path: {
                floorPlanId: floorPlanId,
            },
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
}
