const containerName = process.env.REACT_APP_FCA_STORAGE_CONTAINER;
const storageAccountName = 'mysiteiqstorage';
const BLOB_STORE_BASE_URL = `https://${storageAccountName}.blob.core.windows.net`;
export const BLOB_STORE_CONTAINER_URL = `${BLOB_STORE_BASE_URL}/${containerName}`;
export const toCleanFileName = (fileName) => {
    const splittedFileName = fileName.split('.');
    const extension = splittedFileName.pop();
    const cleanedName = splittedFileName
        .join('.')
        .replace(/[!*'();:@&=+$,/?%#[\].\\]+|\s+/g, '-');
    return cleanedName + '.' + extension;
};
export const getFileExtension = (fileName) => {
    return fileName.split('.').pop() || '';
};
export const createFileWithUpdatedName = (file, newName) => {
    return new File([file], newName, {
        type: file.type,
        lastModified: file.lastModified,
    });
};
const toContainerPath = (fileName, 
// eslint-disable-next-line unused-imports/no-unused-vars
orgIdentity, type) => {
    // orgIdentity is not needed until we migrate photos to use org folders
    // when reimplementing, add orgIdentity/ before the type
    // leaving orgIdentity in toContainerPath params because the work is already done to utilize it; leaving it allows the only change needing to be done here
    const containerPath = `${type ? type + '/' : ''}${fileName}`;
    return containerPath;
};
export const toBlobStoreContainerUrl = (fileName, orgIdentity) => {
    const resultUrl = `${BLOB_STORE_CONTAINER_URL}/${toContainerPath(fileName, orgIdentity, 'compressed')}`;
    return resultUrl;
};
/**
 * This goes through a list of files and makes sure to increment
 * the new file's name if there are duplicate files in the existing
 * files.
 *
 * File increment format: fileName_number.jpg
 *
 * @param files
 * @param fileName
 * @returns
 */
export const incrementFileName = (files, fileName) => {
    const [baseName, fileExtension] = fileName.split('.');
    // Regex to match files that follow the format baseName_number.extension
    const fileRegex = new RegExp(`${baseName}_(\\d+)\\.${fileExtension}$`);
    // Start by assuming the next available number is 1
    let maxFileNumber = 0;
    // Reduce over the files array to find the highest number for duplicate file names
    const hasExactMatch = files.reduce((foundExactMatch, file) => {
        // If the file name matches exactly, we should start at 1 (file_1.ext)
        if (file.name === fileName) {
            maxFileNumber = Math.max(maxFileNumber, 1);
            return true;
        }
        // If the file matches the regex pattern, extract the number and compare
        const match = file.name.match(fileRegex);
        if (match) {
            const fileNumber = parseInt(match[1], 10); // Extract the number from the match
            maxFileNumber = Math.max(maxFileNumber, fileNumber + 1);
        }
        return foundExactMatch; // Keep track of whether we found an exact match
    }, false);
    return hasExactMatch || maxFileNumber > 0
        ? `${baseName}_${maxFileNumber}.${fileExtension}`
        : fileName;
};
