import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Flex, HStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Layer, Stage } from 'react-konva';
import { appValues } from '../../theme/constants';
import { DrawTypeButton } from './buttons/DrawTypeButton';
import { KonvaImage } from './KonvaImage';
import { StaticFloorPlanShapesLayer } from './layers/StaticFloorPlanShapesLayer';
import { DrawPolygonShape } from './shapes/DrawPolygonShape';
import { DrawRectangleShape } from './shapes/DrawRectangleShape';
import { EditablePin } from './shapes/EditablePin';
import { EditableShape } from './shapes/EditableShape';
export var CanvasMode;
(function (CanvasMode) {
    CanvasMode[CanvasMode["VIEW"] = 0] = "VIEW";
    CanvasMode[CanvasMode["DRAW_SPACE_LOCATION"] = 1] = "DRAW_SPACE_LOCATION";
    CanvasMode[CanvasMode["DRAW_PIN"] = 2] = "DRAW_PIN";
    CanvasMode[CanvasMode["EDIT_SPACE_LOCATION"] = 3] = "EDIT_SPACE_LOCATION";
})(CanvasMode || (CanvasMode = {}));
const imageMultiplier = 100;
const imageFillWidth = 11 * imageMultiplier;
const imageFillDefaultHeight = 8.54 * imageMultiplier;
export const FloorPlanCanvas = ({ canvasMode = CanvasMode.VIEW, selectedPin, selectedShape, planImageUrl, isViewOnly = true, drawShape = [], drawPin, pins, shapes, splitShapes, isPinSelected, onSelectShape, onSetDrawPin, onUpdateShape, containerWidth, }) => {
    const [stageScale, setStageScale] = useState(1);
    const [stageX, setStageX] = useState(0);
    const [stageY, setStageY] = useState(0);
    const [zoomLevel, setZoomLevel] = useState(0);
    const [isRectangleDraw, setIsRectangleDraw] = useState(true);
    const [stageHeight, setStageHeight] = useState(document.documentElement.clientHeight - appValues.HEADER_HEIGHT_SMALL);
    const pointSize = 10 - zoomLevel > 4 ? 10 - zoomLevel : 4;
    const closeShapeDist = 20 - zoomLevel > 5 ? 20 - zoomLevel : 5;
    /* On submit these need to be cleared out */
    // hold temporary shape location information
    const [rectangle, setRectangle] = useState();
    const [tempPoints, setTempPoints] = useState([]);
    const clearDrawingStateVariables = () => {
        onSetDrawPin === null || onSetDrawPin === void 0 ? void 0 : onSetDrawPin(undefined);
        onUpdateShape === null || onUpdateShape === void 0 ? void 0 : onUpdateShape([]);
        setTempPoints([]);
        setRectangle(undefined);
    };
    useEffect(() => {
        if (selectedShape && canvasMode === CanvasMode.EDIT_SPACE_LOCATION) {
            setTempPoints(selectedShape.shape);
            onUpdateShape === null || onUpdateShape === void 0 ? void 0 : onUpdateShape(selectedShape.shape);
        }
        else {
            clearDrawingStateVariables();
        }
    }, [canvasMode]);
    useEffect(() => {
        // documentElement.clientHeight does not update on window resize, so it needs to be tracked through the event listener
        // Initial call
        setFloorPlanHeight();
        window.addEventListener('resize', () => 
        // Setting timeout to ensure screen size has recaclulated after rotation before setting height
        setTimeout(() => {
            setFloorPlanHeight();
        }, 100));
        return () => {
            window.removeEventListener('resize', setFloorPlanHeight);
        };
    }, []);
    const isIpad = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window['opera'];
        // iPads on iOS 13+ (including iPadOS) use a different user agent pattern
        const isIOS13Plus = /Macintosh/.test(userAgent) && 'ontouchend' in document;
        // iPads on iOS 12 and below
        const isOldIPad = /iPad/.test(userAgent);
        return isIOS13Plus || isOldIPad;
    };
    const setFloorPlanHeight = () => {
        // Use documentElement.clientHeight to get the container's true height, not the parent element's height.
        // This ensures the entire map is visible without any part being cut off.
        //  the height of the browser window, including toolbars, menus, and the window borders
        const outerHeight = window.outerHeight;
        //  height of the viewport, including scrollbars and excluding toolbars, menus, borders
        const innerHeight = window.innerHeight;
        const toolbarHeight = outerHeight - innerHeight;
        const ipad = isIpad();
        const heightWithoutScrollbars = ipad
            ? document.documentElement.clientHeight +
                toolbarHeight -
                appValues.HEADER_HEIGHT_SMALL
            : document.documentElement.clientHeight -
                appValues.HEADER_HEIGHT_SMALL;
        setStageHeight(heightWithoutScrollbars);
    };
    // Move to state or remove from scope reference
    let lastCenter = null;
    let lastDist = 0;
    const updateShape = (shape) => {
        onUpdateShape === null || onUpdateShape === void 0 ? void 0 : onUpdateShape(shape);
    };
    const getCenter = (p1, p2) => {
        return {
            x: (p1.x + p2.x) / 2,
            y: (p1.y + p2.y) / 2,
        };
    };
    const getDistance = (p1, p2) => {
        return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
    };
    const editPinHandleMouseDown = (e) => {
        const stage = e.target.getStage();
        const scale = stage.scaleX();
        const pos = e.target.getStage().getPointerPosition();
        const newPin = {
            x: Math.round(pos.x / scale - stage.x() / scale),
            y: Math.round(pos.y / scale - stage.y() / scale),
        };
        onSetDrawPin === null || onSetDrawPin === void 0 ? void 0 : onSetDrawPin(Object.assign({}, newPin));
        // TODO: update provider with pin on submit
    };
    /* Rectangle Shape Mouse Actions */
    // reuse for touch start
    const rectangleShapeMouseDown = (e) => {
        if (drawShape.length > 0) {
            onUpdateShape === null || onUpdateShape === void 0 ? void 0 : onUpdateShape([]);
        }
        const stage = e.target.getStage();
        const scale = stage.scaleX();
        const pos = e.target.getStage().getPointerPosition();
        const point = {
            x: pos.x / scale - stage.x() / scale,
            y: pos.y / scale - stage.y() / scale,
        };
        const x = point.x;
        const y = point.y;
        setRectangle({ x, y, width: 0, height: 0 });
    };
    //reuse for touch end
    const rectangleShapeMouseUp = (e) => {
        const stage = e.target.getStage();
        const scale = stage.scaleX();
        if (rectangle) {
            const pos = e.target.getStage().getPointerPosition();
            const x = pos.x / scale - stage.x() / scale;
            const y = pos.y / scale - stage.y() / scale;
            const firstPoint = {
                x: rectangle.x,
                y: rectangle.y,
                pointIndex: 0,
            };
            const secondPoint = {
                x: x,
                y: rectangle.y,
                pointIndex: 1,
            };
            const thirdPoint = {
                x: x,
                y: y,
                pointIndex: 2,
            };
            const fourthPoint = {
                x: rectangle.x,
                y: y,
                pointIndex: 3,
            };
            const rectanglePoints = [
                firstPoint,
                secondPoint,
                thirdPoint,
                fourthPoint,
            ];
            onUpdateShape === null || onUpdateShape === void 0 ? void 0 : onUpdateShape(rectanglePoints);
        }
    };
    const rectangleShapeMouseMove = (e) => {
        if (drawShape.length === 0 && rectangle) {
            const stage = e.target.getStage();
            const scale = stage.scaleX();
            const pos = e.target.getStage().getPointerPosition();
            const x = pos.x / scale - stage.x() / scale;
            const y = pos.y / scale - stage.y() / scale;
            setRectangle(Object.assign(Object.assign({}, rectangle), { width: x - rectangle.x, height: y - rectangle.y }));
        }
        lastCenter = null;
        lastDist = 0;
    };
    /* Polygon Shape Mouse Actions */
    const polygonShapeMouseDown = (e) => {
        const stage = e.target.getStage();
        const scale = stage.scaleX();
        const pos = e.target.getStage().getPointerPosition();
        const point = {
            x: pos.x / scale - stage.x() / scale,
            y: pos.y / scale - stage.y() / scale,
            pointIndex: tempPoints ? tempPoints.length : 0,
        };
        // if the shape is already defined reset shape to redraw on mouse down
        if (drawShape.length > 0) {
            onUpdateShape === null || onUpdateShape === void 0 ? void 0 : onUpdateShape([]);
        }
        if (tempPoints.length === 0) {
            setTempPoints([point]);
        }
        else if (Math.abs(tempPoints[0].x - point.x) < closeShapeDist &&
            Math.abs(tempPoints[0].y - point.y) < closeShapeDist) {
            setTempPoints([]);
            onUpdateShape === null || onUpdateShape === void 0 ? void 0 : onUpdateShape(tempPoints);
        }
        else {
            setTempPoints([...tempPoints, point]);
        }
    };
    /* Global Actions */
    const onTouch = (e) => {
        e.evt.preventDefault();
        const touch1 = e.evt.touches[0];
        const touch2 = e.evt.touches[1];
        const stage = e.target.getStage();
        if (touch1 && touch2) {
            stage.stopDrag();
            const p1 = {
                x: touch1.clientX,
                y: touch1.clientY,
            };
            const p2 = {
                x: touch2.clientX,
                y: touch2.clientY,
            };
            if (!lastCenter) {
                lastCenter = getCenter(p1, p2);
                return;
            }
            const newCenter = getCenter(p1, p2);
            const dist = getDistance(p1, p2);
            if (!lastDist) {
                lastDist = dist;
            }
            // local coordinates of center point
            const pointTo = {
                x: (newCenter.x - stage.x()) / stage.scaleX(),
                y: (newCenter.y - stage.y()) / stage.scaleX(),
            };
            const newScale = stage.scaleX() * (dist / lastDist);
            setZoomLevel(newScale * 2);
            stage.scaleX(newScale);
            stage.scaleY(newScale);
            // calculate new position of the stage
            const dx = newCenter.x - lastCenter.x;
            const dy = newCenter.y - lastCenter.y;
            const newPos = {
                x: newCenter.x - pointTo.x * newScale + dx,
                y: newCenter.y - pointTo.y * newScale + dy,
            };
            stage.position(newPos);
            lastDist = dist;
            lastCenter = newCenter;
            setStageScale(newScale);
        }
    };
    const handleWheel = (e) => {
        e.evt.preventDefault();
        const scaleBy = 0.98;
        const stage = e.target.getStage();
        const oldScale = stage.scaleX();
        setZoomLevel(oldScale * 2);
        const mousePointTo = {
            x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
            y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
        };
        const newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
        setStageScale(newScale);
        setStageX(-(mousePointTo.x - stage.getPointerPosition().x / newScale) *
            newScale);
        setStageY(-(mousePointTo.y - stage.getPointerPosition().y / newScale) *
            newScale);
    };
    const getStageActions = (canvasMode, isRectangleDraw) => {
        if (isViewOnly) {
            return {};
        }
        else if (canvasMode === CanvasMode.DRAW_SPACE_LOCATION) {
            return {
                onMouseDown: isRectangleDraw
                    ? rectangleShapeMouseDown
                    : polygonShapeMouseDown,
                onMouseUp: rectangleShapeMouseUp,
                onMouseMove: rectangleShapeMouseMove,
                onTouchStart: isRectangleDraw
                    ? rectangleShapeMouseDown
                    : polygonShapeMouseDown,
                onTouchEnd: rectangleShapeMouseUp,
                onTouchMove: rectangleShapeMouseMove,
            };
        }
        else if (canvasMode === CanvasMode.DRAW_PIN) {
            return {
                onMouseDown: editPinHandleMouseDown,
                // might be better to use tap instead of touch end
                onTouchEnd: editPinHandleMouseDown,
            };
        }
        else {
            return {
                onTouchMove: onTouch,
                onTouchEnd: () => {
                    // Not sure why this is here
                    lastCenter = null;
                    lastDist = 0;
                },
            };
        }
    };
    const height = containerWidth ? imageFillDefaultHeight : stageHeight;
    const width = containerWidth !== null && containerWidth !== void 0 ? containerWidth : document.documentElement.clientWidth;
    /* UpdateShape Mouse Actions */
    return (_jsxs(Flex, Object.assign({ flexGrow: 1, h: '100%', overflow: 'hidden', position: 'relative', flexDirection: 'column', id: 'floor-plan-flex' }, { children: [_jsx(HStack, Object.assign({ overflow: 'hidden', h: '100%', w: '100%', position: 'relative' }, { children: _jsxs(Stage, Object.assign({}, getStageActions(canvasMode, isRectangleDraw), { width: width, height: height * (width / document.documentElement.clientWidth), onWheel: isViewOnly ? undefined : handleWheel, scaleX: stageScale *
                        (width / document.documentElement.clientWidth), scaleY: stageScale *
                        (width / document.documentElement.clientWidth), x: stageX, y: stageY, draggable: !isViewOnly &&
                        canvasMode !== CanvasMode.DRAW_SPACE_LOCATION, 
                    // The browser errors if we don't have some onDragEnd function since draggable is true.
                    onDragEnd: () => { } }, { children: [_jsx(Layer, { children: planImageUrl && (_jsx(KonvaImage, { url: planImageUrl, fillWidth: imageFillWidth, fillHeight: height *
                                    (width /
                                        document.documentElement.clientWidth) })) }), _jsx(Layer, { children: shapes && (_jsx(StaticFloorPlanShapesLayer, { isPinSelected: isPinSelected, shapes: shapes, splitShapes: splitShapes, pins: pins, scale: stageScale, onSelectShape: onSelectShape })) }), canvasMode === CanvasMode.DRAW_SPACE_LOCATION &&
                            !isViewOnly && (
                        // difference in redraw vs edit due to lines and points needing to be made
                        _jsx(Layer, { children: isRectangleDraw && rectangle ? (_jsx(DrawRectangleShape, { rectangle: rectangle })) : (_jsx(DrawPolygonShape, { pointSize: pointSize, shape: drawShape, tempPoints: tempPoints })) })), drawShape.length > 0 &&
                            !isViewOnly &&
                            canvasMode === CanvasMode.EDIT_SPACE_LOCATION && (_jsx(Layer, Object.assign({ draggable: !isRectangleDraw, onDragEnd: () => { } }, { children: _jsx(EditableShape, { pointSize: pointSize, shape: drawShape, setShape: updateShape }) }))), canvasMode === CanvasMode.DRAW_PIN && !isViewOnly && (_jsx(Layer, { children: _jsx(EditablePin, { pinLocation: drawPin, pinConfig: selectedPin, scale: stageScale }) }))] })) })), canvasMode === CanvasMode.DRAW_SPACE_LOCATION && !isViewOnly && (_jsx(DrawTypeButton, { isRectangleDraw: isRectangleDraw, onRectangleSelect: () => {
                    setIsRectangleDraw(true);
                    clearDrawingStateVariables();
                }, onPolygonSelect: () => {
                    setIsRectangleDraw(false);
                    clearDrawingStateVariables();
                } }))] })));
};
