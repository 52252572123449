var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useCurrentUser } from '@frontend/domain/contexts/User/UserContext';
import { AppPaths } from '@frontend/shared/src/components/Routing/RouteConstants';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ActivateWarning } from './ActivateWarning';
import { OnboardingUserForm } from './OnboardingUserForm';
export const OnboardingUser = ({ children, }) => {
    const navigate = useNavigate();
    const { user, updateUserProfile, status } = useCurrentUser();
    const { isLoading, isActive, isEulaAccepted } = status;
    useEffect(() => {
        if (!isActive) {
            const timer = setTimeout(() => {
                navigate(AppPaths.logout);
            }, 2 * 60 * 1000);
            return () => clearTimeout(timer);
        }
    }, [isActive]);
    const submitUserOnboardingForm = (data) => __awaiter(void 0, void 0, void 0, function* () {
        yield updateUserProfile(data);
    });
    if (isLoading) {
        return _jsx(_Fragment, {});
    }
    if (!isActive) {
        return _jsx(ActivateWarning, {});
    }
    return (_jsx(_Fragment, { children: isEulaAccepted ? (children) : (_jsx(OnboardingUserForm, { isLoading: isLoading, user: user, onSave: submitUserOnboardingForm })) }));
};
