import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { VStack } from '@chakra-ui/react';
import { FundContributionInput } from './Inputs/FundContributionInput';
import { RevenueBondedInput } from './Inputs/RevenueBondedInput';
import { TaxImpactTitleWrapper } from './TaxImpactTitleWrapper';
export const FundContributionSection = ({ formContext, taxImpact, }) => {
    const yearsOptions = [];
    for (let i = 5; i <= 20; i++) {
        yearsOptions.push(_jsx("option", Object.assign({ value: i }, { children: `${i} years` }), i));
    }
    const displayFundContributionSection = () => {
        if (taxImpact &&
            !displayFundContributionInputGroup() &&
            !displayRevenueBondedInputGroup()) {
            return false;
        }
        else {
            return true;
        }
    };
    const displayFundContributionInputGroup = () => {
        if (taxImpact && (!taxImpact.contributionYear || !taxImpact.contribution)) {
            return false;
        }
        else {
            return true;
        }
    };
    const displayRevenueBondedInputGroup = () => {
        if (taxImpact &&
            (!taxImpact.revenueBonded ||
                !taxImpact.revenueBondedPercentage ||
                !taxImpact.revenueBondedPercentageYear)) {
            return false;
        }
        else {
            return true;
        }
    };
    return (_jsx(_Fragment, { children: displayFundContributionSection() && (_jsx(TaxImpactTitleWrapper, Object.assign({ title: 'Fund Contribution' }, { children: _jsxs(VStack, Object.assign({ w: '100%', spacing: 5 }, { children: [displayFundContributionInputGroup() && (_jsx(FundContributionInput, { options: yearsOptions, taxImpactExists: taxImpact !== undefined, formContext: formContext })), displayRevenueBondedInputGroup() && (_jsx(RevenueBondedInput, { options: yearsOptions, taxImpactExists: taxImpact !== undefined, formContext: formContext }))] })) }))) }));
};
