import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useToken } from '@chakra-ui/react';
import { rgbFromRandomString } from '@frontend/design-system/theme/colors';
import { getAvgAnnotation, getGlobalMinMax, } from '@frontend/design-system/utils/chartsUtils';
import { getCurrencyNotation } from '@frontend/design-system/utils/uiUtils';
import { formatBudgetToString } from '@frontend/domain/models/Projects/ProjectsInterfaces';
export var WidgetType;
(function (WidgetType) {
    WidgetType[WidgetType["PERCENTAGE_AND_BUDGET"] = 0] = "PERCENTAGE_AND_BUDGET";
    WidgetType[WidgetType["TOTAL_BUDGET"] = 1] = "TOTAL_BUDGET";
    WidgetType[WidgetType["FUNDING_TYPE"] = 2] = "FUNDING_TYPE";
    WidgetType[WidgetType["FUNDING_TYPE_YEAR"] = 3] = "FUNDING_TYPE_YEAR";
    WidgetType[WidgetType["NEED"] = 4] = "NEED";
    WidgetType[WidgetType["FACILITY"] = 5] = "FACILITY";
})(WidgetType || (WidgetType = {}));
const useWidgetData = (planData, widgetTypes, fundingTypesList, years) => {
    const [blue, secOrange, grey, red] = useToken('colors', [
        'brand.primBlue',
        'brand.secOrange',
        'secDarkGray.500',
        'ui.danger',
    ]);
    const aggregateBudgets = (inputData) => {
        const data = inputData.reduce((acc, { fundingTypeId, budget }) => {
            if (budget !== undefined) {
                acc[fundingTypeId] = (acc[fundingTypeId] || 0) + budget;
            }
            return acc;
        }, {});
        return fundingTypesList
            .sort((a, b) => a.name.localeCompare(b.name))
            .map(({ identity }) => data[identity] || 0);
    };
    const fundingByTypeData = aggregateBudgets(planData.budgetsByFundingType);
    const allocatedFundingTypes = aggregateBudgets(planData.fundingTypeAllocations);
    const fundingTypeNames = fundingTypesList.map((type) => type.name);
    const fundingTypeNamesInPlan = [
        ...new Set(planData.fundingTypeAllocations.map((item) => item.fundingTypeName)),
    ].sort((a, b) => a.localeCompare(b));
    const colorBarGraphLabels = (fundingData, allocatedData) => {
        const barGraphNameColor = [];
        fundingData.forEach((budget, index) => {
            if (budget < allocatedData[index]) {
                barGraphNameColor.push(red);
            }
            else {
                barGraphNameColor.push(grey);
            }
        });
        return barGraphNameColor;
    };
    const colorBarGraphLabelsByYear = (fundingData, allocatedData) => {
        const barGraphNameColor = [];
        years.forEach((year) => {
            const hasExceededBudget = allocatedData.some((allocated) => {
                if (allocated.planYear === year) {
                    const matchingFunding = fundingData.find((funding) => funding.fundingTypeId === allocated.fundingTypeId &&
                        funding.planYear === year);
                    return ((allocated.budget > 0 && !matchingFunding) ||
                        allocated.budget > ((matchingFunding === null || matchingFunding === void 0 ? void 0 : matchingFunding.budget) || 0));
                }
                return false;
            });
            barGraphNameColor.push(hasExceededBudget ? red : grey);
        });
        return barGraphNameColor;
    };
    const allocatedDatasetLabel = 'Allocated';
    const budgetDatasetLabel = 'Budget';
    const allocatedDataset = {
        name: allocatedDatasetLabel,
        data: allocatedFundingTypes,
    };
    const fundTypeByYearDataset = fundingTypeNamesInPlan.map((fundingType) => {
        return {
            name: fundingType,
            data: years.map((year) => {
                const budgetData = planData.fundingTypeAllocations.find((item) => item.planYear === year && item.fundingTypeName === fundingType);
                return budgetData ? budgetData.budget : 0;
            }),
        };
    });
    const fundingTypeByYearData = {
        axisCategories: years.map((year) => year.toString()),
        datasets: fundTypeByYearDataset,
    };
    const generateDatasetColors = (fundingTypes) => {
        return fundingTypes.map((fundingType) => {
            return rgbFromRandomString(fundingType);
        });
    };
    const datasetColors = generateDatasetColors(fundingTypeNamesInPlan);
    const { globalMax, globalMin } = getGlobalMinMax(fundingTypeByYearData);
    const widgetConfigs = [];
    widgetTypes.forEach((type) => {
        var _a, _b;
        switch (type) {
            case WidgetType.TOTAL_BUDGET:
                widgetConfigs.push({
                    chartType: 'bar',
                    widgetType: WidgetType.TOTAL_BUDGET,
                    chartData: {
                        config: {
                            tooltip: { enabled: true },
                            datasetColors: [blue, secOrange],
                            labelColors: {
                                xAxis: [
                                    planData.totalAllocation > planData.totalBudget ? red : grey,
                                ],
                            },
                            formatters: {
                                yAxis: formatBudgetToString,
                                tooltip: {
                                    y: formatBudgetToString,
                                },
                            },
                        },
                        data: {
                            axisCategories: ['Total Budget'],
                            datasets: [
                                { name: budgetDatasetLabel, data: [planData.totalBudget] },
                                {
                                    name: allocatedDatasetLabel,
                                    data: [planData.totalAllocation],
                                },
                            ],
                        },
                    },
                });
                break;
            case WidgetType.NEED:
                widgetConfigs.push({
                    chartType: 'bar',
                    widgetType: WidgetType.NEED,
                    chartData: {
                        config: {
                            tooltip: { enabled: true },
                            datasetColors: [secOrange],
                            legend: { datasetLabels: [allocatedDatasetLabel] },
                            formatters: {
                                yAxis: formatBudgetToString,
                                tooltip: {
                                    y: formatBudgetToString,
                                },
                            },
                        },
                        data: {
                            axisCategories: fundingTypeNames,
                            datasets: [allocatedDataset],
                        },
                    },
                });
                break;
            case WidgetType.FUNDING_TYPE:
                widgetConfigs.push({
                    chartType: 'bar',
                    widgetType: WidgetType.FUNDING_TYPE,
                    chartData: {
                        config: {
                            tooltip: { enabled: true },
                            datasetColors: [blue, secOrange],
                            labelColors: {
                                xAxis: colorBarGraphLabels(fundingByTypeData, allocatedFundingTypes),
                            },
                            legend: {
                                datasetLabels: [budgetDatasetLabel, allocatedDatasetLabel],
                            },
                            formatters: {
                                yAxis: formatBudgetToString,
                                tooltip: {
                                    y: formatBudgetToString,
                                },
                            },
                        },
                        data: {
                            axisCategories: fundingTypeNames,
                            datasets: [
                                {
                                    name: budgetDatasetLabel,
                                    data: fundingByTypeData,
                                },
                                allocatedDataset,
                            ],
                        },
                    },
                });
                break;
            case WidgetType.FUNDING_TYPE_YEAR:
                widgetConfigs.push({
                    chartType: 'bar',
                    widgetType: WidgetType.FUNDING_TYPE_YEAR,
                    chartData: {
                        config: {
                            stacked: true,
                            showAvg: fundTypeByYearDataset.length > 0 ? true : false,
                            barLabel: {
                                enabled: false,
                            },
                            annotations: fundTypeByYearDataset.length > 0
                                ? getAvgAnnotation('vertical', fundingTypeByYearData)
                                : {},
                            tooltip: {
                                enabled: true,
                                shared: false,
                                intersect: true,
                            },
                            legend: {
                                // Use custom labels for hover functionality when only one item is displayed.
                                datasetLabels: fundingTypeNamesInPlan.length === 1
                                    ? fundingTypeNamesInPlan
                                    : [],
                                position: 'bottom',
                            },
                            labelColors: {
                                xAxis: colorBarGraphLabelsByYear(planData.budgetsByFundingType, planData.fundingTypeAllocations),
                            },
                            formatters: {
                                min: globalMin,
                                max: globalMax,
                                yAxis: fundingTypeByYearData.datasets.length > 0
                                    ? getCurrencyNotation
                                    : (value) => {
                                        if (value === 0 || value === 1 || value === 2) {
                                            return `$${value.toFixed(0)}`;
                                        }
                                        return `$${value.toFixed(1)}`;
                                    },
                            },
                            datasetColors: datasetColors,
                        },
                        data: fundingTypeByYearData,
                    },
                });
                break;
            case WidgetType.FACILITY:
                widgetConfigs.push({
                    chartType: 'pie',
                    widgetType: WidgetType.FACILITY,
                    pieChartData: {
                        config: {
                            tooltip: {
                                enabled: true,
                                custom: ({ seriesIndex: i }) => {
                                    var _a, _b;
                                    const data = (_a = planData.budgetsByFacility) === null || _a === void 0 ? void 0 : _a[i];
                                    return data !== undefined ? (
                                    // Can't use Chakra here because the tooltip doesn't support non-HTML elements
                                    _jsxs("div", Object.assign({ style: { padding: '8px' } }, { children: [_jsxs("p", { children: ["Percentage %:", ' ', (data.allocationPercentage * 100).toFixed(1)] }), _jsxs("p", { children: ["Budget: ", getCurrencyNotation(data.allocationAmount)] }), _jsxs("p", { children: ["Budget per SQFT:", ' ', getCurrencyNotation((_b = data.allocationByArea) !== null && _b !== void 0 ? _b : 0)] })] }))) : (_jsx("p", { children: "No Data" }));
                                },
                            },
                        },
                        labels: (_a = planData.budgetsByFacility.map((budget) => budget.facilityName)) !== null && _a !== void 0 ? _a : [],
                        data: (_b = planData.budgetsByFacility.map((budget) => budget.allocationAmount)) !== null && _b !== void 0 ? _b : [],
                    },
                });
                break;
            default:
                break;
        }
    });
    return widgetConfigs;
};
export default useWidgetData;
