import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Rect } from 'react-konva';
import useImage from 'use-image';
export const KonvaImage = ({ url, fillWidth, fillHeight, }) => {
    const [image] = useImage(url);
    const [height, setHeight] = useState(fillHeight);
    const [ratio, setRatio] = useState();
    const imageWidth = (image === null || image === void 0 ? void 0 : image.width) || fillWidth;
    const imageHeight = (image === null || image === void 0 ? void 0 : image.height) || height;
    const scale = fillWidth / imageWidth;
    useEffect(() => {
        setRatio(imageWidth / imageHeight);
    }, [imageWidth, imageHeight]);
    useEffect(() => {
        ratio && setHeight(fillWidth / ratio);
    }, [ratio]);
    return (_jsx(Rect, { fillPatternImage: image, fillPatternRepeat: 'no-repeat', x: 0, y: 0, width: fillWidth, height: height, fillPatternScaleX: scale, fillPatternScaleY: scale, draggable: false }));
};
