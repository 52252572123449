import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Spacer, Stack, Text, VStack } from '@chakra-ui/react';
import { CurrencyInput } from '@frontend/design-system/components/Inputs/CurrencyInput';
import { parseCurrencyAsFloat } from '@frontend/domain/conversion/NumberConversions';
import { BudgetTypeEnum } from '@frontend/domain/models/Scenario/ScenarioInterfaces';
import React from 'react';
import { useController } from 'react-hook-form';
import { convertToCurrency } from '../../../config/CPHelperFunctions';
import { slgqColorSchemes } from '@frontend/design-system/theme/theme';
import { useFormSectionErrorContext, } from '../ScenarioModal';
import { FundingTypeByYearInputs } from './FundingTypeByYearInputs';
import { FundingTypeInputs } from './FundingTypeInputs';
import { PLAN_BUDGET_TYPE_KEY } from './PlanningByPage';
export const PLAN_TOTAL_BUDGET_KEY = 'totalBudget';
export const PLAN_FUNDING_TYPE_BUDGET_KEY = 'fundingTypeBudgets';
export const BudgetPage = ({ formContext, stepOverride, fundingTypeFieldContext, }) => {
    const { fields, update } = fundingTypeFieldContext;
    // not currently needing to return error context
    useFormSectionErrorContext([PLAN_TOTAL_BUDGET_KEY], formContext, (hasError) => stepOverride.setCanContinue(!hasError));
    const { field: totalBudgetField } = useController({
        name: PLAN_TOTAL_BUDGET_KEY,
        control: formContext.control,
        rules: {
            validate: (e) => {
                if (budgetType === BudgetTypeEnum.TOTAL_BUDGET) {
                    return !e ? 'required' : undefined;
                }
                else if (budgetType !== BudgetTypeEnum.NEEDS) {
                    return e === '' || e === undefined || parseInt(e) === 0
                        ? 'required'
                        : undefined;
                }
                return undefined;
            },
        },
    });
    const budgetType = formContext.watch(PLAN_BUDGET_TYPE_KEY);
    const totalBudget = formContext.watch(PLAN_TOTAL_BUDGET_KEY);
    const updateBudgetFromFundingTypes = (fields, newBudget, index) => {
        const sum = Array.from(fields).reduce((sum, field, i) => {
            if (index === i) {
                const value = newBudget !== '' ? parseCurrencyAsFloat(newBudget) : 0;
                return sum + value;
            }
            else {
                const value = field.budget !== '' ? parseCurrencyAsFloat(field.budget) : 0;
                return sum + value;
            }
        }, 0);
        formContext.setValue(PLAN_TOTAL_BUDGET_KEY, sum.toString(), {
            shouldValidate: true,
        });
    };
    const updateFundingTypeInList = (field, newBudget, index) => {
        const newFundingTypeBudget = Object.assign(Object.assign({}, field), { budget: newBudget });
        updateBudgetFromFundingTypes(fields, newBudget, index);
        update(index, newFundingTypeBudget);
    };
    return (_jsx(Stack, { children: _jsxs(VStack, Object.assign({ alignItems: 'left' }, { children: [budgetType === BudgetTypeEnum.TOTAL_BUDGET ? (_jsx(CurrencyInput, { label: 'Total Budget', onInputChange: totalBudgetField.onChange, data: totalBudgetField.value, placeholder: 'Type here...', maskOptions: { allowDecimal: false }, required: true })) : budgetType === BudgetTypeEnum.FUNDING_TYPE ? (_jsx(FundingTypeInputs, { formContext: formContext, fields: fields, updateFundingType: updateFundingTypeInList })) : (_jsx(FundingTypeByYearInputs, { formContext: formContext, fields: fields, updateFundingType: updateFundingTypeInList })), _jsx(Spacer, { h: '20px' }), budgetType !== BudgetTypeEnum.TOTAL_BUDGET && (_jsxs(Text, Object.assign({ alignSelf: 'left', colorScheme: slgqColorSchemes.primBlue }, { children: ["My total budget is ", _jsx("b", { children: convertToCurrency(totalBudget !== null && totalBudget !== void 0 ? totalBudget : '0') }), ' ', "USD"] })))] })) }));
};
