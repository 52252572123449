var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Search2Icon } from '@chakra-ui/icons';
import { Box, HStack, Text } from '@chakra-ui/react';
import { chakraComponents, Select as ChakraSelect, } from 'chakra-react-select';
import { appColors } from '../../theme/constants';
export const SearchBar = ({ onSearchSelect, searchItems, color, isDisabled, }) => {
    const handleSelect = (value) => {
        onSearchSelect(value);
    };
    const CustomSelectComponents = {
        Option: (_a) => {
            var { children } = _a, props = __rest(_a, ["children"]);
            return (_jsxs(chakraComponents.Option, Object.assign({}, props, { children: [_jsx(Box, Object.assign({ display: 'none' }, { children: children })), _jsxs(HStack, Object.assign({ justify: 'space-between', width: '100%' }, { children: [_jsx(Text, Object.assign({ fontSize: '14px', w: '50%', overflow: 'hidden', textOverflow: 'ellipsis' }, { children: props.data.label })), _jsx(Text, Object.assign({ fontSize: '14px', w: '40%', textAlign: 'end' }, { children: props.data.subLabel }))] }))] })));
        },
        DropdownIndicator: (props) => (_jsx(chakraComponents.DropdownIndicator, Object.assign({}, props, { children: _jsx(Search2Icon, { color: color === null || color === void 0 ? void 0 : color.icon }) }))),
    };
    return (_jsx(Box, Object.assign({ "data-testid": 'search-bar' }, { children: _jsx(ChakraSelect, { useBasicStyles: true, placeholder: 'Search', options: searchItems, value: null, onChange: (option) => handleSelect(option === null || option === void 0 ? void 0 : option.value), components: CustomSelectComponents, isDisabled: isDisabled, chakraStyles: {
                valueContainer: (provided) => (Object.assign(Object.assign({}, provided), { background: appColors.SEC_LIGHT_GREY, p: 2 })),
                indicatorsContainer: (provided) => (Object.assign(Object.assign({}, provided), { background: appColors.SEC_LIGHT_GREY })),
                placeholder: (provided) => (Object.assign(Object.assign({}, provided), { textColor: color === null || color === void 0 ? void 0 : color.input })),
                menu: (provided) => (Object.assign(Object.assign({}, provided), { width: '96%', ml: '2%', mt: '1px' })),
                menuList: (provided) => (Object.assign(Object.assign({}, provided), { borderTopRadius: 0, maxHeight: '180px' })),
            } }) })));
};
