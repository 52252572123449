export class SpaceService {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Retrieves space detail information based on the provided ID.
     * NOTE: Exactly one of facility or space ID must be provided.
     * @param facilityId ID of the facility to load spaces for
     * @param spaceId ID of the space to load
     * @returns SpaceDetailResponse OK
     * @throws ApiError
     */
    getApiV1SpaceLoad(facilityId, spaceId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/Space/load',
            query: {
                facilityId: facilityId,
                spaceId: spaceId,
            },
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Retrieves space summary information based on the provided ID.
     * NOTE: Exactly one of facility or floor plan ID must be provided.
     * @param facilityId The ID of the facility to list spaces for
     * @param floorPlanId The ID of the floor plan to list spaces for
     * @param assetClassTypes
     * @returns SpaceSummaryResponse OK
     * @throws ApiError
     */
    getApiV1SpaceList(facilityId, floorPlanId, assetClassTypes) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/Space/list',
            query: {
                facilityId: facilityId,
                floorPlanId: floorPlanId,
                assetClassTypes: assetClassTypes,
            },
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Creates or updates a space evaluation
     * @param requestBody A space evaluation
     * @returns SpaceDetailResponse OK
     * @throws ApiError
     */
    postApiV1SpaceEvaluate(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/Space/evaluate',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Re-assesses a space's IEQ
     * @param requestBody Space re-evaluation
     * @returns SpaceDetailResponse OK
     * @throws ApiError
     */
    postApiV1SpaceReassess(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/Space/reassess',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Removes the given space and it's assets from the system.
     * @param spaceId The ID of the space to remove
     * @returns any OK
     * @throws ApiError
     */
    deleteApiV1SpaceRemove(spaceId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/Space/remove/{spaceId}',
            path: {
                spaceId: spaceId,
            },
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Copies space and standard asset(s) into the target space(s)
     * @param requestBody Copy settings
     * @returns any OK
     * @throws ApiError
     */
    postApiV1SpaceCopy(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/Space/copy',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
}
