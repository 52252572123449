import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Center, Image, Spinner, Text, VStack } from '@chakra-ui/react';
import { useAppAccess } from '@frontend/domain/contexts/Authorization/UserAccess';
import { usePlannerPermissions } from '@frontend/domain/contexts/Authorization/UserPermission';
import { useDependencies } from '@frontend/domain/contexts/Dependencies/DependenciesContext';
import { useOrganizationSummaries } from '@frontend/domain/contexts/Organization/OrganizationSummaryContext';
import { useOrganization } from '@frontend/domain/hooks/Organization/OrganizationHook';
import { IAppNames } from '@frontend/domain/models/App';
import { usePlanService } from '@frontend/domain/services/PlanService';
import { UnauthorizedOrg } from '@frontend/shared/src/components/Access/Unauthorized';
import { AppRoutes } from '@frontend/shared/src/components/Routing/RouteConstants';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageLayout } from '../../components/layouts/PageLayout';
import { appColors } from '../../config/constants';
import { useProjectsContext } from '../../contexts/Projects/ProjectsContext';
import starterImage from '../../images/ScenarioPlanner_baseImage.png';
import { AssetsTable } from './AssetsTable';
export const MyAssetsPage = () => {
    const { selectedOrganizationIdentity: orgIdentity } = useOrganizationSummaries();
    const { organizationDetails: orgCurrent } = useOrganization({
        organizationIdentity: orgIdentity,
    });
    const { projectList } = useProjectsContext(); // assumes project provider is at app level
    const { canAddScenario, canAddProject } = usePlannerPermissions(projectList);
    const { platformApi } = useDependencies();
    const { plan } = usePlanService({ platformApi });
    const [planAssetDetail, setPlanAssetDetail] = useState();
    const [loading, setLoading] = useState();
    useEffect(() => {
        if (orgIdentity) {
            setLoading(true);
            plan
                .fetchAssets(orgIdentity)
                .then((response) => {
                if (response) {
                    setPlanAssetDetail(response);
                }
            })
                .finally(() => setLoading(false));
        }
    }, [orgIdentity]);
    const { hasAccess } = useAppAccess(IAppNames.FCA);
    const navigate = useNavigate();
    return (_jsx(_Fragment, { children: _jsx(PageLayout, Object.assign({ title: 'myAssets', canAddProject: canAddProject, canAddScenario: canAddScenario, onAddProject: (orgIdentity) => navigate(AppRoutes.myProjects(orgIdentity), {
                state: { showModal: true },
            }), onAddScenario: (orgIdentity) => navigate(AppRoutes.myScenarios(orgIdentity), {
                state: { showModal: true },
            }) }, { children: _jsx(_Fragment, { children: loading || orgIdentity === undefined ? (_jsx(Center, Object.assign({ h: '85vh', w: '100%' }, { children: _jsx(Spinner, { color: appColors.PRIM_BLUE }) }))) : (_jsx(Box, { children: planAssetDetail && planAssetDetail.assets.length > 0 ? (_jsx(AssetsTable, { planAssetDetail: planAssetDetail })) : (_jsx(Center, Object.assign({ h: '65vh', w: '100%' }, { children: _jsx(Box, Object.assign({ textAlign: 'center' }, { children: hasAccess ? (_jsxs(VStack, { children: [_jsx(Image, { "aria-label": 'no assets available', h: '325px', sx: { objectFit: 'cover' }, src: starterImage }), _jsxs(Text, { children: ["You do not have any assets! Use the Prioritize --", '>', ' ', "FCA Tool to add assets"] })] })) : (_jsx(UnauthorizedOrg, { organization: orgCurrent })) })) }))) })) }) })) }));
};
