import { incrementFileName } from '../../cp/src/utils/fileUtils';
const toCleanFileName = (fileName) => {
    const splittedFileName = fileName.split('.');
    const extension = splittedFileName.pop();
    const cleanedName = splittedFileName
        .join('.')
        .replace(/[!*'();:@&=+$,/?%#[\].\\]+|\s+/g, '-');
    return cleanedName + '.' + extension;
};
const getFileExtension = (fileName) => {
    return fileName.split('.').pop() || '';
};
const createFileWithUpdatedName = (file, newName) => {
    return new File([file], newName, {
        type: file.type,
        lastModified: file.lastModified,
    });
};
export const validateFileUpload = (files, validFileExtensions, validFileSize, validFileNameSize, 
// For uploading multiple files and incrementing file names/
// or displaying an error if applicable
projectFiles, 
// To check whether or not we are uploading files with the same name on the same save action
newlyAddedFiles) => {
    const invalidFiles = [];
    const validFiles = [];
    const errorMessages = [];
    files.forEach((file) => {
        const cleanedFileName = toCleanFileName(file.name);
        let fileName = cleanedFileName;
        if (projectFiles) {
            fileName = incrementFileName(projectFiles, cleanedFileName);
        }
        const fileExtension = getFileExtension(fileName);
        const fileSize = file.size;
        const errors = [];
        if (!validFileExtensions.includes(fileExtension)) {
            invalidFiles.push(file);
            errorMessages.push(`File, ${fileName}, has an invalid file type.`);
            errors.push('file type error');
        }
        if (validFileSize && fileSize > validFileSize) {
            invalidFiles.push(file);
            errorMessages.push(`File, ${fileName}, exceeds the max size of ${validFileSize / 1024 / 1024} MB.`);
            errors.push('max file size error');
        }
        if (validFileNameSize && fileName.length > validFileNameSize - 20) {
            invalidFiles.push(file);
            errorMessages.push(`File, ${fileName}, exceeds the max name length of ${validFileNameSize} characters.`);
            errors.push('max file name error');
        }
        if (newlyAddedFiles) {
            const hasSameName = newlyAddedFiles
                .map((file) => file.name)
                .includes(cleanedFileName);
            if (hasSameName) {
                invalidFiles.push(file);
                errors.push('duplicate file name error');
                errorMessages.push(`File name, ${cleanedFileName}, is duplicated.`);
            }
        }
        if (errors.length === 0) {
            const updatedFile = createFileWithUpdatedName(file, fileName);
            validFiles.push(updatedFile);
        }
    });
    return {
        errorMessage: errorMessages.length > 0 ? errorMessages.join('\n') : undefined,
        validFiles: validFiles,
    };
};
