var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { getPlans } from '../../services/api/facilityAPI';
export const useFloorPlan = (facilityIdentity) => {
    const toast = useToast();
    const [floorPlanList, setFloorPlanList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        if (facilityIdentity) {
            fetchPlans();
        }
    }, [facilityIdentity]);
    // TODO: replace implementation with service layer once properly created
    const fetchPlans = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        try {
            const res = yield getPlans(facilityIdentity);
            setFloorPlanList(res);
        }
        catch (err) {
            console.error('couldnt fetch plans: ', err);
            toast({
                title: 'Failed to fetch plans',
                status: 'error',
                isClosable: true,
            });
        }
        finally {
            setIsLoading(false);
        }
    });
    return {
        floorPlanList,
        isLoading,
    };
};
