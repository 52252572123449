var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Center, FormControl, FormErrorMessage, FormLabel, Heading, HStack, VStack, } from '@chakra-ui/react';
import React, { createContext, useContext, useState } from 'react';
import { EditIcon } from '../../theme/icons/EditIcon';
import { InfoIcon } from '../../theme/icons/InfoIcon';
import { variants } from '../../theme/theme';
import { Loading } from '../Loading/Loading';
import { TextOrSkeleton } from '../TextOrSkeleton/TextOrSkeleton';
import { Tooltip } from '../Tooltip/Tooltip';
export const InfoTabPanelContext = createContext({});
function Panel({ children, editability, onSubmit, onCancelEditing, }) {
    const [isEditMode, setIsEditMode] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const editable = (editability === null || editability === void 0 ? void 0 : editability.canEdit) && (editability === null || editability === void 0 ? void 0 : editability.editable);
    const isEditing = editable && isEditMode;
    const handleOnSubmit = (event) => __awaiter(this, void 0, void 0, function* () {
        if (event) {
            event.preventDefault();
            setIsSaving(true);
            if (onSubmit) {
                onSubmit(event)
                    .then((successful) => {
                    if (successful) {
                        setIsEditMode(false);
                    }
                })
                    .finally(() => setIsSaving(false));
            }
        }
    });
    return (_jsxs("form", Object.assign({ onSubmit: handleOnSubmit, style: { width: '100%' } }, { children: [isSaving && (_jsx(Center, Object.assign({ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.5)', zIndex: '100' }, { children: _jsx(Loading, {}) }))), _jsxs(HStack, Object.assign({ alignItems: 'top', justifyContent: 'space-around', fontSize: '16px', fontWeight: 400 }, { children: [_jsx(InfoTabPanelContext.Provider, Object.assign({ value: {
                            isInEditMode: isEditMode,
                        } }, { children: children })), editable && (_jsxs(VStack, Object.assign({ py: '10px', flexGrow: 0.5, flexBasis: 0.5 }, { children: [isEditing && (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ width: '90px', variant: variants.addNewBtn, type: 'submit' }, { children: "Save" })), _jsx(Button, Object.assign({ width: '90px', variant: variants.transDelBtn, onClick: () => {
                                            setIsEditMode(false);
                                            onCancelEditing && onCancelEditing();
                                        } }, { children: "Cancel" }))] })), !isEditing && (_jsx(Button, Object.assign({ width: '90px', variant: variants.transparentBlueBtn, visibility: !editable ? 'hidden' : 'visible', leftIcon: _jsx(EditIcon, { boxSize: '12px' }), onClick: () => setIsEditMode(true) }, { children: "Edit" })))] })))] }))] })));
}
function PanelProvider({ children, isEditMode, onSubmit, noValidate, }) {
    const [isSaving, setIsSaving] = useState(false);
    const handleOnSubmit = (event) => __awaiter(this, void 0, void 0, function* () {
        if (event) {
            event.preventDefault();
            setIsSaving(true);
            if (onSubmit) {
                onSubmit(event).then(() => setIsSaving(false));
            }
        }
    });
    return (_jsx(InfoTabPanelContext.Provider, Object.assign({ value: { isInEditMode: isEditMode } }, { children: _jsxs("form", Object.assign({ onSubmit: handleOnSubmit, style: { width: '100%' }, noValidate: noValidate }, { children: [isSaving && (_jsx(Center, Object.assign({ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.5)', zIndex: '100' }, { children: _jsx(Loading, {}) }))), children] })) })));
}
function SlimPanel({ children }) {
    return (_jsx(HStack, Object.assign({ alignItems: 'top', justifyContent: 'space-around', fontSize: '16px', fontWeight: 400 }, { children: children })));
}
function Column({ stackProps, children }) {
    return (_jsx(VStack, Object.assign({ alignItems: 'start', px: '10px', py: '10px', gap: '25px', flexGrow: 1, flexBasis: 1 }, stackProps, { children: children })));
}
function Info({ label, info, isLoading, italicized = false, opaque = false, errorMessage, leftIcon, children, tooltip, required, }) {
    const { isInEditMode } = useContext(InfoTabPanelContext);
    return (_jsxs(FormControl, Object.assign({ isInvalid: Boolean(errorMessage), isRequired: required && isInEditMode }, { children: [_jsxs(HStack, Object.assign({ alignContent: 'center', whiteSpace: 'pre-line' }, { children: [_jsx(FormLabel, Object.assign({ color: 'brand.primBlue', fontWeight: '600' }, { children: label })), tooltip && (_jsx(Tooltip, { label: tooltip, icon: _jsx(InfoIcon, { boxSize: '20px', marginBottom: '8px', marginRight: '12px' }), props: {
                            children: null,
                            placement: 'end-start',
                            hasArrow: true,
                        } }))] })), isInEditMode && children ? (_jsx(Box, Object.assign({ pl: '18px' }, { children: children }))) : (_jsxs(Box, Object.assign({ pl: leftIcon ? '40px' : '18px', whiteSpace: 'pre-line', position: 'relative' }, { children: [leftIcon && (_jsx(Box, Object.assign({ left: '18px', position: 'absolute' }, { children: leftIcon }))), _jsx(TextOrSkeleton, { maybeText: info, isLoaded: !isLoading, italicized: italicized, opaque: opaque })] }))), errorMessage && isInEditMode && (_jsx(FormErrorMessage, Object.assign({ pl: '18px' }, { children: errorMessage })))] })));
}
function InfoHeader({ label, info, errorMessage, children, required, }) {
    const { isInEditMode } = useContext(InfoTabPanelContext);
    return (_jsxs(FormControl, Object.assign({ isInvalid: Boolean(errorMessage), isRequired: required && isInEditMode, w: '100%' }, { children: [_jsx(HStack, Object.assign({ alignContent: 'center', whiteSpace: 'pre-line', w: '100%' }, { children: !isInEditMode ? (_jsx(Heading, Object.assign({ color: 'brand.primBlue', alignItems: 'center', fontSize: '24px', fontWeight: 600, pl: '18px', w: '100%' }, { children: info }))) : (_jsxs(VStack, Object.assign({ w: '100%', alignItems: 'flex-start' }, { children: [_jsx(FormLabel, Object.assign({ color: 'brand.primBlue', fontWeight: '600' }, { children: label })), _jsx(Box, Object.assign({ pl: '18px', w: '100%' }, { children: children }))] }))) })), errorMessage && isInEditMode && (_jsx(FormErrorMessage, Object.assign({ pl: '18px' }, { children: errorMessage })))] })));
}
export const InformationTab = {
    Panel,
    PanelProvider,
    SlimPanel,
    Column,
    Info,
    InfoHeader,
};
