import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { TaxImpactCurrencyInput } from './Inputs/TaxImpactCurrencyInput';
import { TaxImpactTitleWrapper } from './TaxImpactTitleWrapper';
const TOTAL_REINVESTMENT_KEY = 'totalReinvestment';
export const GeneralInformationSection = ({ formContext, taxImpact, }) => {
    const displayGeneralInformationSection = () => {
        if (taxImpact && !(taxImpact === null || taxImpact === void 0 ? void 0 : taxImpact.totalReinvestment)) {
            return false;
        }
        else {
            return true;
        }
    };
    return (_jsx(_Fragment, { children: displayGeneralInformationSection() && (_jsx(TaxImpactTitleWrapper, Object.assign({ title: 'General Information' }, { children: _jsx(TaxImpactCurrencyInput, { title: 'Total value of reinvestment', formContext: formContext, taxImpactExists: taxImpact !== undefined, formKey: TOTAL_REINVESTMENT_KEY }) }))) }));
};
