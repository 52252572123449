const Paths = {
    base: '/',
    logout: '/logout',
    support: '/support',
    userProfile: '/user-profile',
    activate: '/activate',
    admin: '/admin',
    createOrganization: '/create-organization',
    organization: '/organization',
    details: '/details',
    reports: '/reports',
    facilities: '/facilities',
    projects: '/projects',
    scenarios: '/scenarios',
    compare: '/compare',
    photos: '/photos',
    assets: '/assets',
    organize: '/organize',
    prioritize: '/prioritize',
    opportunities: 'opportunities',
};
const PathParams = {
    organization: '/:oid',
    facility: '/:fid',
    report: '/:rid',
    scenario: '/:scid',
};
const getSelectedOrgPath = (orgIdentity) => {
    return orgIdentity ? `/${orgIdentity}` : PathParams.organization;
};
const getSelectedReportPath = (reportId) => {
    return reportId ? `/${reportId}` : PathParams.report;
};
const getSelectedFacilityPath = (facilityIdentity) => {
    return facilityIdentity ? `/${facilityIdentity}` : PathParams.facility;
};
const getSelectedScenarioPath = (scenarioIdentity) => {
    return scenarioIdentity ? `/${scenarioIdentity}` : PathParams.scenario;
};
export const AppPaths = {
    home: Paths.base,
    support: Paths.support,
    profile: Paths.userProfile,
    logout: Paths.logout,
    activate: Paths.activate,
    organization: {
        base: Paths.organization,
        withOrgId: (orgIdentity) => {
            const selectedOrgPath = getSelectedOrgPath(orgIdentity);
            return `${AppPaths.organization.base}${selectedOrgPath}`;
        },
        details: (orgIdentity) => {
            const base = AppPaths.organization.withOrgId(orgIdentity);
            return `${base}${Paths.details}`;
        },
    },
    admin: {
        base: Paths.admin,
        createOrganization: `${Paths.admin}${Paths.createOrganization}`,
    },
    reports: {
        base: (orgIdentity) => {
            const selectedOrgPath = AppPaths.organization.withOrgId(orgIdentity);
            return `${selectedOrgPath}${Paths.reports}`;
        },
        withReportId: (pathIds) => {
            const selectedReportPath = getSelectedReportPath(pathIds === null || pathIds === void 0 ? void 0 : pathIds.reportId);
            return `${AppPaths.reports.base(pathIds === null || pathIds === void 0 ? void 0 : pathIds.orgIdentity)}${selectedReportPath}`;
        },
    },
    facilities: {
        base: (orgIdentity) => {
            const selectedOrgPath = AppPaths.organization.withOrgId(orgIdentity);
            return `${selectedOrgPath}${Paths.facilities}`;
        },
        withFacilityId: (pathIds) => {
            const selectedFacilityPath = getSelectedFacilityPath(pathIds === null || pathIds === void 0 ? void 0 : pathIds.facilityIdentity);
            return `${AppPaths.facilities.base(pathIds === null || pathIds === void 0 ? void 0 : pathIds.orgIdentity)}${selectedFacilityPath}`;
        },
    },
    photos: {
        base: (orgIdentity) => {
            const selectedOrgPath = AppPaths.organization.withOrgId(orgIdentity);
            return `${selectedOrgPath}${Paths.photos}`;
        },
    },
    projects: {
        base: (orgIdentity) => {
            const selectedOrgPath = AppPaths.organization.withOrgId(orgIdentity);
            return `${selectedOrgPath}${Paths.projects}`;
        },
    },
    scenarios: {
        base: (orgIdentity) => {
            const selectedOrgPath = AppPaths.organization.withOrgId(orgIdentity);
            return `${selectedOrgPath}${Paths.scenarios}`;
        },
        withScenarioId: (pathIds) => {
            const selectedScenarioPath = getSelectedScenarioPath(pathIds === null || pathIds === void 0 ? void 0 : pathIds.scenarioIdentity);
            return `${AppPaths.scenarios.base(pathIds === null || pathIds === void 0 ? void 0 : pathIds.orgIdentity)}${selectedScenarioPath}`;
        },
        compare: (orgIdentity) => {
            const base = AppPaths.scenarios.base(orgIdentity);
            return `${base}${Paths.compare}`;
        },
    },
    assets: {
        base: (orgIdentity) => {
            const selectedOrgPath = AppPaths.organization.withOrgId(orgIdentity);
            return `${selectedOrgPath}${Paths.assets}`;
        },
    },
};
const makeExternal = (externalBaseRoute, appRoute) => {
    return `${externalBaseRoute}${appRoute}`;
};
/** Utility to provide Toolbar routes for internal and external navigation
 * externalBaseRoute is the full base route derived from each apps environment variables
 * (ex. https://dev.mysiteiq.com/organize, https://dev.mysiteiq.com/prioritize)
 */
export const AppRoutes = {
    // External
    // Organize
    myFacilities: (orgIdentity, externalBaseRoute) => {
        const { facilities } = AppPaths;
        const route = facilities.base(orgIdentity);
        return externalBaseRoute ? makeExternal(externalBaseRoute, route) : route;
    },
    myGallery: (orgIdentity, externalBaseRoute) => {
        const { photos } = AppPaths;
        const route = photos.base(orgIdentity);
        return externalBaseRoute
            ? makeExternal(externalBaseRoute, photos.base(orgIdentity))
            : route;
    },
    // Prioritize
    myProjects: (orgIdentity, externalBaseRoute) => {
        const { projects } = AppPaths;
        const route = projects.base(orgIdentity);
        return externalBaseRoute
            ? makeExternal(externalBaseRoute, projects.base(orgIdentity))
            : route;
    },
    myScenarios: (orgIdentity, externalBaseRoute) => {
        const { scenarios } = AppPaths;
        const route = scenarios.base(orgIdentity);
        return externalBaseRoute ? makeExternal(externalBaseRoute, route) : route;
    },
    myAssets: (orgIdentity, externalBaseRoute) => {
        const { assets } = AppPaths;
        const route = assets.base(orgIdentity);
        return externalBaseRoute ? makeExternal(externalBaseRoute, route) : route;
    },
    myOpportunities: (orgIdentity, externalBaseRoute) => {
        const { facilities } = AppPaths;
        const route = facilities.base(orgIdentity);
        return externalBaseRoute ? makeExternal(externalBaseRoute, route) : route;
    },
    // Internal
    // misc
    landingPage: (orgIdentity, externalBaseRoute) => {
        const { organization } = AppPaths;
        const route = orgIdentity
            ? organization.withOrgId(orgIdentity)
            : organization.base; // landing page
        return externalBaseRoute ? makeExternal(externalBaseRoute, route) : route;
    },
    profile: (externalBaseRoute) => {
        const { profile } = AppPaths;
        return externalBaseRoute
            ? makeExternal(externalBaseRoute, profile)
            : profile;
    },
    support: (externalBaseRoute) => {
        const { support } = AppPaths;
        return externalBaseRoute
            ? makeExternal(externalBaseRoute, support)
            : support;
    },
    logout: (externalBaseRoute) => {
        const { logout } = AppPaths;
        return externalBaseRoute ? makeExternal(externalBaseRoute, logout) : logout;
    },
    systemAdmin: (externalBaseRoute) => {
        const { admin: { base }, } = AppPaths;
        return externalBaseRoute ? makeExternal(externalBaseRoute, base) : base;
    },
    createOrganization: (externalBaseRoute) => {
        const { admin: { createOrganization }, } = AppPaths;
        return externalBaseRoute
            ? makeExternal(externalBaseRoute, createOrganization)
            : createOrganization;
    },
    organizationSettings: (orgIdentity, externalBaseRoute) => {
        const { organization } = AppPaths;
        const route = organization.details(orgIdentity);
        return externalBaseRoute ? makeExternal(externalBaseRoute, route) : route;
    },
    reports: (orgIdentity, reportId, externalBaseRoute) => {
        const { reports } = AppPaths;
        const route = reports.withReportId({ orgIdentity, reportId });
        return externalBaseRoute ? makeExternal(externalBaseRoute, route) : route;
    },
};
