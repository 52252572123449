import { useFilter, } from '@frontend/design-system/components/Filter/Filter';
import FilterMap, { DefaultFilterType, } from '@frontend/design-system/components/Filter/FilterMap';
import { PriorityCode } from '../../config/constants';
const blankFilter = {
    facilityName: null,
    estimatedBudget: null,
    escalatedBudget: null,
    plannedFundingTypes: null,
    projectTypeName: null,
    priorityRating: null,
    criteriaScores: null,
};
const priorityRatingFilter = (filterObj) => {
    return (obj) => {
        const filterItem = filterObj.priorityRating
            ? filterObj.priorityRating
            : [];
        if (filterItem.length > 0) {
            const valuesToFilterAgainst = filterItem.map((opt) => opt.value.toString());
            const containsNeedsAction = valuesToFilterAgainst.includes(PriorityCode.NEEDS_ACTION.toString());
            if (obj.priorityRating) {
                const includedInFilterItem = valuesToFilterAgainst.includes(obj.priorityRating);
                return includedInFilterItem;
            }
            else {
                return containsNeedsAction;
            }
        }
        else {
            return true;
        }
    };
};
const fundingTypesFilter = (filterObj) => {
    return (obj) => {
        const filterItem = filterObj.plannedFundingTypes
            ? filterObj.plannedFundingTypes
            : [];
        if (filterItem.length > 0) {
            const valuesToFilterAgainst = filterItem.map((opt) => opt.value.toString());
            const isDefaultFundTypeIncluded = obj.fundingTypeName
                ? valuesToFilterAgainst.includes(obj.fundingTypeName)
                : false;
            const isPlannedFundTypeIncluded = obj.plannedFundingTypes
                ? valuesToFilterAgainst.some((fundType) => obj.plannedFundingTypes.includes(fundType))
                : false;
            return isDefaultFundTypeIncluded || isPlannedFundTypeIncluded;
        }
        else {
            return true;
        }
    };
};
const hasSomeNameAndScore = (projectScores, filterNames, filterScores) => {
    return projectScores.some((criteriaScore) => {
        const scoreToMatch = criteriaScore.score !== undefined
            ? parseFloat(criteriaScore.score)
            : undefined;
        return (filterNames.includes(criteriaScore.name) &&
            isWithinRange(scoreToMatch, filterScores));
    });
};
const isWithinRange = (number, rangeValue) => {
    if (number === undefined) {
        return false;
    }
    else {
        return ((rangeValue.min === undefined || number >= rangeValue.min) &&
            (rangeValue.max === undefined || number <= rangeValue.max));
    }
};
const hasSomeScore = (projectScores, filterScores) => {
    return projectScores.some((criteriaScore) => {
        const scoreToMatch = criteriaScore.score !== undefined
            ? parseFloat(criteriaScore.score)
            : undefined;
        return isWithinRange(scoreToMatch, filterScores);
    });
};
const hasSomeName = (projectScores, filterScores) => {
    return projectScores.some((criteriaScore) => filterScores.includes(criteriaScore.name));
};
/** Combination filter criteria name and score
 * This filter makes assumptions on the nested field naming
 * and relies on proper dev setup with the inputs to ensure
 * the filter has the correct fields available
 */
const criteriaScoresFilter = (filterObj) => {
    return (projectSummary) => {
        if (filterObj.criteriaScores) {
            const filterItems = filterObj.criteriaScores;
            const filterNames = filterItems.get('name');
            const names = (filterNames === null || filterNames === void 0 ? void 0 : filterNames.map((items) => items.label)) || undefined;
            const scores = filterItems.get('score');
            if (names && scores) {
                return hasSomeNameAndScore(projectSummary.criteriaScores, names, scores);
            }
            else if (scores) {
                return hasSomeScore(projectSummary.criteriaScores, scores);
            }
            else if (names) {
                return hasSomeName(projectSummary.criteriaScores, names);
            }
            else {
                return false;
            }
        }
        else {
            return true;
        }
    };
};
export const projectSummaryFilter = (projectSummaryList) => {
    const filterMap = new FilterMap();
    filterMap.set('facilityName', DefaultFilterType.MULTI_VALUE);
    filterMap.set('projectTypeName', DefaultFilterType.MULTI_VALUE);
    filterMap.setCustom('plannedFundingTypes', fundingTypesFilter);
    filterMap.set('estimatedBudget', DefaultFilterType.RANGE);
    filterMap.set('escalatedBudget', DefaultFilterType.RANGE);
    filterMap.setCustom('priorityRating', priorityRatingFilter);
    filterMap.setCustom('criteriaScores', criteriaScoresFilter);
    return useFilter({
        itemsToFilter: projectSummaryList,
        filterMap,
        initialFilter: blankFilter,
    });
};
