import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, HStack, useDisclosure } from '@chakra-ui/react';
import { FilterIcon } from '@frontend/design-system/theme/icons/FilterIcon';
import { ProjectFilterBulbDisplay } from './ProjectFilterBulbDisplay';
import { ProjectFilterModal } from './ProjectFilterModal';
import { variants } from '@frontend/design-system/theme/theme';
export const ProjectFilterHeader = ({ allProjects, filterContext, showCurrentFilters = true, }) => {
    const { filter, clearFilter, clearListFilter, clearNestedListFilter, hasFilter, } = filterContext;
    const disclosure = useDisclosure();
    const getListOfFiltersToDisplay = () => {
        return Object.entries(filter).flatMap(([key, value]) => {
            if (value) {
                const filterKey = key;
                if (Array.isArray(value)) {
                    const optionArray = value;
                    return optionArray.map((option, index) => (_jsx(ProjectFilterBulbDisplay, { filterKey: filterKey, filterValue: option.label, clearFilter: () => clearListFilter(filterKey, index) }, `${key}_${option.label}`)));
                }
                else if (value instanceof Map) {
                    const optionMap = value;
                    const filterBulbs = [];
                    optionMap.forEach((options, key) => {
                        if (Array.isArray(options)) {
                            options.forEach((option, index) => filterBulbs.push(_jsx(ProjectFilterBulbDisplay, { filterKey: filterKey, filterValue: option.label, clearFilter: () => clearNestedListFilter(filterKey, key, index) }, `${key}_${option.label}`)));
                        }
                        else {
                            filterBulbs.push(_jsx(ProjectFilterBulbDisplay, { filterKey: filterKey, filterValue: options, clearFilter: () => clearNestedListFilter(filterKey, key), format: (value) => value.toString() }, `${key}`));
                        }
                    });
                    return filterBulbs;
                }
                return [
                    _jsx(ProjectFilterBulbDisplay, { filterKey: filterKey, filterValue: value, clearFilter: () => clearFilter(filterKey) }, `${key}_${value}`),
                ];
            }
            else
                return [];
        });
    };
    return (_jsxs(_Fragment, { children: [_jsxs(HStack, Object.assign({ justify: 'space-between', w: '100%' }, { children: [showCurrentFilters && _jsx(HStack, { children: getListOfFiltersToDisplay() }), _jsx(HStack, { children: _jsx(Button, Object.assign({ variant: hasFilter
                                ? variants.cpOrangeTransparentBtn
                                : variants.cpBlueTransparentBtn, onClick: disclosure.onOpen, leftIcon: _jsx(FilterIcon, { boxSize: '15px', fill: hasFilter ? 'brand.secOrange' : 'brand.primBlue' }) }, { children: "Filter" })) })] })), _jsx(ProjectFilterModal, { allProjects: allProjects, disclosure: disclosure, filterContext: filterContext })] }));
};
