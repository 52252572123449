import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Checkbox, FormControl, FormErrorMessage, FormLabel, Input, Stack, } from '@chakra-ui/react';
import { SelectInput } from '@frontend/design-system/components/DynamicInput/SelectInput';
import { TextAreaInput } from '@frontend/design-system/components/DynamicInput/TextAreaInput';
import { BudgetTypeEnum, PlanGoalEnum, } from '@frontend/domain/models/Scenario/ScenarioInterfaces';
import React from 'react';
import { Controller } from 'react-hook-form';
import { appColors } from '../../../config/constants';
import { useFormSectionErrorContext, } from '../ScenarioModal';
import { PLAN_FUNDING_TYPE_BUDGET_KEY, PLAN_TOTAL_BUDGET_KEY, } from './BudgetPage';
import { PLAN_BUDGET_TYPE_KEY } from './PlanningByPage';
export const PLAN_DURATION_KEY = 'planDurationYrs';
export const PLAN_NEED_KEY = 'planningNeed';
export const PLAN_START_KEY = 'startYear';
export const PLAN_NAME_KEY = 'name';
export const PLAN_DESCRIPTION_KEY = 'description';
export const SCENARIO_NAME_ERROR_MSG = 'A scenario already exists with that name. Please enter a different name.';
export const ScenarioDetailsPage = ({ formContext, stepOverride, existingScenarioNames, fundingTypes, fundingTypeFieldContext, isEditModal, }) => {
    const { control, setValue, getValues } = formContext;
    const { replace } = fundingTypeFieldContext;
    const currentYear = new Date().getUTCFullYear();
    const minYears = 1;
    const maxYears = 30;
    // not currently needing to return error context
    useFormSectionErrorContext([
        PLAN_NAME_KEY,
        PLAN_DESCRIPTION_KEY,
        PLAN_DURATION_KEY,
        PLAN_START_KEY,
        PLAN_NEED_KEY,
    ], formContext, (hasError) => stepOverride.setCanContinue(!hasError));
    const validateScenarioName = (value) => {
        const scenarioFound = existingScenarioNames.find((scenarioName) => scenarioName === value);
        if (scenarioFound) {
            return SCENARIO_NAME_ERROR_MSG;
        }
        else {
            return undefined;
        }
    };
    const generateYearOptions = (currentYear, yearsToShow) => {
        const options = [];
        for (let i = 0; i < yearsToShow; i++) {
            const year = currentYear + i;
            options.push({ label: year.toString(), value: year });
        }
        return options;
    };
    const onNeedsChange = (value) => {
        if (value === PlanGoalEnum.NEEDS) {
            stepOverride.setNextStep(3);
            setValue(PLAN_BUDGET_TYPE_KEY, BudgetTypeEnum.NEEDS);
            setValue(PLAN_TOTAL_BUDGET_KEY, '');
            setValue(PLAN_FUNDING_TYPE_BUDGET_KEY, []);
        }
        else {
            // allows for budget type validation resets
            setValue(PLAN_BUDGET_TYPE_KEY, '');
            stepOverride.setNextStep(undefined);
        }
    };
    const onStartYearBlur = (newStartYearStr, fundingTypes) => {
        const newStartYear = parseInt(newStartYearStr);
        const duration = parseInt(getValues().planDurationYrs);
        resetNewFindingTypeFormFields(newStartYear, duration, fundingTypes);
    };
    const onDurationBlur = (newDuration, fundingTypes) => {
        const startYear = parseInt(getValues().startYear);
        resetNewFindingTypeFormFields(startYear, newDuration, fundingTypes);
    };
    const resetNewFindingTypeFormFields = (startYear, duration, fundingTypes) => {
        const newFundingTypeBudgets = [];
        for (let i = 0; i < duration; i++) {
            const currentYear = startYear + i;
            for (const fundingType of fundingTypes) {
                newFundingTypeBudgets.push({
                    identity: fundingType.identity,
                    name: fundingType.name,
                    budget: '',
                    year: currentYear.toString(),
                });
            }
        }
        replace(newFundingTypeBudgets);
    };
    return (_jsxs(Stack, Object.assign({ gap: 3 }, { children: [_jsx(Controller, { control: control, name: PLAN_NAME_KEY, render: ({ field, fieldState: { error } }) => {
                    const isInvalid = (error === null || error === void 0 ? void 0 : error.message) !== undefined && (error === null || error === void 0 ? void 0 : error.message) !== '';
                    return (_jsxs(FormControl, Object.assign({ isRequired: true, isInvalid: isInvalid }, { children: [_jsx(FormLabel, { children: "Name the scenario" }), _jsx(Input, { placeholder: 'Type here', value: field.value, onChange: field.onChange, maxLength: 100 }), (error === null || error === void 0 ? void 0 : error.message) && (_jsx(FormErrorMessage, { children: error === null || error === void 0 ? void 0 : error.message }))] })));
                }, rules: { required: true, validate: (e) => validateScenarioName(e) } }), _jsx(Controller, { control: control, name: PLAN_DESCRIPTION_KEY, render: ({ field }) => (_jsx(TextAreaInput, { label: 'Description', data: field.value, onInputChange: field.onChange, placeholder: 'Type here', constraints: { maxLength: 1500 }, editMode: true, errorMessage: undefined })) }), _jsx(Controller, { control: control, name: PLAN_DURATION_KEY, render: ({ field }) => (_jsxs(FormControl, Object.assign({ isRequired: true }, { children: [_jsx(FormLabel, { children: 'How long are you planning for?' }), _jsx(Input, { type: 'number', placeholder: 'Enter number of years', value: field.value, onChange: (e) => {
                                let changedNum = parseInt(e.target.value);
                                if (changedNum > maxYears) {
                                    changedNum = maxYears;
                                }
                                else if (changedNum < minYears) {
                                    changedNum = minYears;
                                }
                                field.onChange(changedNum);
                            }, onBlur: (e) => {
                                const planDuration = parseInt(e.target.value);
                                onDurationBlur(planDuration, fundingTypes);
                            }, disabled: isEditModal })] }))), rules: { required: true } }), _jsx(Controller, { control: control, name: PLAN_START_KEY, render: ({ field }) => (_jsx(SelectInput, { label: 'First year of planning?', data: field.value, choices: generateYearOptions(currentYear, maxYears), onInputChange: field.onChange, onInputBlur: (changedNum) => onStartYearBlur(changedNum, fundingTypes), editable: true, isDisabled: isEditModal, required: true })), rules: { required: true } }), _jsx(Controller, { control: control, name: PLAN_NEED_KEY, render: ({ field }) => (_jsx(SelectInput, { label: 'In my planning...', data: field.value, editable: true, onInputChange: (e) => {
                        onNeedsChange(e);
                        field.onChange(e);
                    }, choices: [
                        {
                            label: 'I know my needs, I have to determine my budget',
                            value: PlanGoalEnum.NEEDS,
                        },
                        {
                            label: 'I know my budget, I have to determine my needs',
                            value: PlanGoalEnum.BUDGET,
                        },
                    ], required: true })), rules: { required: true } }), _jsx(Controller, { control: control, name: 'useEscalationFactor', render: ({ field }) => (_jsx(Checkbox, Object.assign({ isChecked: field.value, onChange: field.onChange, iconColor: appColors.SEC_ORANGE, fontSize: 14, color: appColors.PRIM_BLUE, fontWeight: 300 }, { children: "Apply escalation factor" }))) })] })));
};
