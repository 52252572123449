import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { AddIcon } from '@chakra-ui/icons';
import { Button, ButtonGroup, Flex, Heading, HStack } from '@chakra-ui/react';
import { Dropdown } from '@frontend/design-system/components/Toolbar/Dropdown';
import { variants } from '@frontend/design-system/theme/theme';
import { AppPaths } from '@frontend/shared/src/components/Routing/RouteConstants';
import { useLocation, useNavigate } from 'react-router-dom';
import { appColors } from '../../config/constants';
export const SESSION_SCENARIO_ID_KEY = 'scid';
//TODO: remove duplication in all PageHeaders
/** Replaces old org base path with new org  */
const setNewOrgInPath = (path, newOrgIdentity, currentOrgIdentity) => {
    if (currentOrgIdentity) {
        return path.replace(AppPaths.organization.withOrgId(currentOrgIdentity), AppPaths.organization.withOrgId(newOrgIdentity));
    }
    else {
        return path;
    }
};
export const PageHeader = ({ title, addButtonConfig, orgIdentity: selectedOrganizationIdentity, organizations, isLoading, onSelectOrganization, }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const handleOrgChange = (orgIdentity) => {
        onSelectOrganization(orgIdentity);
        const url = setNewOrgInPath(location.pathname, orgIdentity, selectedOrganizationIdentity);
        navigate(url);
    };
    const scenarioButtonConfig = addButtonConfig === null || addButtonConfig === void 0 ? void 0 : addButtonConfig.scenarioButtonConfig;
    const projectButtonConfig = addButtonConfig === null || addButtonConfig === void 0 ? void 0 : addButtonConfig.projectButtonConfig;
    return (_jsx(_Fragment, { children: _jsx(Flex, Object.assign({ as: 'header', minH: '50px', w: '100%' }, { children: _jsxs(HStack, Object.assign({ borderBottom: '1px solid #E6E7E9', justifyContent: 'space-between', w: '100%', h: '100%', p: '5px 40px' }, { children: [_jsx(Heading, Object.assign({ as: 'h1', fontSize: 36, fontWeight: 400, color: appColors.TEXT_SEC_DARK_GRAY, whiteSpace: 'nowrap' }, { children: title })), _jsxs(Flex, Object.assign({ gap: '40px', alignItems: 'center' }, { children: [_jsx(Dropdown, { dropdownValues: organizations.map((org) => ({
                                    label: org.name,
                                    value: org.identity,
                                })) || [], label: 'Organization', isLoading: isLoading, handleChange: handleOrgChange, value: selectedOrganizationIdentity || '', bgColor: appColors.PRIM_BLUE }), !projectButtonConfig && !scenarioButtonConfig ? (_jsx(_Fragment, {})) : (_jsxs(ButtonGroup, Object.assign({ variant: 'outline', colorScheme: 'primBlue', spacing: 5, alignItems: 'center', isDisabled: selectedOrganizationIdentity === undefined }, { children: [projectButtonConfig && (_jsx(Button, Object.assign({ variant: variants.cpBaseStyle, "aria-label": 'add new project', leftIcon: _jsx(AddIcon, {}), w: '50%', h: '40px', onClick: projectButtonConfig.onClick, isDisabled: projectButtonConfig.isDisabled, fontWeight: 500, border: `1px solid ${appColors.PRIM_BLUE}`, _hover: { background: '#e2eefd' } }, { children: projectButtonConfig.label }))), scenarioButtonConfig && (_jsx(Button, Object.assign({ variant: variants.cpBaseStyle, "aria-label": 'add new scenario', leftIcon: _jsx(AddIcon, {}), w: '50%', h: '40px', onClick: scenarioButtonConfig.onClick, isDisabled: scenarioButtonConfig.isDisabled, fontWeight: 500, border: `1px solid ${appColors.PRIM_BLUE}`, _hover: { background: '#e2eefd' } }, { children: scenarioButtonConfig.label })))] })))] }))] })) })) }));
};
