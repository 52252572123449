import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Heading, Input, useToken, VStack } from '@chakra-ui/react';
import { SelectInput } from '@frontend/design-system/components/DynamicInput/SelectInput';
import { TextAreaInput } from '@frontend/design-system/components/DynamicInput/TextAreaInput';
import { CurrencyInput } from '@frontend/design-system/components/Inputs/CurrencyInput';
import { DateInput, validateYearRange, } from '@frontend/design-system/components/Inputs/DateInput';
import { TableGrid } from '@frontend/design-system/components/TableGrid/TableGrid';
import { InformationTab } from '@frontend/design-system/components/Tabs/InformationTabPanel';
import { formatBudgetToString, } from '@frontend/domain/models/Projects/ProjectsInterfaces';
import { Controller, useController, } from 'react-hook-form';
import { NeedsAction } from '../../../components/alerts/NeedsAction';
import { SliderInput } from '../../../components/forms/SliderInput';
import { appColors, FriendlyPriorityCode, projectLabels, } from '../../../config/constants';
import { getFriendlyPriorityRating } from '../../../config/CPHelperFunctions';
const NAME_KEY = 'name';
const FUNDING_TYPE_KEY = 'fundingTypeIdentity';
const QUOTE_DATE_KEY = 'quoteDate';
const PROJ_SCOPE_KEY = 'scope';
const CURRENT_ISSUES_KEY = 'currentIssues';
const PROJECT_PRIORITIES_KEY = 'priorities';
const ESTIMATED_BUDGET_KEY = 'estimatedBudget';
export const GeneralInfoTab = ({ project, projectUseForm, editMode, fundingTypes, isCriteriaTypeConditionLocked, priorityScoreAverage, priorityCode, colorCode, }) => {
    var _a, _b;
    const { control, formState: { errors }, resetField, watch, } = projectUseForm;
    const [blue] = useToken('colors', ['brand.primBlue']);
    const watchShowBudget = watch(ESTIMATED_BUDGET_KEY);
    const showBudgetDate = watchShowBudget !== '';
    const renderPrioritizationInputs = (criteriaScores) => {
        // TODO: refactor to match platformAPI return
        // ensure all eligible prioritization criteria are show even if no score recorded
        return criteriaScores.map((criteriaScore, index) => {
            var _a;
            const identityFieldName = `${PROJECT_PRIORITIES_KEY}.${index}.identity`;
            const scoreFieldName = `${PROJECT_PRIORITIES_KEY}.${index}.score`;
            useController({
                name: identityFieldName,
                control,
                defaultValue: criteriaScore.identity.toString(),
                rules: { required: true },
            });
            return (_jsx(SliderInput, { label: criteriaScore.name, type: criteriaScore.type, isInEditMode: editMode, isLocked: isCriteriaTypeConditionLocked, controller: useController({
                    name: scoreFieldName,
                    control,
                    defaultValue: ((_a = criteriaScore === null || criteriaScore === void 0 ? void 0 : criteriaScore.score) === null || _a === void 0 ? void 0 : _a.toString()) || '',
                }) }, `${PROJECT_PRIORITIES_KEY}.${index}`));
        });
    };
    const renderLabel = (label) => {
        return (_jsx(Heading, Object.assign({ as: 'h3', variant: 'h3', color: blue }, { children: label })));
    };
    const renderPrioritizationScore = () => {
        const friendlyCode = getFriendlyPriorityRating(priorityCode);
        return (_jsx(Box, Object.assign({ bgColor: colorCode, fontWeight: 500, px: '15px', py: '7px', maxW: '14em' }, { children: friendlyCode === FriendlyPriorityCode.NEEDS_ACTION ? (_jsx(NeedsAction, { label: friendlyCode })) : (`${priorityScoreAverage} - ${friendlyCode} Priority`) })));
    };
    const styleConfig = {
        table: {
            marginLeft: '18px',
            width: '100%',
            maxWidth: '210px',
            border: `1px solid ${appColors.PRIM_LIGHT_GREY}`,
        },
        body: {
            maxHeight: '163px',
        },
        cell: [
            { fontWeight: 700, color: appColors.PRIM_BLUE },
            { fontWeight: 500, color: appColors.TEXT_SEC_DARK_GRAY },
        ],
    };
    const tableData = {
        headers: ['Scenario', 'Funding Type'],
        data: project.scenarios.map((scenario) => [
            scenario.scenarioName,
            scenario.scenarioFundingType,
        ]),
        popoverCellIndex: 0,
        emptyStateText: 'Empty',
    };
    return (_jsxs(InformationTab.SlimPanel, { children: [_jsxs(InformationTab.Column, { children: [_jsx(InformationTab.Info, { info: project.identity.toString(), label: projectLabels.projectId, isLoading: false, errorMessage: (_a = errors.projectTypeIdentity) === null || _a === void 0 ? void 0 : _a.message }), _jsx(InformationTab.Info, { info: project.projectType.name, label: projectLabels.projType, isLoading: false, errorMessage: (_b = errors.projectTypeIdentity) === null || _b === void 0 ? void 0 : _b.message }), _jsx(Controller, { name: ESTIMATED_BUDGET_KEY, control: control, render: ({ field }) => {
                            var _a;
                            return (_jsx(InformationTab.Info, Object.assign({ info: formatBudgetToString(project.estimatedBudget), label: projectLabels.estBudget, isLoading: false }, { children: _jsx(CurrencyInput, { label: '', data: (_a = field.value) === null || _a === void 0 ? void 0 : _a.toString(), maskOptions: { allowDecimal: false }, onInputChange: (e) => {
                                        if (!(e && e.target.value) || e.target.value === '') {
                                            resetField(QUOTE_DATE_KEY);
                                        }
                                        field.onChange(e);
                                    }, placeholder: 'Type here...', isDisabled: project.isLocked }) })));
                        } }), _jsx(Box, Object.assign({ display: showBudgetDate ? 'block' : 'none' }, { children: _jsx(Controller, { name: QUOTE_DATE_KEY, control: control, rules: {
                                validate: (value) => {
                                    const year = new Date(value).getUTCFullYear();
                                    const min = 1776;
                                    const max = new Date().getUTCFullYear() + 20;
                                    return validateYearRange(year, min, max);
                                },
                            }, render: ({ field, fieldState: { error } }) => {
                                var _a, _b, _c, _d;
                                return (_jsx(InformationTab.Info, Object.assign({ info: (_b = (_a = field.value) === null || _a === void 0 ? void 0 : _a.split('T')[0]) !== null && _b !== void 0 ? _b : '', label: projectLabels.budgetDate, isLoading: false }, { children: _jsx(DateInput, { label: '' // not needed when used with InformationTab
                                        , data: (_d = (_c = field.value) === null || _c === void 0 ? void 0 : _c.split('T')[0]) !== null && _d !== void 0 ? _d : '', errorMessage: error === null || error === void 0 ? void 0 : error.message, onInputChange: field.onChange, isDisabled: project.isLocked }) })));
                            } }) })), _jsx(Controller, { name: FUNDING_TYPE_KEY, control: control, rules: { required: true }, render: ({ field }) => {
                            var _a;
                            return (_jsx(InformationTab.Info, Object.assign({ info: project.fundingType.name, label: projectLabels.fundingType, isLoading: false, errorMessage: (_a = errors.fundingTypeIdentity) === null || _a === void 0 ? void 0 : _a.message, required: true }, { children: _jsx(SelectInput, { label: '', editable: true, data: field.value, choices: fundingTypes.map((fundingType) => ({
                                        label: fundingType.name,
                                        value: fundingType.identity,
                                    })), placeholder: 'Select', onInputChange: field.onChange }) })));
                        } }), _jsx(TableGrid, { styleConfig: styleConfig, tableData: tableData })] }), _jsxs(InformationTab.Column, { children: [_jsx(Controller, { name: NAME_KEY, control: control, rules: { required: true }, render: ({ field, fieldState: { error } }) => (_jsx(InformationTab.InfoHeader, Object.assign({ label: projectLabels.projectName, info: field.value, errorMessage: error === null || error === void 0 ? void 0 : error.message, required: true }, { children: _jsx(Input, { value: field.value, onChange: field.onChange, maxLength: 80, required: true }) }))) }), _jsx(Controller, { name: PROJ_SCOPE_KEY, control: control, rules: { required: true }, render: ({ field, fieldState: { error } }) => (_jsx(InformationTab.Info, Object.assign({ info: field.value, label: projectLabels.projScope, isLoading: false, required: true }, { children: _jsx(TextAreaInput, { label: '', editMode: true, data: field.value, onInputChange: field.onChange, placeholder: projectLabels.projScope, errorMessage: error === null || error === void 0 ? void 0 : error.message, constraints: { maxLength: 500 } }) }))) }), _jsx(Controller, { name: CURRENT_ISSUES_KEY, control: control, render: ({ field, fieldState: { error } }) => {
                            var _a;
                            return (_jsx(InformationTab.Info, Object.assign({ info: (_a = project.ideas) !== null && _a !== void 0 ? _a : '', label: projectLabels.currentIssues, isLoading: false }, { children: _jsx(TextAreaInput, { label: '', editMode: true, data: field.value, onInputChange: field.onChange, placeholder: projectLabels.currentIssues, errorMessage: error === null || error === void 0 ? void 0 : error.message, constraints: { maxLength: 1250 } }) })));
                        } })] }), _jsxs(InformationTab.Column, { children: [_jsxs(VStack, Object.assign({ gap: '0.5em', w: '100%', justifyContent: 'space-between', alignItems: 'start', "data-testid": 'prioritization-score-avg' }, { children: [renderLabel(projectLabels.prioritizationScore), renderPrioritizationScore()] })), _jsx(VStack, Object.assign({ w: '100%', paddingRight: '10px', "data-testid": 'criteria-score-inputs' }, { children: renderPrioritizationInputs(project.criteriaScores) }))] })] }));
};
