import { convertSimpleObjectListToFormDataArray } from '../../conversion/FormDataConversions';
import { convertFundingTypeResponseToIFundingType, convertProjectTypeResponseToIProjectType, } from '../../models/Settings/SettingsConversions';
import { CriteriaType, ProjectRelationshipType, } from './ProjectsInterfaces';
export const convertProjectToFormRequest = (project) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const formRequest = {
        ProjectId: project.identity,
        FacilityId: project.facilityIdentity,
        Name: project.name,
        ProjectTypeId: project.projectTypeIdentity,
        FundingTypeId: project.fundingTypeIdentity,
        EstimatedBudget: project.estimatedBudget,
        QuoteDate: project.quoteDate,
        Scope: project.scope,
        Ideas: project.ideas,
        'CO2eSavings.Amount': (_a = project.cO2eSavings) === null || _a === void 0 ? void 0 : _a.amount,
        'CO2eSavings.NumberOfYears': (_b = project.cO2eSavings) === null || _b === void 0 ? void 0 : _b.numberOfYears,
        'EnergySavings.Amount': (_c = project.energySavings) === null || _c === void 0 ? void 0 : _c.amount,
        'EnergySavings.NumberOfYears': (_d = project.energySavings) === null || _d === void 0 ? void 0 : _d.numberOfYears,
        'ThermsSavings.Amount': (_e = project.thermsSavings) === null || _e === void 0 ? void 0 : _e.amount,
        'ThermsSavings.NumberOfYears': (_f = project.thermsSavings) === null || _f === void 0 ? void 0 : _f.numberOfYears,
        'WaterSavings.Amount': (_g = project.waterSavings) === null || _g === void 0 ? void 0 : _g.amount,
        'WaterSavings.NumberOfYears': (_h = project.waterSavings) === null || _h === void 0 ? void 0 : _h.numberOfYears,
        AssetIds: project.assetIdentities,
    };
    const supportingFiles = convertSimpleObjectListToFormDataArray('SupportingFiles', convertFilesToProjectFilesRequest(project.supportingFiles || []));
    const criteriaScores = convertSimpleObjectListToFormDataArray('CriteriaScores', convertCriteriaScoresToCriteriaScoreRequests((project === null || project === void 0 ? void 0 : project.criteriaScores) || []));
    const monetarySavings = convertSimpleObjectListToFormDataArray('MonetarySavings', convertMonetarySavingsToMonetarySavingsRequests(project.monetarySavings || []));
    const relatedProjects = convertSimpleObjectListToFormDataArray('RelatedProjects', convertIConfigureProjectRelationshipsToRelatedProjectRequests(project.relatedProjects || []));
    const rebates = convertSimpleObjectListToFormDataArray('Rebates', convertRebatesToRebatesRequests(project.rebates || []));
    return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, formRequest), supportingFiles), criteriaScores), monetarySavings), relatedProjects), rebates);
};
const convertCriteriaScoresToCriteriaScoreRequests = (criteriaScores) => {
    const criteriaScoreRequest = [];
    for (const criteriaScore of criteriaScores) {
        criteriaScoreRequest.push(convertCriteriaScoreToCriteriaScoreRequest(criteriaScore.identity, criteriaScore.score));
    }
    return criteriaScoreRequest;
};
const convertCriteriaScoreToCriteriaScoreRequest = (criteriaIdentity, score) => {
    return {
        criteriaId: criteriaIdentity,
        score: score,
    };
};
const convertFilesToProjectFilesRequest = (files) => {
    return files.map(convertFileToProjectFileRequest);
};
const convertFileToProjectFileRequest = (file) => {
    return {
        fileName: file.name,
        file: file.file,
    };
};
const convertProjectFilesToIProjectFiles = (files) => {
    return files.map((file) => ({
        name: file.fileName,
        url: file.fileUrl,
    }));
};
const convertCriteriaScoresToICriteriaScores = (scores) => {
    return scores.map((score) => {
        var _a;
        const isValidEnum = Object.values(CriteriaType).includes(score.criteriaType);
        return {
            identity: score.criteriaId,
            name: score.criteriaName,
            type: isValidEnum
                ? score.criteriaType
                : CriteriaType.UserDefined,
            score: (_a = score.score) !== null && _a !== void 0 ? _a : undefined,
            weight: score.weight,
        };
    });
};
const convertCriteriaScoresToICriteriaScoresSummary = (scores) => {
    return scores.map((score) => {
        var _a;
        return {
            name: score.criteriaName,
            score: score.score !== null ? (_a = score.score) === null || _a === void 0 ? void 0 : _a.toString() : undefined,
        };
    });
};
const convertProjectScenariosResponseToIProjectScenarios = (scenarios) => {
    return scenarios.map((scenario) => ({
        scenarioIdentity: scenario.scenarioId,
        scenarioName: scenario.scenarioName,
        scenarioFundingType: scenario.scenarioFundingType,
        isLocked: scenario.isLocked,
    }));
};
const convertProjectRelationshipResponsesToIProjectRelationships = (relatedProjects) => {
    const convertedProjects = [];
    relatedProjects === null || relatedProjects === void 0 ? void 0 : relatedProjects.forEach((relatedProject) => {
        const convertedProject = convertProjectRelationshipResponseToIProjectRelationship(relatedProject);
        if (convertedProject) {
            convertedProjects.push(convertedProject);
        }
    });
    return convertedProjects;
};
export const isValidProjectRelationshipType = (value) => {
    const isValidEnum = Object.values(ProjectRelationshipType).includes(value);
    if (!isValidEnum) {
        console.warn('Invalid ProjectRelationshipType', value);
    }
    return isValidEnum;
};
const convertProjectRelationshipResponseToIProjectRelationship = (relatedProject) => {
    const isValidEnum = isValidProjectRelationshipType(relatedProject.relationshipType);
    return isValidEnum
        ? {
            identity: relatedProject.relatedProjectId,
            projectName: relatedProject.relatedProjectName,
            relationshipType: toProjectRelationshipType(relatedProject.relationshipType),
        }
        : undefined;
};
export function toProjectRelationshipType(value) {
    switch (value) {
        case 'Requires':
            return ProjectRelationshipType.REQUIRES;
        case 'Recommended':
            return ProjectRelationshipType.RECOMMENDED;
        case 'Alternate':
            return ProjectRelationshipType.ALTERNATE;
        case 'Interdependent':
            return ProjectRelationshipType.INTERDEPENDENT;
        case 'RequiredBy':
            return ProjectRelationshipType.REQUIRED_BY;
        default:
            throw Error(`Unknown value ${value}`);
    }
}
const convertRebateResponseToIProjectsRebate = (rebates) => {
    return rebates.map((rebate) => ({
        identity: rebate.rebateId,
        rebateProgram: rebate.rebateProgram,
        amount: rebate.rebateAmount,
        expirationDate: new Date(rebate.expirationDate),
        notes: !rebate.notes ? undefined : rebate.notes,
    }));
};
export const convertResponseToProject = (response) => {
    return {
        identity: response.projectId,
        facilityIdentity: response.facilityId,
        facilityName: response.facilityName,
        siteName: response.siteName,
        name: response.name,
        projectType: convertProjectTypeResponseToIProjectType(response.projectType),
        fundingType: convertFundingTypeResponseToIFundingType(response.fundingType),
        estimatedBudget: response.estimatedBudget !== null
            ? response.estimatedBudget
            : undefined,
        quoteDate: response.quoteDate || undefined,
        scope: response.scope,
        isLocked: response.isLocked,
        ideas: response.ideas || undefined,
        // TODO: fill out field conversions as they get applied
        rebates: convertRebateResponseToIProjectsRebate(response.rebates),
        savings: [],
        assetIdentities: response.assetIds,
        priorityScore: response.priorityScore !== null &&
            response.priorityScore !== undefined
            ? response.priorityScore
            : undefined,
        priorityRating: response.priorityRating || undefined,
        criteriaScores: convertCriteriaScoresToICriteriaScores(response.criteriaScores),
        createdOn: new Date(response.createdOn),
        relatedProjects: convertProjectRelationshipResponsesToIProjectRelationships(response.relatedProjects),
        requiredByProjects: convertProjectRelationshipResponsesToIProjectRelationships(response.requiredByProjects),
        supportingFiles: convertProjectFilesToIProjectFiles(response.supportingFiles),
        scenarios: convertProjectScenariosResponseToIProjectScenarios(response.scenarios),
        cO2eSavings: convertEnvironmentalSavingsResponseToEnvironmentalSavings(response.cO2eSavings),
        energySavings: convertEnvironmentalSavingsResponseToEnvironmentalSavings(response.energySavings),
        thermsSavings: convertEnvironmentalSavingsResponseToEnvironmentalSavings(response.thermsSavings),
        waterSavings: convertEnvironmentalSavingsResponseToEnvironmentalSavings(response.waterSavings),
        dollarSavings: response.monetarySavings
            ? convertMonetarySavingsResponseToDollarSavings(response.monetarySavings)
            : [],
    };
};
export const convertProjectSummaryResponseToIProjectSummary = (project) => {
    return {
        identity: project.projectId,
        facilityIdentity: project.facilityId,
        name: project.name,
        estimatedBudget: project.estimatedBudget !== null
            ? project.estimatedBudget
            : undefined,
        scope: project.scope,
        facilityName: project.facilityName,
        isLocked: project.isLocked,
        fundingTypeName: project.fundingType.fundingTypeName,
        fundingTypeId: project.fundingType.fundingTypeId,
        projectTypeName: project.projectType.projectTypeName,
        priorityScore: project.priorityScore !== null &&
            project.priorityScore !== undefined
            ? project.priorityScore
            : undefined,
        priorityRating: project.priorityRating
            ? project.priorityRating
            : undefined,
        plannedFundingTypes: project.plannedFundingTypes.map((ft) => ft.fundingTypeName),
        criteriaScores: convertCriteriaScoresToICriteriaScoresSummary(project.criteriaScores),
        createdOn: new Date(project.createdOn),
        quoteDate: project.quoteDate ? new Date(project.createdOn) : undefined,
    };
};
export const convertEnvironmentalSavingsResponseToEnvironmentalSavings = (environmentalSaving) => {
    var _a, _b;
    return {
        amount: (_a = environmentalSaving === null || environmentalSaving === void 0 ? void 0 : environmentalSaving.amount.toString()) !== null && _a !== void 0 ? _a : '',
        numberOfYears: (_b = environmentalSaving === null || environmentalSaving === void 0 ? void 0 : environmentalSaving.numberOfYears.toString()) !== null && _b !== void 0 ? _b : '',
    };
};
const convertMonetarySavingsResponseToDollarSavings = (dollarSavings) => {
    return dollarSavings.map((dollarSaving) => ({
        id: dollarSaving.savingsId,
        saving: dollarSaving.savingsAmount.toString(),
        type: dollarSaving.savingsType,
        duration: dollarSaving.numberOfYears.toString(),
        notes: dollarSaving.notes ? dollarSaving.notes : undefined,
    }));
};
const convertMonetarySavingsToMonetarySavingsRequests = (monetarySavings) => {
    const monetarySavingsRequest = [];
    for (const monetarySaving of monetarySavings) {
        monetarySavingsRequest.push(convertMonetarySavingToMonetarySavingRequest(monetarySaving));
    }
    return monetarySavingsRequest;
};
const convertMonetarySavingToMonetarySavingRequest = (monetarySaving) => {
    return {
        savingsId: monetarySaving.savingsId,
        savingsAmount: monetarySaving.savingsAmount,
        savingsType: monetarySaving.savingsType,
        numberOfYears: monetarySaving.numberOfYears,
        notes: monetarySaving.notes,
    };
};
const convertIConfigureProjectRelationshipsToRelatedProjectRequests = (relatedProjects) => {
    return relatedProjects.map((relatedProject) => ({
        relatedProjectId: relatedProject.identity,
        relationshipType: relatedProject.relationshipType,
    }));
};
const convertRebatesToRebatesRequests = (rebates) => {
    return rebates.map((rebate) => ({
        rebateId: rebate.identity,
        rebateProgram: rebate.rebateProgram,
        rebateAmount: rebate.amount,
        expirationDate: rebate.expirationDate.toISOString().split('T')[0],
        notes: rebate.notes,
    }));
};
