import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { HStack, IconButton, VStack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { MdArrowLeft, MdArrowRight } from 'react-icons/md';
import { BarChart } from './BarChart';
export const ScrollableBarChart = ({ config, data, containerHeight, containerWidth, chunkSize, }) => {
    var _a, _b;
    const [index, setIndex] = useState(0);
    const [labelsColors, setLablesColors] = useState(((_a = config.labelColors) === null || _a === void 0 ? void 0 : _a.xAxis) || []);
    useEffect(() => {
        var _a;
        // resetting on widget change
        setIndex(0);
        setLablesColors(((_a = config.labelColors) === null || _a === void 0 ? void 0 : _a.xAxis) || []);
    }, [data]);
    useEffect(() => {
        var _a;
        if ((_a = config.labelColors) === null || _a === void 0 ? void 0 : _a.xAxis) {
            const newLabelColors = getDataChunk(index, config.labelColors.xAxis);
            setLablesColors(newLabelColors);
        }
    }, [index, (_b = config.labelColors) === null || _b === void 0 ? void 0 : _b.xAxis]);
    const totalBars = data.axisCategories.length;
    const getDataChunk = (index, list) => {
        const minIndex = index;
        const maxIndex = index + chunkSize;
        return list.slice(minIndex, maxIndex);
    };
    const prev = () => {
        if (index - 1 >= 0) {
            setIndex(index - 1);
        }
    };
    const next = () => {
        if (index + 1 <= totalBars - chunkSize) {
            setIndex(index + 1);
        }
    };
    const modifiedData = {
        axisCategories: getDataChunk(index, data.axisCategories),
        datasets: data.datasets.map((dataset) => (Object.assign(Object.assign({}, dataset), { data: getDataChunk(index, dataset.data) }))),
    };
    const iconButtonStyles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSize: '24px',
        size: '24px',
        variant: 'unstyled',
    };
    return (_jsxs(VStack, Object.assign({ alignItems: 'center', justifyContent: 'center', height: containerHeight, width: containerWidth }, { children: [_jsx(BarChart, { config: Object.assign(Object.assign({}, config), { labelColors: Object.assign(Object.assign({}, config.labelColors), { xAxis: labelsColors }) }), data: modifiedData, containerHeight: containerHeight, containerWidth: containerWidth }), _jsxs(HStack, Object.assign({ hidden: totalBars <= chunkSize }, { children: [_jsx(IconButton, Object.assign({ onClick: prev, icon: _jsx(MdArrowLeft, { size: '24px' }), "aria-label": 'previous', isDisabled: index === 0 }, iconButtonStyles)), _jsx(IconButton, Object.assign({ onClick: next, icon: _jsx(MdArrowRight, { size: '24px' }), "aria-label": 'next', isDisabled: index >= totalBars - chunkSize }, iconButtonStyles))] }))] })));
};
