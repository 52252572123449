var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { consolidateName } from '../conversion/NameConversions';
import { usePlatformResponseWrapper } from '../hooks/responseWrappers/usePlatformResponseWrapper';
import { convertIUserToUserProfileRequest, convertUserProfileResponseToIUser, } from '../models/User/UserConversions';
export const useUserService = ({ platformApi }) => {
    const { handleResponse } = usePlatformResponseWrapper();
    const activateUser = (activateUser) => __awaiter(void 0, void 0, void 0, function* () {
        return handleResponse(platformApi.user.postApiV1UserActivate(activateUser), {
            error: { label: 'Error occurred activating the user' },
            success: { enabled: false },
        }).then((res) => res);
    });
    const fetchUser = (userId) => __awaiter(void 0, void 0, void 0, function* () {
        return handleResponse(platformApi.user.getApiV1UserLoad(userId), {
            error: {
                label: 'Error occurred retrieving user information',
                ignoreStatus: [412],
            },
            success: { enabled: false },
        }).then(convertUserProfileResponseToIUser);
    });
    const configureUser = (userToUpdate) => __awaiter(void 0, void 0, void 0, function* () {
        const request = convertIUserToUserProfileRequest(userToUpdate);
        return handleResponse(platformApi.user.postApiV1UserConfigure(request), {
            error: { label: 'Error occurred updating user' },
            success: {
                enabled: true,
                label: `Successfully updated user: ${consolidateName(userToUpdate)}`,
            },
        }).then(convertUserProfileResponseToIUser);
    });
    const fetchUserList = (organizationIdentity, roles) => __awaiter(void 0, void 0, void 0, function* () {
        return handleResponse(platformApi.user.getApiV1UserList(organizationIdentity, roles)).then((res) => res.map(convertUserProfileResponseToIUser));
    });
    return {
        user: {
            activate: activateUser,
            fetch: fetchUser,
            fetchList: fetchUserList,
            update: configureUser,
        },
    };
};
