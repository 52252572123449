import { jsx as _jsx } from "react/jsx-runtime";
import { SearchBar, } from '@frontend/design-system/components/SearchBar/SearchBar';
import { appColors } from '../../../../config/constants';
export const AssociatedProjectSearch = ({ currentProject, onSearchSelect, associatedProjects, requiredByProjects, allProjects, isDisabled, }) => {
    const searchItems = allProjects
        .filter((project) => !associatedProjects.some((assocProj) => assocProj.identity === project.identity) &&
        project.identity !== (currentProject === null || currentProject === void 0 ? void 0 : currentProject.identity) &&
        !requiredByProjects.some((reqProj) => reqProj.identity === project.identity))
        .sort((projectA, projectB) => projectA.name.toLowerCase() >= projectB.name.toLowerCase() ? 1 : -1)
        .map((project) => ({
        label: project.name,
        subLabel: project.facilityName,
        value: project,
    }));
    return (_jsx(SearchBar, { searchItems: searchItems, onSearchSelect: onSearchSelect, isDisabled: isDisabled, color: {
            background: appColors.TEXT_SEC_DARK_GRAY,
            input: appColors.SEC_DARK_GRAY,
            icon: appColors.SEC_DARK_GRAY,
        } }));
};
