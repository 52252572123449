import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
export const useAccessToken = (authorizationParams) => {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [token, setToken] = useState(undefined);
    useEffect(() => {
        if (isAuthenticated) {
            getAccessTokenSilently({
                authorizationParams,
            }).then((token) => {
                setToken(token);
            });
        }
    }, [isAuthenticated]);
    return {
        token,
    };
};
