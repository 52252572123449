var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { usePlatformResponseWrapper } from '../hooks/responseWrappers/usePlatformResponseWrapper';
import { convertToIReport } from '../models/Report/ReportConversions';
export const useReportService = ({ platformApi }) => {
    const { handleResponse: handlePlatformResponse } = usePlatformResponseWrapper();
    const fetchReportList = (organizationId) => __awaiter(void 0, void 0, void 0, function* () {
        return handlePlatformResponse(platformApi.report.getApiV1ReportList(organizationId), {
            error: { label: 'Error occurred retrieving Priority' },
            success: { enabled: false },
        }).then((response) => response.map(convertToIReport));
    });
    return {
        report: {
            fetchReportList: fetchReportList,
        },
    };
};
