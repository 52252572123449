export class UserService {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Lists the user profiles the authenticated user has access to.
     * @param organizationId The ID of the organization to search
     * @param roles An optional set of organization roles to constrain the search by
     * @param isPrimaryContactEligible
     * @returns UserProfileResponse OK
     * @throws ApiError
     */
    getApiV1UserList(organizationId, roles, isPrimaryContactEligible) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/User/list',
            query: {
                organizationId: organizationId,
                roles: roles,
                isPrimaryContactEligible: isPrimaryContactEligible,
            },
            errors: {
                403: `Forbidden`,
            },
        });
    }
    /**
     * Gets the user profile for the currently authenticated user.
     * @param userId The ID of the user to query
     * @returns UserProfileResponse OK
     * @throws ApiError
     */
    getApiV1UserLoad(userId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/User/load',
            query: {
                userId: userId,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }
    /**
     * Gets the user profile for the currently authenticated user.
     * @param userId The ID of the user to query
     * @returns UserProfileResponse OK
     * @throws ApiError
     */
    getApiV1UserView(userId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/User/view',
            query: {
                userId: userId,
            },
            errors: {
                404: `Not Found`,
            },
        });
    }
    /**
     * Invites a new user to the system.
     * @param requestBody The details of the user to invite
     * @returns any OK
     * @throws ApiError
     */
    postApiV1UserInvite(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/User/invite',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Re-sends the invitation to an existing invited user.
     * @param requestBody The ID of the user to re-send the invitation to
     * @returns any OK
     * @throws ApiError
     */
    postApiV1UserInviteResend(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/User/invite/resend',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Updates the user's profile information and role assignments.
     * @param requestBody The updated user details
     * @returns UserProfileResponse OK
     * @throws ApiError
     */
    postApiV1UserConfigure(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/User/configure',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Verifies the user's identity and activates them in the system.
     * @param requestBody The details that positively identify the user
     * @returns string OK
     * @throws ApiError
     */
    postApiV1UserActivate(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/User/activate',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Verifies the user's identity and activates them in the system.
     * @param requestBody The details that positively identify the user
     * @returns string OK
     * @throws ApiError
     */
    postApiV1UserVerify(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/User/verify',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Offboards one or more users from the system.
     * @param requestBody The list of user-organization ID pairs to offboard
     * @returns any OK
     * @throws ApiError
     */
    postApiV1UserOffboard(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/User/offboard',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Returns system data to initialize a user
     * @returns UserTemplateResponse OK
     * @throws ApiError
     */
    getApiV1UserInitialize() {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/User/initialize',
            errors: {
                403: `Forbidden`,
            },
        });
    }
}
