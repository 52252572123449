import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, FormLabel, SimpleGrid, Spacer, Text, VStack, } from '@chakra-ui/react';
import { useEffect } from 'react';
import { useFieldArray } from 'react-hook-form';
import { VscAdd } from 'react-icons/vsc';
import { DollarSavingCard } from './DollarSavingCard';
import { DollarSavingsInputs } from './DollarSavingsInputs';
import { variants } from '@frontend/design-system/theme/theme';
export const DOLLAR_SAVINGS_LABEL = 'Annual Dollar Savings';
export const NO_DOLLAR_SAVINGS_INSTRUCTION = 'Add savings that are an outcome of completing this project here.';
export const DollarSavings = ({ editMode, project, projectUseForm, }) => {
    const { control, trigger } = projectUseForm;
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'dollarSavings',
    });
    useEffect(() => {
        if (fields === undefined || fields.length === 0) {
            append({ saving: '', type: '', duration: '', notes: '' });
        }
    }, [editMode]);
    const showDollarSavingsInstruction = project.dollarSavings.length === 0 && !editMode;
    return (_jsxs(Box, Object.assign({ px: 4 }, { children: [_jsx(FormLabel, Object.assign({ color: 'brand.primBlue', fontWeight: 'semibold', pl: 0 }, { children: DOLLAR_SAVINGS_LABEL })), _jsx(Spacer, { h: 4 }), showDollarSavingsInstruction && (_jsx(Text, Object.assign({ variant: variants.subText }, { children: NO_DOLLAR_SAVINGS_INSTRUCTION }))), !editMode && (_jsx(SimpleGrid, Object.assign({ columns: 3, spacing: 4 }, { children: project.dollarSavings.map((saving, index) => (_jsx(DollarSavingCard, { saving: saving }, `${index}_${project.identity}_dollar_savings_card`))) }))), editMode && (_jsxs(VStack, Object.assign({ spacing: 4, align: 'flex-start' }, { children: [fields.length > 0 &&
                        fields.map((field, index) => (_jsx(DollarSavingsInputs, { control: control, index: index, removeSaving: () => remove(index), canRemove: fields.length > 1, trigger: trigger }, field.id))), _jsx(Button, Object.assign({ onClick: () => {
                            append({
                                saving: '',
                                type: '',
                                duration: '',
                                notes: '',
                            });
                        }, color: 'brand.primBlue', variant: 'link', leftIcon: _jsx(VscAdd, {}), fontWeight: 500, size: '14px', lineHeight: '22px', isDisabled: fields.length === 5 }, { children: "Add new" }))] })))] })));
};
