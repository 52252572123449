export class PlanService {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Loads scenario planning information, including scheduled projects
     * @param scenarioId ID of the scenario to load
     * @returns PlanResponse OK
     * @throws ApiError
     */
    getApiV1PlanLoad(scenarioId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/Plan/load/{scenarioId}',
            path: {
                scenarioId: scenarioId,
            },
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Provides comparable metrics for each requested scenario
     * @param formData
     * @returns PlanCompareResponse OK
     * @throws ApiError
     */
    postApiV1PlanCompare(formData) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/Plan/compare',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Exports the projects related to the requested scenario in a csv format.
     * The scenario must be locked to use this endpoint.
     * Filename will be in the content-disposition header.
     * @param scenarioId ID of the scenario to export
     * @returns binary OK
     * @throws ApiError
     */
    getApiV1PlanExport(scenarioId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/Plan/export/{scenarioId}',
            path: {
                scenarioId: scenarioId,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Locks or unlocks a scenario
     * @param requestBody Request to lock or unlock a scenario
     * @returns any OK
     * @throws ApiError
     */
    postApiV1PlanLock(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/Plan/lock',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Schedules project(s) into a scenario
     * @param requestBody Project(s) to schedule
     * @returns PlanResponse OK
     * @throws ApiError
     */
    postApiV1PlanSchedule(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/Plan/schedule',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Loads the assets and related projects for an organization
     * @param organizationId ID of the organization to load assets for
     * @returns PlanAssetDetailResponse OK
     * @throws ApiError
     */
    getApiV1PlanGroup(organizationId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/Plan/group/{organizationId}',
            path: {
                organizationId: organizationId,
            },
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @deprecated
     * (Deprecated. Use lock endpoint instead.) Analyzes a locked scenario.
     * @param scenarioId The ID of the scenario to analyze
     * @returns any OK
     * @throws ApiError
     */
    postApiV1PlanAnalyze(scenarioId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/Plan/analyze/{scenarioId}',
            path: {
                scenarioId: scenarioId,
            },
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @deprecated
     * (Deprecated. Use lock endpoint instead.) Removes a scenario.
     * @param scenarioId The ID of the scenario to remove
     * @returns any OK
     * @throws ApiError
     */
    deleteApiV1PlanRemove(scenarioId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/Plan/remove/{scenarioId}',
            path: {
                scenarioId: scenarioId,
            },
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
}
