export class ScenarioService {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * @param scenarioId
     * @returns ScenarioDetailResponse OK
     * @throws ApiError
     */
    getApiV1ScenarioLoad(scenarioId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/Scenario/load/{scenarioId}',
            path: {
                scenarioId: scenarioId,
            },
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @param organizationId
     * @returns ScenarioSummaryResponse OK
     * @throws ApiError
     */
    getApiV1ScenarioList(organizationId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/Scenario/list/{organizationId}',
            path: {
                organizationId: organizationId,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @param requestBody
     * @returns ScenarioDetailResponse OK
     * @throws ApiError
     */
    postApiV1ScenarioConfigure(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/Scenario/configure',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Sets the tax impact for a locked scenario
     * @param requestBody Tax impact information to save
     * @returns TaxImpactDetailResponse OK
     * @throws ApiError
     */
    postApiV1ScenarioSettaximpact(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/Scenario/settaximpact',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * @param scenarioId
     * @returns any OK
     * @throws ApiError
     */
    deleteApiV1ScenarioRemove(scenarioId) {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/v1/Scenario/remove/{scenarioId}',
            path: {
                scenarioId: scenarioId,
            },
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
}
