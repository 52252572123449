import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Circle, Line } from 'react-konva';
import { appColors } from '../../../theme/constants';
export const EditableShape = ({ shape, setShape, pointSize, }) => {
    const handleCircleDragEnd = (pointIndex, e) => {
        const stage = e.target.getStage();
        const scale = stage.scaleX();
        const pos = e.target.getStage().getPointerPosition();
        const newPoint = {
            x: pos.x / scale - stage.x() / scale,
            y: pos.y / scale - stage.y() / scale,
            pointIndex,
        };
        const filteredPoints = shape.filter((point) => point.pointIndex !== pointIndex);
        setShape([...filteredPoints, newPoint]);
    };
    // Duplicate method in DrawShape.tsx
    const getOrderedLinePoints = (points) => {
        const linePoints = [];
        points
            .sort((a, b) => a.pointIndex - b.pointIndex)
            .forEach((point) => linePoints.push(point.x, point.y));
        return linePoints;
    };
    return (_jsxs(_Fragment, { children: [_jsx(Line, { points: getOrderedLinePoints(shape), stroke: appColors.SEC_ORANGE, strokeWidth: 2, dash: [33, 10], fill: appColors.KONVA_ORANGE, closed: true }), shape.map((point, i) => (_jsx(Circle, { x: point.x, y: point.y, width: pointSize, height: pointSize, fill: 'lightblue', opacity: 0.7, stroke: 'black', strokeWidth: 1, draggable: true, onDragEnd: (e) => handleCircleDragEnd(point.pointIndex, e) }, `drawing shape ${point} ${i}`)))] }));
};
