import theme from '../theme/theme';
import { getCurrencyNotation } from './uiUtils';
export const getAvgAnnotation = (position, dataSet) => {
    const sum = dataSet.datasets
        .flatMap((dataset) => dataset.data)
        .reduce((prev, curr) => prev + curr);
    const avg = sum / dataSet.axisCategories.length;
    return position === 'horizontal'
        ? getXAnnotation(avg)
        : getYAnnotation(avg);
};
const getYAnnotation = (avg) => {
    return {
        yaxis: [
            {
                y: avg,
                borderColor: theme.colors.brand.primBlue,
                borderWidth: 2,
                strokeDashArray: 4,
                label: {
                    borderColor: theme.colors.brand.primBlue,
                    borderRadius: '1px',
                    style: {
                        color: '#fff',
                        background: theme.colors.brand.primBlue,
                    },
                    text: `Average ${getCurrencyNotation(avg)}`,
                },
            },
        ],
        xaxis: [],
    };
};
const getXAnnotation = (avg) => {
    return {
        yaxis: [],
        xaxis: [
            {
                x: avg,
                borderColor: '#00E396',
                label: {
                    borderColor: '#00E396',
                    style: {
                        color: '#fff',
                        background: '#00E396',
                    },
                    text: 'Average',
                },
            },
        ],
    };
};
export const getGlobalMinMax = (dataSet) => {
    const axisSums = dataSet.axisCategories.map((_, index) => {
        return dataSet.datasets.reduce((sum, dataset) => {
            return sum + (dataset.data[index] || 0);
        }, 0);
    });
    const globalMin = 0;
    let globalMax = Math.max(...axisSums);
    if (globalMax === 0) {
        globalMax = 2;
    }
    return { globalMin, globalMax };
};
