export function sortByField(fieldGetter, desc) {
    return (a, b) => {
        if (desc) {
            return fieldGetter(b) - fieldGetter(a);
        }
        return fieldGetter(a) - fieldGetter(b);
    };
}
export function uniqueObjects(objects, key) {
    const objectMap = new Map(objects.map((item) => [key(item), item]));
    const objectList = [];
    objectMap.forEach((item) => {
        objectList.push(item);
    });
    return objectList;
}
