export const formatBudgetToString = (budgetNum) => {
    if (budgetNum === undefined) {
        return '';
    }
    else if (budgetNum === 0) {
        return '$0';
    }
    else {
        return `$${new Intl.NumberFormat().format(budgetNum)}`;
    }
};
export var CriteriaType;
(function (CriteriaType) {
    CriteriaType["UserDefined"] = "UserDefined";
    CriteriaType["Condition"] = "Condition";
})(CriteriaType || (CriteriaType = {}));
export var ProjectRelationshipType;
(function (ProjectRelationshipType) {
    ProjectRelationshipType["REQUIRES"] = "Requires";
    ProjectRelationshipType["RECOMMENDED"] = "Recommended";
    ProjectRelationshipType["ALTERNATE"] = "Alternate";
    ProjectRelationshipType["INTERDEPENDENT"] = "Interdependent";
    ProjectRelationshipType["REQUIRED_BY"] = "RequiredBy";
})(ProjectRelationshipType || (ProjectRelationshipType = {}));
