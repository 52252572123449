export var BudgetTypeEnum;
(function (BudgetTypeEnum) {
    BudgetTypeEnum["TOTAL_BUDGET"] = "totalBudget";
    BudgetTypeEnum["FUNDING_TYPE"] = "fundingTypeBudget";
    BudgetTypeEnum["FUNDING_TYPE_PER_YEAR"] = "fundingTypeBudgetPerYear";
    BudgetTypeEnum["NEEDS"] = "needsBasedBudget";
})(BudgetTypeEnum || (BudgetTypeEnum = {}));
export var PlanGoalEnum;
(function (PlanGoalEnum) {
    PlanGoalEnum["NEEDS"] = "knowNeeds";
    PlanGoalEnum["BUDGET"] = "knowBudget";
})(PlanGoalEnum || (PlanGoalEnum = {}));
export var PlanningFactors;
(function (PlanningFactors) {
    PlanningFactors["FUNDING_TYPE"] = "fundingType";
    PlanningFactors["FACILITY"] = "facility";
})(PlanningFactors || (PlanningFactors = {}));
