import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Checkbox, Flex, HStack, List, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Text, useToken, VStack, } from '@chakra-ui/react';
import { variants } from '@frontend/design-system/theme/theme';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { appColors } from '../../../config/constants';
import { calculateEscalatedBudget } from '../../../config/CPHelperFunctions';
import { projectSummaryFilter } from '../../../contexts/Projects/ProjectFilterHook';
import { SelectProjectRow } from './SelectProjectRow';
import { SelectProjectsFilter } from './SelectProjectsFilter';
const SelectProjectsModal = ({ isOpen, onClose, addProjects, unscheduledProjects, scenarioId, year, isEscalated, escalationFactor, scenarioStartYear, }) => {
    const [projectsToAdd, setProjectsToAdd] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [blue] = useToken('colors', ['brand.primBlue']);
    const getProjectSummaryWithEscalatedValue = (projects) => {
        return projects.map((project) => {
            return Object.assign(Object.assign({}, project), { escalatedBudget: calculateEscalatedBudget(project, escalationFactor, year, scenarioStartYear) });
        });
    };
    const projects = isEscalated
        ? getProjectSummaryWithEscalatedValue(unscheduledProjects)
        : unscheduledProjects;
    const projectFilterContext = projectSummaryFilter(projects);
    const projectFilterForm = useForm({
        mode: 'onBlur',
        values: projectFilterContext.filter,
    });
    const projectsToSelect = projectFilterContext.filteredItems;
    const projectsToAddIds = projectsToAdd.map((proj) => proj.identity);
    const projectsToSelectIds = projectsToSelect.map((proj) => proj.identity);
    useEffect(() => {
        projectFilterContext.resetFilterItems(projects);
    }, [unscheduledProjects]);
    useEffect(() => {
        if (unscheduledProjects.length === projectsToAdd.length) {
            setSelectAll(true);
        }
        else {
            const filteredProjectsNotAdded = projectsToSelectIds.filter((projId) => !projectsToAddIds.includes(projId));
            if (filteredProjectsNotAdded.length > 0) {
                setSelectAll(false);
            }
            else {
                setSelectAll(true);
            }
        }
    }, [projectsToAdd, projectFilterContext.hasFilter, projectsToSelect]);
    const handleSelectAll = (e) => {
        const isChecked = e.target.checked;
        setSelectAll(isChecked);
        if (isChecked) {
            // We're removing the projects that were already added to make sure
            // we don't add any duplicate projects
            const filteredProjectsToSelect = projectsToSelect.filter((proj) => !projectsToAddIds.includes(proj.identity));
            const updatedProjectsToAdd = [
                ...projectsToAdd,
                ...filteredProjectsToSelect,
            ];
            setProjectsToAdd(updatedProjectsToAdd);
        }
        else {
            const updatedProjects = projectsToAdd.filter((proj) => !projectsToSelectIds.includes(proj.identity));
            setProjectsToAdd(updatedProjects);
        }
    };
    const handleProjectChange = (project, isChecked) => {
        if (isChecked) {
            setProjectsToAdd([...projectsToAdd, project]);
        }
        else {
            setProjectsToAdd(projectsToAdd.filter((p) => p.identity !== project.identity));
        }
    };
    const isProjectSelected = (project) => {
        return projectsToAdd.some((p) => p.identity === project.identity);
    };
    const handleAddProjects = () => {
        addProjects(scenarioId, projectsToAdd, year);
        setProjectsToAdd([]);
        setSelectAll(false);
        projectFilterContext.clearFilter();
        projectFilterForm.reset(projectFilterContext.initialFilter);
        onClose();
    };
    const handleOnModalClose = () => {
        setProjectsToAdd([]);
        setSelectAll(false);
        projectFilterContext.clearFilter();
        projectFilterForm.reset(projectFilterContext.initialFilter);
        onClose();
    };
    return (_jsxs(Modal, Object.assign({ closeOnEsc: true, closeOnOverlayClick: true, isOpen: isOpen, isCentered: true, onClose: handleOnModalClose }, { children: [_jsx(ModalOverlay, { bg: 'rgba(0, 0, 0, 0.7)' }), _jsxs(ModalContent, Object.assign({ padding: '40px', minW: '900px', h: 'auto', maxH: '90vh' }, { children: [_jsx(ModalHeader, Object.assign({ fontSize: '36px', fontWeight: 400, padding: '0px' }, { children: "Select Projects" })), _jsx(ModalCloseButton, {}), _jsx(ModalBody, Object.assign({ p: '0px', mt: '20px', mb: '20px', h: '100%', maxH: '65vh' }, { children: _jsxs(HStack, Object.assign({ w: '100%', justifyContent: 'space-between', h: '100%', alignItems: 'baseline' }, { children: [_jsx(Box, Object.assign({ w: '35%' }, { children: _jsx(SelectProjectsFilter, { projects: projects, filterContext: projectFilterContext, formContext: projectFilterForm, isEscalated: isEscalated }) })), _jsxs(VStack, Object.assign({ height: '100%', w: '65%', flexGrow: 1 }, { children: [_jsxs(HStack, Object.assign({ w: '100%', spacing: 1, justifyContent: 'space-between' }, { children: [_jsx(Text, Object.assign({ color: blue, fontWeight: 'semibold' }, { children: "Projects" })), _jsx(Checkbox, Object.assign({ iconColor: appColors.SEC_ORANGE, flexDirection: 'row-reverse', isChecked: selectAll, onChange: handleSelectAll, color: appColors.PRIM_BLUE, sx: {
                                                        '& .chakra-checkbox__control': {
                                                            borderColor: appColors.PRIM_LIGHT_GREY,
                                                            borderWidth: '2px',
                                                        },
                                                    } }, { children: _jsx(Text, Object.assign({ color: blue, mr: '10px', fontSize: '10px' }, { children: "Select all" })) }))] })), _jsx(List, Object.assign({ w: '100%', height: '53vh', borderWidth: '1px', borderColor: appColors.BORDER_SEC_LIGHT_GRAY, overflowY: 'auto', bg: appColors.TERCIARY_LIGHT_GRAY, p: '15px 20px', flexGrow: 1 }, { children: projectsToSelect.map((project) => {
                                                return (_jsx(SelectProjectRow, { project: project, isSelected: isProjectSelected(project), handleProjectChange: handleProjectChange }, project.identity));
                                            }) }))] }))] })) })), _jsx(ModalFooter, Object.assign({ padding: '0px' }, { children: _jsxs(Flex, Object.assign({ w: '100%' }, { children: [_jsx(Button, Object.assign({ fontSize: '14px', onClick: handleOnModalClose, onTouchEnd: handleOnModalClose, type: 'button', variant: variants.blueOutlineBtn }, { children: "Cancel" })), _jsx(Spacer, {}), _jsx(Button, Object.assign({ isDisabled: projectsToAdd.length === 0, fontSize: '14px', onClick: handleAddProjects, onTouchEnd: handleAddProjects, type: 'button', variant: variants.blueBtn }, { children: "Select Projects" }))] })) }))] }))] })));
};
export default SelectProjectsModal;
