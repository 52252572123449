export class ReportService {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Returns the reports the user has access to in a given organization
     * @param organizationId The ID of the organization to query
     * @returns ReportResponse OK
     * @throws ApiError
     */
    getApiV1ReportList(organizationId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/Report/list/{organizationId}',
            path: {
                organizationId: organizationId,
            },
        });
    }
    /**
     * Views the specified report.
     * @param reportId The ID of the report to request
     * @returns EmbedResponse OK
     * @throws ApiError
     */
    getApiV1ReportView(reportId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/Report/view/{reportId}',
            path: {
                reportId: reportId,
            },
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Publishes the given report.
     * @param requestBody The details of the report to publish
     * @returns any OK
     * @throws ApiError
     */
    postApiV1ReportPublish(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/Report/publish',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Registers the given PowerBI report with mySiteIQ.
     * @param requestBody The details of the report to register
     * @returns ReportResponse OK
     * @throws ApiError
     */
    postApiV1ReportRegister(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/Report/register',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Removes the given report from mySiteIQ
     * @param reportId The ID of the report to remove (from mySiteIQ)
     * @returns any OK
     * @throws ApiError
     */
    postApiV1ReportRemove(reportId) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/Report/remove/{reportId}',
            path: {
                reportId: reportId,
            },
            errors: {
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
}
