import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { VStack } from '@chakra-ui/react';
import { CurrencyInput } from '@frontend/design-system/components/Inputs/CurrencyInput';
import { Controller } from 'react-hook-form';
import { convertToCurrency } from '../../../../config/CPHelperFunctions';
import { TaxImpactText } from './TaxImpactText';
import { TaxImpactValueText } from './TaxImpactValueText';
export const TaxImpactCurrencyInput = ({ title, formContext, taxImpactExists, formKey, }) => {
    const { control, resetField, watch } = formContext;
    const currencyValue = watch(formKey);
    return taxImpactExists && currencyValue ? (_jsxs(VStack, Object.assign({ "aria-label": `${formKey}-currency-value`, w: '100%', spacing: 0.5 }, { children: [_jsx(TaxImpactText, { text: title }), _jsx(TaxImpactValueText, { text: convertToCurrency(currencyValue) })] }))) : (_jsxs(VStack, Object.assign({ "aria-label": `${formKey}-currency-input`, w: '100%', spacing: 1 }, { children: [_jsx(TaxImpactText, { text: title }), _jsx(Controller, { control: control, name: formKey, render: ({ field }) => (_jsx(CurrencyInput, { data: field.value, onInputChange: (e) => {
                        if (!(e && e.target.value) || e.target.value === '') {
                            resetField(formKey);
                        }
                        field.onChange(e.target.value);
                    }, placeholder: 'Type here...', maskOptions: {
                        allowDecimal: false,
                    } })) })] })));
};
