import { PlanningFactors } from '@frontend/domain/models/Scenario/ScenarioInterfaces';
export const defaultScenarioUpdateForm = {
    name: '',
    description: '',
    planDurationYrs: '',
    planningNeed: '',
    budgetType: '',
    startYear: '',
    planningFactor: PlanningFactors.FUNDING_TYPE,
    totalBudget: '',
    fundingTypeBudgets: [],
    useEscalationFactor: false,
};
