import { createContext, useContext } from 'react';
// Only use in test suites
export const testHappyOrgSummaryContext = {
    selectedOrganizationIdentity: undefined,
    organizations: [],
    onSelectOrganization: (_identity) => { },
    isLoading: false,
    onUpdateOrganization: () => { },
};
export const OrganizationSummaryContext = createContext({});
export const useOrganizationSummaries = () => useContext(OrganizationSummaryContext);
