export class MeasureService {
    constructor(httpRequest) {
        this.httpRequest = httpRequest;
    }
    /**
     * Calculates ECM priorities for an Organization or Facility.
     * @param requestBody The organization and optional facility to prioritize
     * @returns any OK
     * @throws ApiError
     */
    postApiV1MeasurePrioritize(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/Measure/prioritize',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Loads the set of priorities for a given organization or facility.
     * NOTE that a request can be made for either an organization or a facility, but not both.
     * @param organizationId The ID of the organization to load priorities for
     * @param facilityId The ID of the facility to load priorities for
     * @returns PriorityResponse OK
     * @throws ApiError
     */
    getApiV1MeasureLoad(organizationId, facilityId) {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/v1/Measure/load',
            query: {
                organizationId: organizationId,
                facilityId: facilityId,
            },
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Refines the accuracy of an EV project's qualification score
     * @param requestBody The refinement data to apply
     * @returns RefinementResponse OK
     * @throws ApiError
     */
    postApiV1MeasureRefineEv(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/Measure/refine/ev',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Refines the accuracy of a lighting project's qualification score
     * @param requestBody The refinement data to apply
     * @returns RefinementResponse OK
     * @throws ApiError
     */
    postApiV1MeasureRefineLighting(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/Measure/refine/lighting',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Refines the accuracy of a solar project's qualification score
     * @param requestBody The refinement data to apply
     * @returns RefinementResponse OK
     * @throws ApiError
     */
    postApiV1MeasureRefineSolar(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/Measure/refine/solar',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
    /**
     * Imports facility and related criteria data from a CSV file.
     * @param formData
     * @returns any OK
     * @throws ApiError
     */
    postApiV1MeasureImport(formData) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/Measure/import',
            formData: formData,
            mediaType: 'multipart/form-data',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Exports a CSV file with the requested facilities and existing criteria values.
     * File name is contained in the content-disposition header.
     * @param requestBody Which organization and facility type to export
     * @returns binary OK
     * @throws ApiError
     */
    postApiV1MeasureExport(requestBody) {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/v1/Measure/export',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                404: `Not Found`,
            },
        });
    }
}
