import { createGetHandler } from '../testing/msw/handlerUtils';
import { OrganizationSummaryResponseBuilder } from '../testing/platformOrganizationMocks';
const organizationEndpoints = {
    list: '/api/v1/Organization/list',
};
const organizationListHandler = createGetHandler(organizationEndpoints.list, [
    new OrganizationSummaryResponseBuilder({}).build(),
]);
const organizationHandlers = [organizationListHandler];
export const organizationHandler = {
    endpoints: organizationEndpoints,
    handlers: organizationHandlers,
};
