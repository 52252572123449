var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useDependencies } from '@frontend/domain/contexts/Dependencies/DependenciesContext';
import { convertIPlanProjectToIPlannedProject, sortAndFilterRelationshipViolations, } from '@frontend/domain/models/Plan/PlanConversions';
import { IProjectRelationshipType, } from '@frontend/domain/models/Plan/PlanInterfaces';
import { usePlanService } from '@frontend/domain/services/PlanService';
import { toast } from '@frontend/domain/ToastContainer';
import { useEffect, useState } from 'react';
import { sortByProjectSummary } from '../../../src/config/CPHelperFunctions';
export const useScenarioPlan = (projectList, refreshScenarioAndScenarioList) => {
    const { platformApi } = useDependencies();
    const { plan: planApi } = usePlanService({ platformApi });
    const [plan, setPlan] = useState();
    const [scheduledProjects, setScheduledProjects] = useState([]);
    const [unscheduledProjects, setUnscheduledProjects] = useState([]);
    const [relationshipViolations, setRelationshipViolations] = useState([]);
    const [isTaxImpactDownloading, setTaxImpactDownloading] = useState(false);
    useEffect(() => {
        if (plan) {
            checkScheduledOrUnscheduled(plan.projects);
        }
    }, [projectList, plan === null || plan === void 0 ? void 0 : plan.projects]);
    const fetchScenarioPlan = (scenarioIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        planApi
            .fetchScenarioPlan(scenarioIdentity)
            .then((scenarioPlan) => {
            setPlan(scenarioPlan);
            if (scenarioPlan) {
                setRelationshipViolations(sortAndFilterRelationshipViolations(scenarioPlan.relationshipViolations));
            }
        });
    });
    const checkScheduledOrUnscheduled = (projects) => {
        const selectedIds = new Set(projects.map((item) => item.projectId));
        const resultItems = [];
        const unselectedItems = [];
        projectList.forEach((project) => {
            if (selectedIds.has(project.identity)) {
                const selectedItem = projects.find((selected) => selected.projectId === project.identity);
                if (selectedItem) {
                    resultItems.push(convertIPlanProjectToIPlannedProject(selectedItem, project));
                }
            }
            else {
                unselectedItems.push(project);
            }
        });
        setScheduledProjects(sortByProjectSummary(resultItems, (project) => project.projectSummary));
        setUnscheduledProjects(sortByProjectSummary(unselectedItems, (project) => project));
    };
    const schedulePlan = (scenarioId, project, fundingTypeId, year) => __awaiter(void 0, void 0, void 0, function* () {
        const planToUpdate = {
            projectId: project.projectSummary.identity,
            fundingTypeId: fundingTypeId
                ? fundingTypeId
                : project.projectSummary.fundingTypeId,
            planYear: year ? year : project.planYear,
            remove: false,
        };
        const convertedPlan = {
            scenarioId: scenarioId,
            projects: [planToUpdate],
        };
        return yield planApi
            .schedulePlan(convertedPlan)
            .then((scenarioPlan) => {
            setPlan(scenarioPlan);
            if (scenarioPlan === null || scenarioPlan === void 0 ? void 0 : scenarioPlan.projects) {
                checkScheduledOrUnscheduled(scenarioPlan.projects);
            }
        });
    });
    const lockUnlockScenario = (scenarioIdentity, isLocking, name) => __awaiter(void 0, void 0, void 0, function* () {
        return yield planApi
            .lockUnlockScenario(scenarioIdentity, isLocking, name)
            .then(() => {
            if (refreshScenarioAndScenarioList) {
                refreshScenarioAndScenarioList(isLocking);
            }
        });
    });
    const showProjectRemovedToast = (violations, removedProject) => {
        violations.forEach((relation) => {
            if (relation.relatedProjectName === removedProject.name &&
                relation.violationType !== IProjectRelationshipType.ALTERNATE) {
                const status = relation.violationType === IProjectRelationshipType.REQUIRED
                    ? 'error'
                    : 'warning';
                toast({
                    isClosable: true,
                    status: status,
                    description: `${relation.relationMessage} ${relation.actionMessage}`,
                });
            }
        });
    };
    const removeProjectFromScenario = (scenarioId, project) => __awaiter(void 0, void 0, void 0, function* () {
        const projectToRemove = {
            projectId: project.projectSummary.identity,
            fundingTypeId: project.projectSummary.fundingTypeId,
            planYear: project.planYear,
            remove: true,
        };
        const convertedPlan = {
            scenarioId: scenarioId,
            projects: [projectToRemove],
        };
        return yield planApi
            .schedulePlan(convertedPlan)
            .then((scenarioPlan) => {
            if (scenarioPlan && scenarioPlan.relationshipViolations) {
                showProjectRemovedToast(scenarioPlan.relationshipViolations, project.projectSummary);
            }
            setPlan(scenarioPlan);
            if (scenarioPlan) {
                setRelationshipViolations(sortAndFilterRelationshipViolations(scenarioPlan.relationshipViolations));
                if (scenarioPlan.projects) {
                    checkScheduledOrUnscheduled(scenarioPlan.projects);
                }
            }
        });
    });
    const isProjectInTheScenario = (projectName, projectsToAdd) => {
        return !(scheduledProjects.some((project) => project.projectSummary.name === projectName) || projectsToAdd.some((project) => project.name === projectName));
    };
    const showProjectAddedToast = (violations, addedProject, projectsToAdd) => {
        violations.forEach((relation) => {
            if (relation.projectName === addedProject.name &&
                isProjectInTheScenario(relation.relatedProjectName, projectsToAdd) &&
                relation.violationType !== IProjectRelationshipType.ALTERNATE) {
                const status = relation.violationType === IProjectRelationshipType.REQUIRED
                    ? 'error'
                    : 'warning';
                toast({
                    isClosable: true,
                    status: status,
                    description: `${relation.relationMessage} ${relation.actionMessage}`,
                });
            }
            if ((relation.projectName === addedProject.name ||
                relation.relatedProjectName === addedProject.name) &&
                relation.violationType === IProjectRelationshipType.ALTERNATE) {
                toast({
                    isClosable: true,
                    status: 'error',
                    description: `This scenario already includes an alternative project, ${relation.projectName === addedProject.name
                        ? relation.relatedProjectName
                        : relation.projectName}. Please remove the appropriate project from this scenario.`,
                });
            }
        });
    };
    const addProjectToScenario = (scenarioId, projects, year) => __awaiter(void 0, void 0, void 0, function* () {
        const projectsToAdd = [];
        projects.forEach((project) => {
            projectsToAdd.push({
                projectId: project.identity,
                fundingTypeId: project.fundingTypeId,
                planYear: year,
                remove: false,
            });
        });
        const convertedPlan = {
            scenarioId: scenarioId,
            projects: projectsToAdd,
        };
        return yield planApi
            .schedulePlan(convertedPlan)
            .then((scenarioPlan) => {
            if (scenarioPlan && scenarioPlan.relationshipViolations) {
                projects.map((project) => {
                    showProjectAddedToast(scenarioPlan.relationshipViolations, project, projects);
                });
            }
            setPlan(scenarioPlan);
            if (scenarioPlan) {
                setRelationshipViolations(sortAndFilterRelationshipViolations(scenarioPlan.relationshipViolations));
                if (scenarioPlan.projects) {
                    checkScheduledOrUnscheduled(scenarioPlan.projects);
                }
            }
        });
    });
    const fetchTaxImpactCSV = (scenarioId, fileName, type) => __awaiter(void 0, void 0, void 0, function* () {
        setTaxImpactDownloading(true);
        return yield planApi
            .fetchTaxImpactCSV(scenarioId, fileName, type)
            .then((response) => {
            if (response) {
                return response;
            }
        })
            .finally(() => setTaxImpactDownloading(false));
    });
    return {
        plan,
        scheduledProjects,
        unscheduledProjects,
        addProjectToScenario,
        fetchScenarioPlan,
        schedulePlan,
        lockUnlockScenario,
        removeProjectFromScenario,
        relationshipViolations,
        fetchTaxImpactCSV,
        isTaxImpactDownloading,
    };
};
