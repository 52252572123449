/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const environment = {
    apiBase: process.env.REACT_APP_MYSITEIQ_API,
    platformApi: process.env.REACT_APP_PLATFORM_API_OLD,
    appBasename: process.env.REACT_APP_CP_PATH,
    environment: process.env.NODE_ENV,
    fcaUrl: process.env.REACT_APP_FCA_URL,
    msiqUrl: process.env.REACT_APP_MSIQ_URL,
    plannerUrl: process.env.REACT_APP_CAPITAL_PLANNER_URL,
    apiAuthDomain: process.env.REACT_APP_AUTH_DOMAIN,
    apiAuthClientId: process.env.REACT_APP_AUTH_CLIENT_ID,
    apiPlatformAudience: process.env.REACT_APP_AUTH_PLATFORM_AUDIENCE,
    apiAuthRedirectUri: process.env.REACT_APP_AUTH_REDIRECT_URI,
    rootPathUrl: process.env.REACT_APP_ROOT_PATH_URL,
    apis: {
        mysiteIQAPI: process.env.REACT_APP_MYSITEIQ_API,
        platformAPI: process.env.REACT_APP_PLATFORM_API,
    },
};
