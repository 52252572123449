export const initialProjectCreateData = {
    name: '',
    scope: '',
    projectTypeIdentity: '',
    fundingTypeIdentity: '',
    facilityIdentity: '',
    estimatedBudget: '',
    quoteDate: '',
    currentIssues: '',
};
export const initialCreateTaxImpactData = {
    totalReinvestment: '',
    contributionYear: '',
    contribution: '',
    revenueBonded: '',
    revenueBondedPercentYear: '',
    revenueBondedPercent: '',
    annualHomeValue: '',
    annualTaxImpact: '',
    monthlyHomeValue: '',
    monthlyTaxImpact: '',
    acreValue: '',
    annualAcreValueTaxImpact: '',
    ag2SchoolStateAid: '',
    ltfmStateAid: '',
    stateAidDescription: '',
    stateAid: '',
};
