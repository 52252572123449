import { FetchHttpRequest } from './core/FetchHttpRequest';
import { AssessmentService } from './services/AssessmentService';
import { AssetService } from './services/AssetService';
import { FacilityService } from './services/FacilityService';
import { MeasureService } from './services/MeasureService';
import { OrganizationService } from './services/OrganizationService';
import { PhotoService } from './services/PhotoService';
import { PlanService } from './services/PlanService';
import { ProjectService } from './services/ProjectService';
import { ReportService } from './services/ReportService';
import { ScenarioService } from './services/ScenarioService';
import { SettingsService } from './services/SettingsService';
import { SpaceService } from './services/SpaceService';
import { UserService } from './services/UserService';
export class PlatformAPI {
    constructor(config, HttpRequest = FetchHttpRequest) {
        var _a, _b, _c, _d;
        this.request = new HttpRequest({
            BASE: (_a = config === null || config === void 0 ? void 0 : config.BASE) !== null && _a !== void 0 ? _a : '',
            VERSION: (_b = config === null || config === void 0 ? void 0 : config.VERSION) !== null && _b !== void 0 ? _b : '1.0',
            WITH_CREDENTIALS: (_c = config === null || config === void 0 ? void 0 : config.WITH_CREDENTIALS) !== null && _c !== void 0 ? _c : false,
            CREDENTIALS: (_d = config === null || config === void 0 ? void 0 : config.CREDENTIALS) !== null && _d !== void 0 ? _d : 'include',
            TOKEN: config === null || config === void 0 ? void 0 : config.TOKEN,
            USERNAME: config === null || config === void 0 ? void 0 : config.USERNAME,
            PASSWORD: config === null || config === void 0 ? void 0 : config.PASSWORD,
            HEADERS: config === null || config === void 0 ? void 0 : config.HEADERS,
            ENCODE_PATH: config === null || config === void 0 ? void 0 : config.ENCODE_PATH,
        });
        this.assessment = new AssessmentService(this.request);
        this.asset = new AssetService(this.request);
        this.facility = new FacilityService(this.request);
        this.measure = new MeasureService(this.request);
        this.organization = new OrganizationService(this.request);
        this.photo = new PhotoService(this.request);
        this.plan = new PlanService(this.request);
        this.project = new ProjectService(this.request);
        this.report = new ReportService(this.request);
        this.scenario = new ScenarioService(this.request);
        this.settings = new SettingsService(this.request);
        this.space = new SpaceService(this.request);
        this.user = new UserService(this.request);
    }
}
